import React from "react"
import { Typography } from "@material-ui/core"

import styles from "./MoneyStretch.module.scss"

const HeaderStretch: React.FC = () => {
  return (
    <div>
      <Typography variant="h4" className={styles.titleBold} color="primary">
        What's Your MoneySTRETCH?
      </Typography>
      <Typography variant="h5" className={styles.title} color="primary">
        Did you know?
      </Typography>
      <Typography variant="subtitle1" className={styles.paragraph}>
        <b>
          The average Australian household has just four and a half months of
          savings before their money runs out! That’s right, the average
          household has only $28,602 in savings, yet spends on average $74,301 a
          year (source: finder.com.au and ABS 2016 census data){" "}
        </b>
      </Typography>
      <Typography variant="h5" className={styles.title} color="primary">
        Introducing MoneySTRETCH
      </Typography>
      <Typography variant="subtitle1" className={styles.paragraph}>
        A self assessment tool that helps you work out just how long money will
        last if your income were to change.
      </Typography>
      <Typography variant="h5" className={styles.title} color="primary">
        How it Works
      </Typography>
      <Typography variant="subtitle1" className={styles.paragraph}>
        <b> Step 1 </b> : Establish your baseline scenario. Import your live
        MyWealth Portal income, expenses and lending data from your MyWealth
        Portal using the IMPORT LIVE DATA button below. This will give you your
        MoneySTRETCH baseline reading (red).
        <br />
        <b> Step 2 </b>: You can now play around with your figures. Each time
        you adjust either an income, expense or lending figure, you will see the
        changes it makes to how long your money will last.
      </Typography>
      <Typography variant="subtitle1" className={styles.paragraph}>
        <b>TIP</b>: To get the most out of this money insights feature on our
        platform - do your best to separate essential from discretionary
        expenses, as this will show you how far your money can stretch, if you
        needed it to. This hopefully gives you peace of mind and highlights just
        how important it is to be across your money as well as having a money
        management system that you put in control.{" "}
        <a
          href="https://empowerwealth.com.au/moneystretch/"
          className={styles.link}
        >
          Here is a tutorial video to help you learn more.
        </a>
      </Typography>
    </div>
  )
}

export default HeaderStretch
