import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#FCAB14",
      main: "#541868", // EM-19566 moorr CSS update was #FF8C10 (cannot use CSS variables here)
      dark: "#3d114c", // EM-19566 moorr CSS update was #FF9A11 (cannot use CSS variables here)
      contrastText: "#fff",
    },
    secondary: {
      light: "#506688",
      main: "#25406B",
      dark: "#192c4a",
    },
  },
});

export default theme;
