import * as React from "react";
import {
  withStyles,
  StyleRules,
  WithStyles,
  Theme,
} from "@material-ui/core/styles";
import FontAwesome from "react-fontawesome";
import { Typography } from "@material-ui/core";
import _ from "lodash";
const FamilyCard: React.FC<Props> = ({ personalInfo, classes }) => {
  let client1 = _.get(personalInfo, "client1", null);
  let client2 = _.get(personalInfo, "client2", null);
  let client1Icon = getGenderImg(client1);
  let client2Icon = getGenderImg(client2);
  let dependants = _.get(personalInfo, "dependants", []);
  return (
    <div className={classes.imageHolder}>
      <FontAwesome
        name={client1Icon}
        size="3x"
        className={classes.orangeColor}
      ></FontAwesome>
      {client2 !== null && (
        <FontAwesome
          name={client2Icon}
          size="3x"
          className={classes.orangeColor}
        ></FontAwesome>
      )}
      {dependants.length > 0 && <br />}
      {dependants.length > 0 && (
        <Typography variant="h6">
          + {dependants.length} Dependant{dependants.length > 1 && "s"}
        </Typography>
      )}
    </div>
  );
};
function getGenderImg(client: object) {
  let title = _.get(client, "title", null);
  if (title !== null) {
    if (title === "Mr") {
      return "male";
    }
    return "female";
  }
  return "male";
}
interface Props extends WithStyles<typeof styles, false> {
  personalInfo: object;
}
const styles = (theme: Theme) => {
  const style: StyleRules = {
    root: {
      display: "flex",
      flexWrap: "wrap",
      fontFamily: "Poppins",
    },
    imageHolder: {
      marginTop: 10,
      marginBottom: 5,
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "3em",
    },
    orangeColor: {
      color: "var(--portal-page-primary-background) !important",
    },
  };
  return style;
};
export default withStyles(styles)(FamilyCard);
