import React, { useState, useContext } from "react"
import { FormProps } from "./FormStretch"
import { Typography, Grid, Button } from "@material-ui/core"
import InputForm from "../Forms/Input/InputForm"
import CircleButton from "../Circle-Button/CircleButton"
import { frequencyOptions } from "./stretchModels"
import _ from "lodash"
import SelectForm from "../Forms/Select/SelectForm"
import MoneyStretchContext from "../../context/MoneyStretchContext"
import QuestionMarkButton from "./QuestionMarkButton"
import styles from "./FormStretch.module.scss"

import {
  getMonthlyFrequencyMultiplier,
  twoDecimalPlacing,
} from "../../lib/utils"
interface MoneyOutFormProps extends FormProps {
  currencyProps?: any
  normalInput?: any
  isPublishDisable: boolean
}

const MoneyOutForm: React.FC<MoneyOutFormProps> = ({
  form,
  handleFormChange,
  currencyProps,
  handleFormChangeBillsBasic,
  handleFormChangeBillsDiscretionary,
  handleFormChangeSpendingBasic,
  handleFormChangeSpendingDiscretionary,
  handlePublishChanges,
  sumDiscretionaryBills,
  sumDiscretionarySpending,
  handleFormChangeFrequencyBills,
  handleFormChangeFrequencySpending,
  getTotalSurplusDeficit,
  getTotalMoneyOutEssential,
  getTotalMoneyOutDiscretionary,
  normalInput,
  isPublishDisable,
}) => {
  const [hideBillPayments, setHideBillPayments] = useState<boolean>(false)
  const [hidespendingPayments, setHideSpendingPayments] = useState<boolean>(
    false
  )

  const { showMessage, setShowMessage } = useContext(MoneyStretchContext)

  const LabelExpenses = () => {
    return (
      <Grid container alignItems="center" style={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <Typography
                variant="h6"
                align="center"
                className={styles.paragraph}
              >
                Essential
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <Typography
                variant="h6"
                align="center"
                className={styles.paragraph}
              >
                Discretionary
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} className={styles.inputContainer}>
              <Typography
                variant="h6"
                align="center"
                className={styles.paragraph}
              >
                Amount
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="h6"
                align="center"
                className={styles.paragraph}
              >
                Frequency
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Typography variant="h5" className={styles.title}>
        Money Out
      </Typography>

      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Total Loan Repayments</b>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="totalLoanRepayments"
                value={form.totalLoanRepayments}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <SelectForm
                value={form.totalLoanRepaymentsFrequency}
                options={frequencyOptions}
                onChange={handleFormChange}
                id="totalLoanRepaymentsFrequency"
                name="totalLoanRepaymentsFrequency"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Monthly Investment Property Costs</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="monthlyInvestmentPropertyCost"
                value={form.monthlyInvestmentPropertyCost}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <SelectForm
                value={form.monthlyInvestmentPropertyCostFrequency}
                options={frequencyOptions}
                onChange={handleFormChange}
                id="monthlyInvestmentPropertyCostFrequency"
                name="monthlyInvestmentPropertyCostFrequency"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Monthly Other Property Costs</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="monthlyOtherPropertyCost"
                value={form.monthlyOtherPropertyCost}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <SelectForm
                value={form.monthlyOtherPropertyCostFrequency}
                options={frequencyOptions}
                onChange={handleFormChange}
                id="monthlyOtherPropertyCostFrequency"
                name="monthlyOtherPropertyCostFrequency"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={10}>
          <Grid container>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <Typography align="center" variant="h5" className={styles.title}>
                <b> Essential</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <Typography align="center" variant="h5" className={styles.title}>
                <b> Discretionary</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Monthly Bill Payments</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                value={form.totalMonthlyBillsEssential}
                disabled
                inputProps={currencyProps}
                inputClass={styles.inputDisabled}
              />
            </Grid>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                value={sumDiscretionaryBills() ? sumDiscretionaryBills() : 0}
                disabled
                inputProps={currencyProps}
                inputClass={styles.inputDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <div className={styles.cirButtonStretchContainer}>
                <CircleButton
                  type="downwards"
                  rotate={hideBillPayments}
                  onClick={() => setHideBillPayments(!hideBillPayments)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {hideBillPayments && <LabelExpenses />}
      {hideBillPayments &&
        form.bills &&
        form.bills.map((value, index) => {
          return (
            <Grid container alignItems="center" key={index}>
              <Grid item xs={12} sm={2}>
                <Typography
                  className={styles.expenseDesc}
                  variant="subtitle2"
                  align="left"
                >
                  {value.desc}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={3} className={styles.inputContainer}>
                    <InputForm
                      fullWidth
                      type="number"
                      value={value.basic ? value.basic : 0}
                      index={index}
                      onChange={handleFormChangeBillsBasic}
                      inputClass={styles.whiteInput}
                      inputProps={currencyProps}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={styles.inputContainer}>
                    <InputForm
                      fullWidth
                      type="number"
                      index={index}
                      value={value.discretionary ? value.discretionary : 0}
                      onChange={handleFormChangeBillsDiscretionary}
                      inputClass={styles.whiteInput}
                      inputProps={currencyProps}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className={styles.inputContainer}>
                    <InputForm
                      fullWidth
                      disabled
                      value={twoDecimalPlacing(
                        (Number(value.basic ? value.basic : 0) +
                          Number(
                            value.discretionary ? value.discretionary : 0
                          )) *
                          getMonthlyFrequencyMultiplier(value.frequency)
                      )}
                      inputProps={currencyProps}
                      inputClass={styles.inputDisabled}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} className={styles.inputContainer}>
                    <SelectForm
                      value={value.frequency}
                      options={frequencyOptions}
                      onChange={handleFormChangeFrequencyBills}
                      index={index}
                      id="netIncomeFrequency"
                      name="netIncomeFrequency"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}

      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Monthly Spending</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                value={form.totalMonthlySpendingEssential}
                inputClass={styles.inputDisabled}
                disabled
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                value={
                  sumDiscretionarySpending() ? sumDiscretionarySpending() : 0
                }
                inputClass={styles.inputDisabled}
                disabled
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <div className={styles.cirButtonStretchContainer}>
                <CircleButton
                  type="downwards"
                  rotate={hidespendingPayments}
                  onClick={() => setHideSpendingPayments(!hidespendingPayments)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {hidespendingPayments && <LabelExpenses />}
      {hidespendingPayments &&
        form.spending &&
        form.spending.map((value, index) => {
          return (
            <Grid container alignItems="center" key={index}>
              <Grid item xs={12} sm={2}>
                <Typography
                  className={styles.expenseDesc}
                  variant="subtitle2"
                  align="left"
                >
                  {value.desc}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={3} className={styles.inputContainer}>
                    <InputForm
                      fullWidth
                      type="number"
                      value={value.basic ? value.basic : 0}
                      index={index}
                      onChange={handleFormChangeSpendingBasic}
                      inputClass={styles.whiteInput}
                      inputProps={currencyProps}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={styles.inputContainer}>
                    <InputForm
                      fullWidth
                      type="number"
                      index={index}
                      value={value.discretionary ? value.discretionary : 0}
                      onChange={handleFormChangeSpendingDiscretionary}
                      inputClass={styles.whiteInput}
                      inputProps={currencyProps}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className={styles.inputContainer}>
                    <InputForm
                      fullWidth
                      disabled
                      value={twoDecimalPlacing(
                        (Number(value.basic ? value.basic : 0) +
                          Number(
                            value.discretionary ? value.discretionary : 0
                          )) *
                          getMonthlyFrequencyMultiplier(value.frequency)
                      )}
                      inputProps={currencyProps}
                      inputClass={styles.inputDisabled}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} className={styles.inputContainer}>
                    <SelectForm
                      value={value.frequency}
                      options={frequencyOptions}
                      onChange={handleFormChangeFrequencySpending}
                      index={index}
                      id="netIncomeFrequency"
                      name="netIncomeFrequency"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}

      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}>
          <Typography variant="h6" className={styles.paragraphTitle}>
            <b> Total Money Out</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="getTotalMoneyOutEssential"
                value={
                  getTotalMoneyOutEssential() ? getTotalMoneyOutEssential() : 0
                }
                disabled
                inputProps={currencyProps}
                inputClass={styles.inputDisabled}
              />
            </Grid>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="getTotalMoneyOutDiscretionary"
                value={
                  getTotalMoneyOutDiscretionary()
                    ? getTotalMoneyOutDiscretionary()
                    : 0
                }
                disabled
                inputProps={currencyProps}
                inputClass={styles.inputDisabled}
              />
            </Grid>
            <Grid item xs={10} md={3} className={styles.buttonWrapper}>
              <div style={{ marginTop: "8px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePublishChanges}
                  disabled={isPublishDisable}
                  className={isPublishDisable ? styles.buttonDisabled : null}
                >
                  Publish Changes
                </Button>
              </div>
            </Grid>
            <Grid item xs={2} sm={1} className={styles.questionMarkBtn}>
              <QuestionMarkButton
                cssClass={styles.questionIcon}
                onClick={() => setShowMessage(!showMessage)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {showMessage ? (
                <div className={styles.messageBlock}>
                  <div>
                    <Typography
                      color="primary"
                      variant="h6"
                      className={styles.paragraphTitle}
                    >
                      <b>Publish Changes to Live Data</b>
                    </Typography>
                    <Typography variant="h6" className={styles.paragraphTitle}>
                      <p>
                        This will publish the data you have entered{" "}
                        <b>
                          in bill <br />
                          payments and spending only
                        </b>{" "}
                        across to the rest of the
                        <br />
                        MyWealth Portal
                      </p>
                    </Typography>
                  </div>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.containterTotalSurplus}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2}>
            <Typography
              color="primary"
              variant="h6"
              className={styles.paragraphTitle}
            >
              <b> Your Total Surplus/(Deficit)</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid container>
              <Grid item xs={12} md={3} className={styles.inputContainer}>
                <InputForm
                  fullWidth
                  type="number"
                  value={
                    getTotalSurplusDeficit() ? getTotalSurplusDeficit() : 0
                  }
                  disabled
                  inputProps={currencyProps}
                  inputClass={styles.inputDisabled}
                />
              </Grid>
              <Grid item xs={12} md={3} className={styles.inputContainer}>
                <InputForm
                  fullWidth
                  type="text"
                  value={"Monthly"}
                  disabled
                  inputClass={styles.inputDisabled}
                  inputProps={normalInput}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default React.memo(MoneyOutForm)
