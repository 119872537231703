import React, { useState, useEffect, useContext } from "react"
import { i_RenderRolloverRow } from "./Rollover.interface"
import { getMonthlyFrequencyMultiplier } from "../../lib/utils"
import { RenderInputCurrency, RenderInputSelect } from "./Rollover.utils"
import SummaryExpenseContext from "../../context/SummaryExpenseContext"
import minusBtn from "../../images/icons/minus-circle-solid.svg"
import styles from "./Rollover.module.scss"
import _ from "lodash"

const RenderRolloverRow: React.FC<i_RenderRolloverRow> = ({
  expenseItem,
  index,
  setTempExpenses,
  tempExpenses,
}) => {
  const frequencyOptions = [
    {
      selectedCondition: expenseItem.frequency === "Weekly",
      value: "Weekly",
      label: "Weekly",
    },
    {
      selectedCondition: expenseItem.frequency === "Fortnightly",
      value: "Fortnightly",
      label: "Fortnightly",
    },
    {
      selectedCondition: expenseItem.frequency === "Monthly",
      value: "Monthly",
      label: "Monthly",
    },
    {
      selectedCondition: expenseItem.frequency === "Every 2 months",
      value: "Every 2 months",
      label: "Every 2 months",
    },
    {
      selectedCondition: expenseItem.frequency === "Every 3 months",
      value: "Every 3 months",
      label: "Every 3 months",
    },
    {
      selectedCondition: expenseItem.frequency === "Every 6 months",
      value: "Every 6 months",
      label: "Every 6 months",
    },
    {
      selectedCondition: expenseItem.frequency === "Yearly",
      value: "Yearly",
      label: "Yearly",
    },
  ]

  const categoryOptions = [
    {
      selectedCondition: expenseItem.category === "Direct from Primary",
      value: "Direct from Primary",
      label: "Primary Direct",
    },
    {
      selectedCondition: expenseItem.category === "Paid by Credit",
      value: "Paid by Credit",
      label: "via Credit Card",
    },
    {
      selectedCondition: expenseItem.category === "7 Days Float",
      value: "7 Days Float",
      label: "via Living and Lifestyle",
    },
    {
      selectedCondition: expenseItem.category === "Provisioning",
      value: "Provisioning",
      label: "Provision for",
    },
  ]

  const { summaryDispatch } = useContext(SummaryExpenseContext)
  const [individualAmount, setIndividualAmount] = useState(0)
  const [individualDiscretionary, setIndividualDiscretionary] = useState(0)
  const [amountTotalValue, setTotalAmountValue] = useState(0)
  const [totalMonthly, setTotalMonthly] = useState("0.00")
  const [individualDesc, setIndividualDesc] = useState("")

  let customId = _.get(expenseItem, "_id", expenseItem.id || "")
  useEffect(() => {
    const newTotal = Number(individualAmount) + Number(individualDiscretionary)
    setTotalAmountValue(newTotal)
    const newTotalMonthly =
      newTotal * getMonthlyFrequencyMultiplier(expenseItem.frequency)
    setTotalMonthly(newTotalMonthly.toFixed(2))
  }, [individualAmount, individualDiscretionary])

  useEffect(() => {
    const discretionary = Number(expenseItem.discretionary)
    let basic = Number(expenseItem.basic)
    setIndividualAmount(_.round(basic, 2) || 0)
    setIndividualDiscretionary(_.round(discretionary, 2) || 0)
    setIndividualDesc(expenseItem.desc || "")
  }, [expenseItem._id])

  const monthlySelectionHandler = (type, event) => {
    const expenseId =
      expenseItem.customExpense && expenseItem.rollOverAdded
        ? expenseItem.id
        : expenseItem._id

    if (type === "MONTHLY_FREQUENCY") {
      const monthlyValue =
        amountTotalValue * getMonthlyFrequencyMultiplier(event.target.value)
      setTotalMonthly(monthlyValue.toFixed(2))
      summaryDispatch({
        type: "MONTHLY_SELECTOR_FIELD",
        payload: {
          frequency: event.target.value,
          expenseId: expenseId,
        },
      })
    } else {
      let newExpenses = [...tempExpenses]
      let count = 0
      const newEntry = {
        category: event.target.value,
        expenseId: expenseId,
      }

      newExpenses = newExpenses.map(function(expence) {
        if (expence.expenseId === expenseId) {
          count++
          return newEntry
        }
        return expence
      })
      newExpenses = count > 0 ? newExpenses : [...newExpenses, newEntry]
      setTempExpenses(newExpenses)
    }
  }

  const changeInputHandler = (type, value) => {
    const expenseId =
      expenseItem.customExpense && expenseItem.rollOverAdded
        ? expenseItem.id
        : expenseItem._id
    const customAmount =
      expenseItem.customExpense && !expenseItem.rollOverAdded
        ? Number(value)
        : value
    if (type === "Essential") {
      setIndividualAmount(value)
      summaryDispatch({
        type: "CHANGE_ESSENTIAL",
        payload: {
          amount: customAmount,
          expenseId: expenseId,
        },
      })
    } else if (type === "Custom") {
      setIndividualDesc(value)
      summaryDispatch({
        type: "CHANGE_CUSTOM_DESC",
        payload: {
          desc: value,
          expenseId: expenseId,
        },
      })
    } else {
      setIndividualDiscretionary(value)
      summaryDispatch({
        type: "CHANGE_DISCRETIONARY",
        payload: {
          discretionary: value,
          expenseId: expenseId,
        },
      })
    }
  }

  return (
    <>
      <tr>
        <td>
          {expenseItem.customExpense ? (
            <RenderInputCurrency
              expenseItem={expenseItem}
              id="money-1"
              disabled={false}
              val={individualDesc}
              custom={true}
              onChange={event =>
                changeInputHandler("Custom", event.target.value)
              }
            />
          ) : (
            expenseItem.desc
          )}
        </td>
        <td>
          <RenderInputCurrency
            expenseItem={expenseItem}
            id="money-1"
            disabled={false}
            val={individualAmount}
            onChange={event =>
              changeInputHandler("Essential", event.target.value)
            }
            custom={false}
          />
        </td>
        <td>
          <RenderInputCurrency
            expenseItem={expenseItem}
            id="money-1"
            disabled={false}
            val={individualDiscretionary}
            onChange={event =>
              changeInputHandler("Discretionary", event.target.value)
            }
            custom={false}
          />
        </td>
        <td>
          <RenderInputCurrency
            expenseItem={expenseItem}
            id="money-1"
            disabled={true}
            val={amountTotalValue.toFixed(2)}
            custom={false}
          />
        </td>
        <td>
          <RenderInputSelect
            id="frequency"
            title="Frequency"
            options={frequencyOptions}
            onChange={event =>
              monthlySelectionHandler("MONTHLY_FREQUENCY", event)
            }
          />
        </td>
        <td>
          <RenderInputCurrency
            expenseItem={expenseItem}
            id="money-1"
            disabled={true}
            val={totalMonthly}
            custom={false}
          />
        </td>
        <td>
          <RenderInputSelect
            id="category"
            options={categoryOptions}
            onChange={event => monthlySelectionHandler("MONEY_SMARTS", event)}
          />
        </td>
        <td>
          {expenseItem.customExpense ? (
            <img
              onClick={() =>
                summaryDispatch({
                  type: "DELETE_CUSTOM_EXPENSE",
                  payload: {
                    id: customId,
                  },
                })
              }
              className={styles.minusBtn}
              src={minusBtn}
              alt="minus-button"
            />
          ) : null}
        </td>
      </tr>
    </>
  )
}

export default RenderRolloverRow
