import React from "react"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
interface Props {
  value?: any
  id?: string
  name?: number | string
  onChange?: (value?: any, index?: any) => any
  options?: Option[] | any
  index?: any
}

interface Option {
  value: any
  name: any
}

const SelectForm: React.FC<Props> = ({
  value,
  id,
  name,
  onChange,
  options,
  index,
}) => {
  return (
    <FormControl margin="none" fullWidth variant="outlined">
      <Select
        native
        value={value}
        onChange={e => onChange && onChange(e as any, index as any)}
        inputProps={{
          name: name,
          id: id,
          style: {
            padding: "10.3px 13px",
            backgroundColor: "#FFFF",
            color: "#555555 !important",
            fontSize: "14px",
          },
        }}
      >
        {options &&
          options.map((value, key) => {
            return (
              <option value={value.value} key={key}>
                {value.name}
              </option>
            )
          })}
      </Select>
    </FormControl>
  )
}

export default React.memo(SelectForm)
