import React from "react"
import { Bar, Line } from "react-chartjs-2"

import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema"
import { MonthlyBalanceItemSchema } from "../schemas/monthlyBalanceResponseSchema"
import {
  chartJsTooltipTitle,
  chartJsTooltipLabel,
  twelveMonthsLabel,
} from "../lib/utils"
import { calculateYearlyProvisions } from "../lib/calculator"
import styles from "./TrackProvision.module.scss"
import _ from "lodash"

const TrackProvisionSpendingSection: React.FC<Props> = ({
  summary,
  monthlyBalanceItems,
  provisioningSpent,
  yearlyProvisionRef,
}) => {
  const dateString = monthlyBalanceItems[0]
    ? monthlyBalanceItems[0].month +
      " " +
      monthlyBalanceItems[0].day +
      ", " +
      monthlyBalanceItems[0].year
    : ""
  const startDate = dateString === "" ? new Date() : new Date(dateString)
  const labels = twelveMonthsLabel(startDate)
  const shiftLabels = [...labels]
  shiftLabels.shift()
  const hasAssets = _.has(summary, "assets")
  const propertyAssets = hasAssets ? summary.assets.properties : []
  return (
    <section>
      <h2 className={styles.graphHeader}>Track Provision Spending</h2>
      <div className={styles.lineChartContainer}>
        <div>
          <h3 className={styles.graphHeader}>Monthly Provision Spent</h3>
          <Bar
            height={250}
            data={{
              labels: shiftLabels,
              datasets: [
                {
                  label: "Monthly Spend",
                  data: provisioningSpent,
                  backgroundColor: "rgba(68, 114, 196, 1)",
                },
              ],
            }}
            options={{
              maintainAspectRatio: true,
              legend: { display: false },
              tooltips: {
                bodyFontSize: 16,
                callbacks: {
                  label: chartJsTooltipLabel,
                  title: chartJsTooltipTitle,
                },
              },
            }}
          />
        </div>

        <div>
          <h3 className={styles.graphHeader}>Yearly Remaining Provisions</h3>
          <Line
            height={250}
            data={{
              labels,
              datasets: [
                {
                  label: "Yearly Remaining Balance",
                  data: yearlyProvisionRef
                    .map((_, i, self) =>
                      self
                        .slice(0, i + 1)
                        .reduce((prev, curr) => prev + curr, 0)
                    )
                    .map(
                      accumulatedProvisioningsSpent =>
                        calculateYearlyProvisions(
                          summary.expenses.expenses,
                          propertyAssets
                        ) - accumulatedProvisioningsSpent
                    ),
                  borderColor: "rgba(68, 114, 196, 1)",
                  lineTension: 0,
                  pointBackgroundColor: "rgba(68, 114, 196, 1)",
                  pointHitRadius: 10,
                  pointHoverRadius: 7,
                  pointRadius: 5,
                },
              ],
            }}
            options={{
              maintainAspectRatio: true,
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
              legend: { display: false },
              tooltips: {
                bodyFontSize: 16,
                callbacks: {
                  label: chartJsTooltipLabel,
                  title: chartJsTooltipTitle,
                },
              },
            }}
          />
        </div>
      </div>
    </section>
  )
}

interface Props {
  summary: ClientSummarySchema
  monthlyBalanceItems: MonthlyBalanceItemSchema[]
  provisioningSpent: Array<number>
  yearlyProvisionRef: Array<number>
}

export default TrackProvisionSpendingSection
