import { createContext } from "react"

type CalculateProps = {
  currentSurplus: number
  lifestyleJar: number
  loansJar: number
  provisionJar: number
  creditCardJar: number
}

const CalculateDataContext = createContext<Partial<CalculateProps>>({})

export default CalculateDataContext
