exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes _2ef3BYsDfRgGPrhgEPmJyC {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes _2ef3BYsDfRgGPrhgEPmJyC {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n.jZDvfiDo6VQb_EvEz9Ome {\n  height: 45px;\n  width: 45px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: var(--portal-page-primary-background);\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);\n  border: none; }\n  .jZDvfiDo6VQb_EvEz9Ome:hover {\n    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n    cursor: pointer; }\n  .jZDvfiDo6VQb_EvEz9Ome._16A_DIh7X5F_eH7_K5zE-e {\n    background: #b7b7b7; }\n\n._2eolR8gkDk27SVDU2UIkI3 {\n  height: 22px;\n  width: 22px;\n  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(323deg) brightness(101%) contrast(104%);\n  animation: _1hwN2NrIwOYc4lTmb0lTLs 0.5s alternate;\n  animation-fill-mode: forwards; }\n  ._2eolR8gkDk27SVDU2UIkI3._1ovBPbrGsEsnIjIFODFiaO {\n    animation: _1ovBPbrGsEsnIjIFODFiaO 0.5s alternate;\n    animation-fill-mode: forwards; }\n\n._3-VuEwh0-19w_x-Z0MaT3l {\n  height: 22px;\n  width: 22px;\n  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(323deg) brightness(101%) contrast(104%);\n  animation: _1ovBPbrGsEsnIjIFODFiaO 0.5s alternate;\n  animation-fill-mode: forwards; }\n  ._3-VuEwh0-19w_x-Z0MaT3l._1ovBPbrGsEsnIjIFODFiaO {\n    animation: _1hwN2NrIwOYc4lTmb0lTLs 0.5s alternate;\n    animation-fill-mode: forwards; }\n\n@keyframes _1ovBPbrGsEsnIjIFODFiaO {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(180deg); } }\n\n@keyframes _1hwN2NrIwOYc4lTmb0lTLs {\n  0% {\n    transform: rotate(180deg); }\n  100% {\n    transform: rotate(0deg); } }\n", ""]);
// Exports
exports.locals = {
	"circleButton": "jZDvfiDo6VQb_EvEz9Ome",
	"greyedOut": "_16A_DIh7X5F_eH7_K5zE-e",
	"arrowIcon": "_2eolR8gkDk27SVDU2UIkI3",
	"rotateback": "_1hwN2NrIwOYc4lTmb0lTLs",
	"rotate": "_1ovBPbrGsEsnIjIFODFiaO",
	"arrowIconDown": "_3-VuEwh0-19w_x-Z0MaT3l",
	"fadeInDown": "_2ef3BYsDfRgGPrhgEPmJyC"
};