import uniqueString from "unique-string"
import * as dateFns from "date-fns"

import { MonthlyBalanceItemSchema } from "../schemas/monthlyBalanceResponseSchema"
import {
  ProvisionItemElementSchema,
  ProvisionItemSchema,
} from "../schemas/provisioningResponseSchema"
import { DraftMonthlyBalanceItem, decodeMonthlyBalanceStartDate } from "./utils"
import {
  clientExpenses,
  rollOver,
  fetchMonthlyBalanceUpdate,
  fetchProvisioningUpdate,
} from "./services"
import { getYearlyFrequencyMultiplier } from "./utils"
import uuid from "react-uuid"
import _ from "lodash"

// ============================================================================
// draftMonthlyBalanceItemsReducer
// ============================================================================

export type DraftMonthlyBalanceItemsReducerActions =
  | { type: "CREATE_ITEMS"; monthlyBalanceStartDate: Date }
  | { type: "CREATE_ITEM"; monthlyBalanceStartDate: Date }
  | { type: "UPDATE_ITEM"; newItem: DraftMonthlyBalanceItem }
  | { type: "DELETE_ITEM"; id: string }
  | { type: "DELETE_ALL_ITEMS" }
  | { type: "INITIALISE_ITEMS"; initialState: DraftMonthlyBalanceItem[] }

const getMonthlyBalanceItem = startDate => {
  const monthlyBalanceItem: DraftMonthlyBalanceItem = {
    id: uniqueString(),
    year: String(startDate.getFullYear()),
    amount: 0,
    day: String(1),
    creditCardBalance: 0,
    month: dateFns.format(
      startDate,
      "MMMM"
    ) as MonthlyBalanceItemSchema["month"],
  }
  return monthlyBalanceItem
}

interface Interval {
  getStart: () => Date
  getEnd: () => Date
}

const createYearIntervalFromStartDate = (startDate: Date): Interval => ({
  getStart: () => startDate,
  getEnd: () => dateFns.addYears(startDate, 1),
})

const isMonthEqualOrWithinInterval = (date: Date, interval: Interval) =>
  dateFns.isSameMonth(date, interval.getStart()) ||
  dateFns.isSameMonth(date, interval.getEnd()) ||
  dateFns.isWithinInterval(date, {
    start: interval.getStart(),
    end: interval.getEnd(),
  })
const decodeMonthlyBalanceItemDate = (
  monthlyBalanceItem: MonthlyBalanceItemSchema
) =>
  monthlyBalanceItem &&
  dateFns.parse(
    monthlyBalanceItem.month,
    "MMMM",
    new Date(Number(monthlyBalanceItem.year), 1, 1)
  )

export const draftMonthlyBalanceItemsReducer = (
  state: DraftMonthlyBalanceItem[],
  action: DraftMonthlyBalanceItemsReducerActions
) => {
  switch (action.type) {
    case "CREATE_ITEMS": {
      let startDate = action.monthlyBalanceStartDate
      let monthlyBalanceItemsCount = 12
      let newItemsOnStart = false
      if (state.length !== 0) {
        const firstEntry = state[0]
        const firstEntrysDate = decodeMonthlyBalanceItemDate(firstEntry)
        const lastEntry = state[state.length - 1]
        const lastEntrysDate = decodeMonthlyBalanceItemDate(lastEntry)
        if (
          dateFns.isBefore(startDate, firstEntrysDate) ||
          dateFns.isEqual(startDate, firstEntrysDate)
        ) {
          monthlyBalanceItemsCount = dateFns.differenceInCalendarMonths(
            firstEntrysDate,
            startDate
          )
          newItemsOnStart = true
        } else if (
          dateFns.isAfter(startDate, firstEntrysDate) &&
          dateFns.isBefore(startDate, lastEntrysDate)
        ) {
          startDate = lastEntrysDate
          monthlyBalanceItemsCount =
            12 - dateFns.differenceInMonths(startDate, lastEntrysDate)
        } else if (dateFns.isAfter(startDate, lastEntrysDate)) {
          startDate = lastEntrysDate
          monthlyBalanceItemsCount = dateFns.differenceInMonths(
            startDate,
            lastEntrysDate
          )
        }
      }
      const monthlyBalanceItems = []
      let idx = newItemsOnStart ? 0 : 1
      while (idx < monthlyBalanceItemsCount) {
        const newEntrysDate = dateFns.addMonths(startDate, idx)
        const newItem = getMonthlyBalanceItem(newEntrysDate)
        monthlyBalanceItems.push(newItem)
        idx++
      }

      if (newItemsOnStart) return [...monthlyBalanceItems, ...state]
      return [...state, ...monthlyBalanceItems]
    }

    case "CREATE_ITEM": {
      let newEntrysDate = action.monthlyBalanceStartDate
      const newState = [...state]
      const budgetInterval = createYearIntervalFromStartDate(
        action.monthlyBalanceStartDate
      )
      const filterEntriesByDate = newState.filter(monthlyBalanceItem => {
        let date = decodeMonthlyBalanceItemDate(monthlyBalanceItem)
        return isMonthEqualOrWithinInterval(date, budgetInterval)
      })

      if (newState.length !== 0) {
        const lastEntry = filterEntriesByDate[filterEntriesByDate.length - 1]
        const lastEntrysDate = decodeMonthlyBalanceItemDate(lastEntry)
        newEntrysDate =
          typeof lastEntrysDate !== "undefined"
            ? dateFns.addMonths(lastEntrysDate, 1)
            : newEntrysDate
      }
      const newMonthlyBalanceItem: DraftMonthlyBalanceItem = getMonthlyBalanceItem(
        newEntrysDate
      )

      const monthlyBalanceItems = [...state, newMonthlyBalanceItem]
      return monthlyBalanceItems
    }

    case "UPDATE_ITEM": {
      const itemIndex = state.findIndex(x => x.id === action.newItem.id)
      const monthlyBalanceItems = [
        ...state.slice(0, itemIndex),
        action.newItem,
        ...state.slice(itemIndex + 1),
      ]
      return monthlyBalanceItems
    }

    case "DELETE_ITEM": {
      const itemIndex = state.findIndex(x => x.id === action.id)
      const monthlyBalanceItems = [
        ...state.slice(0, itemIndex),
        ...state.slice(itemIndex + 1),
      ]
      return monthlyBalanceItems
    }

    case "DELETE_ALL_ITEMS": {
      return []
    }

    case "INITIALISE_ITEMS": {
      state = action.initialState
      return state
    }

    default:
      throw new Error()
  }
}

// ============================================================================
// draftProvisionItemsReducer
// ============================================================================

export type DraftProvisionItemsReducerActions =
  | {
      type: "CREATE_ITEM_ELEMENT"
      itemIndex: number
      monthlyBalanceStartDate: Date
    }
  | {
      type: "UPDATE_ITEM_ELEMENT"
      itemIndex: number
      itemElementIndex: number
      newItemElement: ProvisionItemElementSchema
    }
  | {
      type: "DELETE_ITEM_ELEMENT"
      itemIndex: number
      itemElementIndex: number
    }
  | {
      type: "INITIALISE_ITEMS"
      initialState: ProvisionItemSchema[]
    }

export const draftProvisionItemsReducer = (
  state: ProvisionItemSchema[],
  action: DraftProvisionItemsReducerActions
) => {
  switch (action.type) {
    case "CREATE_ITEM_ELEMENT": {
      const provisionItem = state.find(
        (_, i) => i === action.itemIndex
      ) as ProvisionItemSchema

      const lastEntry = provisionItem.items[provisionItem.items.length - 1]
      const date = lastEntry
        ? lastEntry.date
        : action.monthlyBalanceStartDate.toISOString()

      const newProvisionItemElement: ProvisionItemElementSchema = {
        amount: 0,
        date,
        name: "",
      }
      const newProvisionItem: ProvisionItemSchema = {
        ...provisionItem,
        items: [...provisionItem.items, newProvisionItemElement],
      }
      return [
        ...state.slice(0, action.itemIndex),
        newProvisionItem,
        ...state.slice(action.itemIndex + 1),
      ]
    }

    case "UPDATE_ITEM_ELEMENT": {
      const provisionItem = state.find(
        (_, i) => i === action.itemIndex
      ) as ProvisionItemSchema

      const provisionItemElements = [
        ...provisionItem.items.slice(0, action.itemElementIndex),
        action.newItemElement,
        ...provisionItem.items.slice(action.itemElementIndex + 1),
      ]
      const newProvisionItem: ProvisionItemSchema = {
        ...provisionItem,
        items: provisionItemElements,
      }

      return [
        ...state.slice(0, action.itemIndex),
        newProvisionItem,
        ...state.slice(action.itemIndex + 1),
      ]
    }

    case "DELETE_ITEM_ELEMENT": {
      const provisionItem = state.find(
        (_, i) => i === action.itemIndex
      ) as ProvisionItemSchema

      const provisionItemElements = [
        ...provisionItem.items.slice(0, action.itemElementIndex),
        ...provisionItem.items.slice(action.itemElementIndex + 1),
      ]
      const newProvisionItem: ProvisionItemSchema = {
        ...provisionItem,
        items: provisionItemElements,
      }

      return [
        ...state.slice(0, action.itemIndex),
        newProvisionItem,
        ...state.slice(action.itemIndex + 1),
      ]
    }

    case "INITIALISE_ITEMS": {
      state = action.initialState
      return state
    }

    default:
      throw new Error()
  }
}

// ============================================================================
// client summary expense reducer
// ============================================================================

export const summaryExpenseReducer = (state, action) => {
  let newState = { ...state }
  let summaryExpense = newState.expenses.expenses.find(item => {
    if (_.has(item, "_id")) {
      return item._id === action.payload.expenseId
    } else {
      return item.id === action.payload.expenseId
    }
  })

  let findIndex = !_.has(action.payload, "expenseId")
    ? null
    : newState.expenses.expenses.findIndex(item => {
        const hasId = _.has(item, "_id")
        if (hasId) {
          return item._id === action.payload.expenseId
        } else {
          return item.id === action.payload.expenseId
        }
      })

  switch (action.type) {
    case "INITIALIZE_EXPENSES":
      return { ...action.payload }
    case "CHANGE_ESSENTIAL":
      summaryExpense.amount = Number(action.payload.amount)
      summaryExpense.basic = Number(action.payload.amount)
      newState.expenses.expenses[findIndex] = summaryExpense
      return newState
    case "CHANGE_DISCRETIONARY":
      summaryExpense.discretionary = Number(action.payload.discretionary)
      newState.expenses.expenses[findIndex] = summaryExpense
      return newState
    case "MONEY_SMARTS_SELECTOR":
      const { categoryExpense } = action.payload
      let newExpense = [...categoryExpense]

      if (_.isArray(categoryExpense)) {
        newState.expenses.expenses = newState.expenses.expenses.map(expense => {
          let expenseId = _.has(expense, "_id") ? expense._id : expense.id

          const sameId = categoryExpense.find(
            categoryE => categoryE.expenseId === expenseId
          )

          if (sameId) {
            newExpense = categoryExpense.filter(
              e => expenseId === sameId.expenseId
            )
          }
          expense.category = sameId ? sameId.category : expense.category

          return expense
        })

        return newState
      }

      summaryExpense.category = action.payload.category
      newState.expenses.expenses[findIndex] = summaryExpense

      return newState
    case "MONTHLY_SELECTOR_FIELD":
      summaryExpense.frequency = action.payload.frequency
      newState.expenses.expenses[findIndex] = summaryExpense
      return newState
    case "ADD_ADDITIONAL_BILL":
      const addedBill = {
        tier4: "Bills",
        customExpense: true,
        rollOverAdded: true,
        frequency: "",
        totalString: "0",
        monthly: "0",
        amount: 0,
        amountStr: "0",
        desc: "",
        basic: 0,
        discretionary: 0,
        category: action.payload.category,
        id: uuid(),
      }
      newState.expenses.expenses.push(addedBill)
      return newState
    case "ADD_ADDITIONAL_EXPENSE":
      const addedExpense = {
        tier4: "Spending",
        customExpense: true,
        rollOverAdded: true,
        frequency: "",
        totalString: "0",
        monthly: "0",
        amount: 0,
        amountStr: "0",
        desc: "",
        basic: 0,
        discretionary: 0,
        category: action.payload.category,
        id: uuid(),
      }
      newState.expenses.expenses.push(addedExpense)
      return newState
    case "CHANGE_CUSTOM_DESC":
      summaryExpense.desc = action.payload.desc
      newState.expenses.expenses[findIndex] = summaryExpense
      return newState
    case "DELETE_CUSTOM_EXPENSE":
      const filterArray = newState.expenses.expenses.filter(expense => {
        if (_.has(expense, "_id")) {
          return expense._id !== action.payload.id
        } else {
          return expense.id !== action.payload.id
        }
      })
      newState.expenses.expenses = filterArray
      return newState
    case "COMPLETE_ROLLOVER":
      const {
        userId,
        monthlyBalance,
        summary,
        changedExpenses,
        monthlyBalanceItemsWithinInterval,
        isNotWithinInterval,
        historical_data,
        live_data,
        isUserTPC,
        startDate,
      } = action.payload
      const annualSpendingsArr = changedExpenses.map(expense => {
        let newAmount: number
        if (expense.frequency) {
          newAmount =
            expense.amount * getYearlyFrequencyMultiplier(expense.frequency)
        } else {
          newAmount = 0
        }
        return newAmount
      })

      const annualDiscretionaryArr = changedExpenses.map(expense => {
        let newDiscretionary: number
        if (expense.frequency) {
          newDiscretionary =
            expense.discretionary *
            getYearlyFrequencyMultiplier(expense.frequency)
        } else {
          newDiscretionary = 0
        }
        return newDiscretionary
      })

      const totalAnnualSpending = annualSpendingsArr.reduce((a, b) => a + b, 0)
      const totalAnnualDiscretionary = annualDiscretionaryArr.reduce(
        (a, b) => a + b,
        0
      )

      const sendExpenses = {
        annualSpendings: {
          amount: totalAnnualSpending,
          discretionary: totalAnnualDiscretionary,
          basic: null,
        },
        annualBills: {
          amount: null,
          discretionary: null,
          basic: null,
        },
        expenses: changedExpenses,
      }

      clientExpenses(isUserTPC, sendExpenses)

      // Send expenses with annual amount and discretionary

      const { day, month, year } = monthlyBalance.startData
      const startData = { day, month, year: `${Number(year) + 1}` }
      const monthlyBalanceItems = monthlyBalance.monthlyBalance[0].items
      const fullMonth = dateFns.format(new Date(year, month, day), "MMMM")
      const newMonthlyBalance = monthlyBalanceItems.find(
        m => m.month === fullMonth && m.year === startData.year
      ) || {
        day,
        month: fullMonth,
        year: startData.year,
        creditCardBalance: 0,
        amount: 0,
      }

      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]

      let monthsNumber = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
      ]

      const firstEntryNextRollover =
        monthlyBalanceItemsWithinInterval[
          monthlyBalanceItemsWithinInterval.length - 1
        ]

      let monthsInIndex = months.indexOf(firstEntryNextRollover.month)
      let monthsInNumberIndex = monthsNumber.indexOf(
        firstEntryNextRollover.month
      )
      const convertPassedStart = new Date(startDate)
      let dayStartData = String("0" + convertPassedStart.getDate()).slice(-2)

      let monthsInNumbers
      if (monthsInIndex === -1 && monthsInNumberIndex !== -1) {
        monthsInNumbers = monthsInNumberIndex
      } else {
        monthsInNumbers = monthsInIndex
      }

      let newstartData = {
        day: dayStartData,
        month: String(monthsInNumbers),
        year: firstEntryNextRollover.year,
        creditCardBalance: 0,
        amount: 0,
      }

      if (
        isNotWithinInterval.length > 1 &&
        monthlyBalanceItemsWithinInterval.length === 13
      ) {
        fetchMonthlyBalanceUpdate(isUserTPC, {
          startData: newstartData,
          monthlyBalance: [{ items: isNotWithinInterval }],
        })
      } else {
        fetchMonthlyBalanceUpdate(isUserTPC, {
          startData: newstartData,
          monthlyBalance: [{ items: firstEntryNextRollover }],
        })
      }

      //history
      rollOver(
        isUserTPC,
        userId,
        { provisionItems: historical_data },
        {
          startData: monthlyBalance.startData,
          monthlyBalance: [{ items: monthlyBalanceItemsWithinInterval }],
        },
        summary
      )

      fetchProvisioningUpdate(
        {
          provisionItems: live_data,
        },
        action.payload.isUserTPC
      )

      return newState
    case "USER_CANCEL_ROLLOVER":
      return state
    default:
      return state
  }
}

export const showRolloverReducer = (state, action) => {
  let newState = { ...state }
  switch (action.type) {
    case "SHOW_ROLLOVER_INTRO":
      newState.showSplash = true
      newState.showRolloverModal = false
      return newState
    case "SHOW_ROLLOVER_MODAL":
      newState.showSplash = false
      newState.showRolloverModal = true
      return newState
    case "CLOSE_ROLLOVER_MODAL":
      newState.showSplash = false
      newState.showRolloverModal = false
      newState.completeRollOver = true
      return newState
    default:
      return state
  }
}

export const editProvisionReducer = (state, action) => {
  let newState = { ...state }
  const foundProvision = newState.provisionItems.find(provision => {
    return provision.categoryId === action.payload.provisionId
  })
  switch (action.type) {
    case "INITIALISE_PROVISION":
      newState = action.payload.newProvision
      return newState
    case "ADD_PROVISION_ITEMS":
      return newState
    case "CHANGE_PROVISION_NAME":
      const findItemToEdit = foundProvision.items[action.payload.index]
      findItemToEdit.name = action.payload.value
      return newState
    case "CHANGE_PROVISION_AMOUNT":
      const amountToEdit = foundProvision.items[action.payload.index]
      amountToEdit.amount = action.payload.value
      return newState
    case "CHANGE_PROVISION_DATE":
      const dateToEdit = foundProvision.items[action.payload.index]
      dateToEdit.date = action.payload.value
      return newState
    case "ADD_PROVISION":
      const additionalProvision = {
        name: "",
        date: action.payload.date,
        amount: 0,
      }
      foundProvision.items.push(additionalProvision)
      return newState
    case "SUBTRACT_PROVISION":
      foundProvision.items.splice(action.payload.index, 1)
      return newState
    default:
      return state
  }
}
