exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes _25ej2RI80uJaKeFDqY97u1 {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes _25ej2RI80uJaKeFDqY97u1 {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n._3pMWywgsJT2WjVow9oD_XR {\n  text-align: center;\n  position: absolute;\n  z-index: -1; }\n  ._3pMWywgsJT2WjVow9oD_XR._2xze76BkrvOAAnX28Tnbbv {\n    position: static; }\n\n.SNIXasFxsz2MKeUWto4ed {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n.i9nHH10yc4yPW9OrANamh {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  margin-bottom: 0;\n  margin-top: 0.3em;\n  font-weight: 500;\n  font-size: 12px; }\n  .i9nHH10yc4yPW9OrANamh.hmiCWkb7UPNXs5DdvbFK5 {\n    font-size: 12px; }\n\n@media (min-width: 1024px) {\n  .i9nHH10yc4yPW9OrANamh {\n    font-size: 16px; }\n    .i9nHH10yc4yPW9OrANamh.hmiCWkb7UPNXs5DdvbFK5 {\n      font-size: 16px; } }\n", ""]);
// Exports
exports.locals = {
	"insideCircleText": "_3pMWywgsJT2WjVow9oD_XR",
	"outsideCircleText": "_2xze76BkrvOAAnX28Tnbbv",
	"doughtnutChartWrapper": "SNIXasFxsz2MKeUWto4ed",
	"totalText": "i9nHH10yc4yPW9OrANamh",
	"amountText": "hmiCWkb7UPNXs5DdvbFK5",
	"fadeInDown": "_25ej2RI80uJaKeFDqY97u1"
};