import { getMonthlyFrequencyMultiplier } from "./utils"

export const options = {
  tooltips: {
    callbacks: {
      label: function(tooltipItem: any, data: any) {
        let dataset = data.datasets[tooltipItem.datasetIndex]
        let index = tooltipItem.index
        return `${dataset.labels[index]} : $ ${dataset.data[index]}`
      },
    },
  },
  maintainAspectRatio: false,
}

export const expenseMapper = (type: string, expenseArray: any) => {
  if (type === "lifestyle" || type === "creditCard" || type === "provision") {
    return expenseArray.map(
      (expense: { frequency: any; amount: number; desc: any }) => {
        const expenseObject = {
          amount: (expense.frequency
            ? expense.amount * getMonthlyFrequencyMultiplier(expense.frequency)
            : 0
          ).toFixed(2),

          category: expense.desc,
        }
        return expenseObject
      }
    )
  } else {
    return expenseArray.map(
      (expense: { repaymentFreq: any; repayment: number; type: any }) => {
        const expenseObject = {
          amount: (expense.repaymentFreq
            ? expense.repayment *
              getMonthlyFrequencyMultiplier(expense.repaymentFreq)
            : 0
          ).toFixed(2),
          category: expense.type,
        }
        return expenseObject
      }
    )
  }
}

export const expenseDoughnutColors = [
  "rgba(252, 43, 64,1)", //lnl jar
  "rgba(252, 78, 49,1)", //cc bills
  "rgba(255, 87, 128,1)", //loans
  "rgba(252, 43, 128,1)", //provision
]

export const backgroundColorPrimary = [
  "rgba(163,209,55,1)",
  "rgba(219,21,31,1)",
  "rgba(163,209,55,1)",
]
export const backgroundColorSecondary = [
  "rgba(70,149,55,1)",
  "rgba(74,10,13,1)",
  "rgba(70,149,55,1)",
]

//   "rgba(252, 100, 50, 1", //directpayment
