import React, { useContext, useEffect, useState } from "react"
import styles from "./ProvisionModal.module.scss"
import ProvisionReducer from "../../context/ProvisionsReducer"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import minusBtn from "../../images/icons/minus-circle-solid.svg"
import PlusBtn from "../../images/icons/plus-circle-solid.svg"
import _ from "lodash"
import clsx from "clsx"
import { fetchProvisioningUpdate } from "../../lib/services"
import * as dateFns from "date-fns"
import AppDataContext from "../../context/AppDataContext"

const ProvisionModal = ({
  expenseId,
  showModalHandler,
  reducerProvisions,
  isEditable,
  startDate,
}) => {
  const { provisionDispatch } = useContext(ProvisionReducer)
  const { isUserTPC } = useContext(AppDataContext)

  const findProvision = reducerProvisions.provisionItems.find(
    provisionItem => provisionItem.categoryId === expenseId
  )

  const [selectedDate, setSelectedDate] = useState([])
  const [inputNames, setInputNames] = useState([])
  const [inputAmounts, setInputAmounts] = useState([])
  const [savingProvision, setSavingProvision] = useState(false)
  const [messageToggle, setMessageToggle] = useState(false)
  useEffect(() => {
    let provisionDates = []
    let provisionNames = []
    let provisionAmount = []
    findProvision.items.map(provision => {
      provisionDates.push(provision.date)
      provisionNames.push(provision.name)
      provisionAmount.push(provision.amount !== null ? provision.amount : 0)
    })
    setInputNames(provisionNames)
    setSelectedDate(provisionDates)
    setInputAmounts(provisionAmount)
  }, [reducerProvisions, findProvision.items])

  const handleDateChange = (date, index) => {
    provisionDispatch({
      type: "CHANGE_PROVISION_DATE",
      payload: {
        provisionId: findProvision.categoryId,
        value: date,
        index,
      },
    })

    const newSelectDates = selectedDate.map((item, i) => {
      if (i === index) {
        item = date
      }
      return item
    })
    setSelectedDate(newSelectDates)
  }

  const inputModalHandler = (val, index) => {
    provisionDispatch({
      type: "CHANGE_PROVISION_NAME",
      payload: {
        provisionId: findProvision.categoryId,
        value: val.target.value,
        index,
      },
    })

    const newInputName = inputNames.map((item, i) => {
      if (i === index) {
        item = val.target.value
      }
      return item
    })

    setInputNames(newInputName)
  }

  const tableButtonsHandler = (type, index) => {
    if (type === "ADD_PROVISION") {
      provisionDispatch({
        type: "ADD_PROVISION",
        payload: {
          provisionId: findProvision.categoryId,
          index,
          date: startDate,
        },
      })
    } else if (type === "SUBTRACT_PROVISION") {
      provisionDispatch({
        type: "SUBTRACT_PROVISION",
        payload: {
          provisionId: findProvision.categoryId,
          index,
        },
      })
    }
  }

  const saveProvisionHandler = () => {
    let emptyInputExist = false
    findProvision.items.forEach(function(v, i) {
      if (
        // get all properties and check any of it's value is null or empty string
        Object.keys(v).some(function(k) {
          return v[k] === null
        }) ||
        Object.keys(v).some(function(k) {
          return v[k] === undefined
        })
      ) {
        emptyInputExist = true
      }
    })

    if (emptyInputExist) {
      setSavingProvision(false)
    } else {
      setSavingProvision(true)
    }

    setMessageToggle(true)
    setTimeout(() => {
      setMessageToggle(false)
    }, 3500)

    if (!emptyInputExist) {
      fetchProvisioningUpdate(reducerProvisions, isUserTPC).then(res => {
        if (!res.error) {
          setSavingProvision(true)
        } else {
          setSavingProvision(false)
        }
      })
    }
  }

  const newProvisionHandler = (type, index, id) => {
    provisionDispatch({
      type,
      payload: {
        provisionId: id,
        index,
        date: startDate,
      },
    })
  }

  const inputAmountHandler = (val, index) => {
    const first = val.target.value.charAt(0)
    if (first === "0" && val.target.value.length > 1) {
      val.target.value = val.target.value.substr(1)
    }
    provisionDispatch({
      type: "CHANGE_PROVISION_AMOUNT",
      payload: {
        provisionId: findProvision.categoryId,
        value: val.target.value !== "" ? val.target.value : 0,
        index,
      },
    })

    const newInputAmount = inputAmounts.map((item, i) => {
      if (i === index) {
        item = val.target.value
      }
      return item
    })

    setInputAmounts(newInputAmount)
  }

  const provisionTitle = _.get(findProvision, "category", "")
  const maxDate = dateFns.subDays(dateFns.addMonths(startDate, 12), 1)

  const saveMessage = !savingProvision
    ? "Changes not saved. Please fill up all fields"
    : "Changes Saved!"

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalBox}>
        <p onClick={showModalHandler} className={styles.modalExitX}>
          &times;
        </p>
        <h2 className={styles.provisionTitle}>{provisionTitle}</h2>
        <div className={styles.btnBar}>
          <div></div>
          <div className={styles.buttonsContainer}>
            <button className={styles.headerBtnOne} onClick={showModalHandler}>
              Close
            </button>
            <button
              className={styles.headerBtnTwo}
              onClick={saveProvisionHandler}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          {messageToggle ? <p>{saveMessage}</p> : null}
          {findProvision.items.length <= 0 ? (
            <div
              className={clsx(
                styles.tableButtonsWrapper,
                styles.noProvisionBtn
              )}
            >
              {isEditable ? (
                <div
                  onClick={() =>
                    newProvisionHandler("ADD_PROVISION", 0, expenseId)
                  }
                  className={clsx(styles.tableButtons, styles.withText)}
                >
                  <p>Add Spent</p>
                  <img
                    alt="plus-button"
                    className={styles.tablePlus}
                    src={PlusBtn}
                  />
                </div>
              ) : (
                <p>For Viewing Only</p>
              )}
            </div>
          ) : (
            <table className={styles.provisionFormTable}>
              <thead>
                <tr className={styles.tableRow}>
                  <th>Expense Name</th>
                  <th>Expense Date</th>
                  <th>Expense Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {findProvision.items.map((provision, index) => {
                  const selectDateValue = selectedDate[index]
                  const inputNameValue = inputNames[index]
                  const inputAmountValue = inputAmounts[index]
                  return (
                    <tr
                      key={`input-provision-${index}`}
                      className={styles.tableRow}
                    >
                      <td>
                        <input
                          disabled={!isEditable}
                          defaultValue={provision.name}
                          value={inputNameValue}
                          onChange={val => inputModalHandler(val, index)}
                        />
                      </td>
                      <td>
                        <div className={styles.calendarSelector}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            key={`date-picker-${index}`}
                          >
                            <KeyboardDatePicker
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id={`date-picker-inline-${index}`}
                              disabled={!isEditable}
                              value={selectDateValue}
                              style={{ borderBottom: "1px #000 solid" }}
                              onChange={date => handleDateChange(date, index)}
                              minDate={startDate}
                              maxDate={maxDate}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </td>
                      <td>
                        <input
                          disabled={!isEditable}
                          type="number"
                          defaultValue={provision.amount}
                          value={inputAmountValue}
                          onChange={val => inputAmountHandler(val, index)}
                        />
                      </td>
                      {index === findProvision.items.length - 1 ? (
                        <td>
                          {isEditable ? (
                            <div className={styles.tableButtonsWrapper}>
                              <div className={styles.tableButtons}>
                                <img
                                  onClick={() =>
                                    tableButtonsHandler("ADD_PROVISION", index)
                                  }
                                  className={styles.tablePlus}
                                  src={PlusBtn}
                                  alt="add-button"
                                />
                              </div>
                              <div className={styles.tableButtons}>
                                <img
                                  onClick={() =>
                                    tableButtonsHandler(
                                      "SUBTRACT_PROVISION",
                                      index
                                    )
                                  }
                                  className={styles.tableMinus}
                                  src={minusBtn}
                                  alt="subtract-button"
                                />
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </td>
                      ) : (
                        <td>
                          <div className={styles.tableButtons}>
                            <img
                              onClick={() =>
                                tableButtonsHandler("SUBTRACT_PROVISION", index)
                              }
                              className={styles.tableMinus}
                              src={minusBtn}
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProvisionModal
