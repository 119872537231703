import React from "react"
import { TextField } from "@material-ui/core"
import NumberFormat from "react-number-format"
interface Props {
  fullWidth?: boolean
  type?: string
  unit?: string
  onSearch?: () => any
  disabled?: boolean
  error?: string
  helperText?: string
  float?: boolean
  name?: string
  onChange?: any
  onBlur?: any
  value: any
  inputClass?: any
  showPassword?: any
  label?: any
  onKeyDown?: any
  errorText?: any
  inputProps?: any
  index?: any
  tabIndex?: number
  autoFocus?: boolean
}

const InputForm: React.FC<Props> = ({
  name,
  onChange,
  onBlur,
  value,
  inputClass,
  showPassword,
  type,
  label,
  onKeyDown,
  fullWidth,
  errorText,
  helperText,
  error,
  disabled,
  inputProps,
  index,
  tabIndex,
  autoFocus,
}) => {
  if (type === "number") {
    return (
      <NumberFormat
        value={value}
        name={name}
        decimalScale={2}
        allowEmptyFormatting={false}
        prefix={"$"}
        fixedDecimalScale={true}
        thousandSeparator={true}
        customInput={TextField}
        onValueChange={values => {
          onChange &&
            onChange(
              {
                target: {
                  name: name,
                  value: values.value,
                },
              },
              index
            )
        }}
        className={inputClass}
        variant="outlined"
        margin={"dense"}
        label={label && label ? label : ""}
        onKeyDown={onKeyDown}
        fullWidth={fullWidth}
        helperText={errorText || error || helperText}
        disabled={disabled}
        error={!!errorText || !!error}
        InputProps={inputProps}
        defaultValue={type === "number" ? 0 : ""}
        outputFormat="number"
        digitGroupSeparator=","
        decimalCharacter="."
        currencySymbol="$"
        decimalPlaces={2}
        maximumValue="11111111111111111111111111"
        tabIndex={tabIndex && tabIndex}
        autoFocus={autoFocus}
      />
    )
  }
  return (
    <TextField
      name={name}
      onChange={e => onChange && onChange(e as any, index)}
      onBlur={e => onChange && onChange(e as any, index)}
      value={value}
      className={inputClass}
      type={showPassword || type === "search" ? "text" : type}
      variant="outlined"
      margin={"dense"}
      label={label && label ? label : ""}
      onKeyDown={onKeyDown}
      fullWidth={fullWidth}
      helperText={errorText || error || helperText}
      disabled={disabled}
      error={!!errorText || !!error}
      InputProps={inputProps}
      defaultValue={type === "number" ? 0 : ""}
    />
  )
}

export default React.memo(InputForm)
