import React from "react"
import ProvisionSectionJar from "../components/ProvisionsSection/ProvisionSectionJar"

const ProvisionSectionWrapper = ({ wrapperProvision, monthCheckDate }) => {
  return (
    <ProvisionSectionJar
      jarProvision={wrapperProvision}
      monthCheckDate={monthCheckDate}
    />
  )
}

export default ProvisionSectionWrapper
