import React, { useContext } from "react"
import SnapshotCards from "./SnapshotCards"
import AppDataContext from "../../context/AppDataContext"

const SnapshotSection = () => {
  const { isEditable, newDashboardValues } = useContext(AppDataContext)

  const cardData = [
    {
      label: "Annual Snapshot",
      moneyIn: newDashboardValues.totalNetIncome,
      moneyOut: newDashboardValues.totalExpenditure,
      type: "Annual",
    },
    {
      label: "Monthly Snapshot",
      moneyIn: newDashboardValues.totalNetIncome / 12,
      moneyOut: newDashboardValues.totalExpenditure / 12,
      type: "Monthly",
    },
  ]

  return (
    <section>
      {cardData.map((card, index) => {
        return (
          <SnapshotCards
            key={`snapshot-card-${index}`}
            type={card.type}
            label={card.label}
            moneyIn={card.moneyIn}
            moneyOut={card.moneyOut}
            isEditable={isEditable}
          />
        )
      })}
      <br />
    </section>
  )
}

export default SnapshotSection
