import React, { useContext } from "react"
import { i_InfoControl } from "./Rollover.interface"
import style from "./Rollover.module.scss"
import SummaryExpenseContext from "../../context/SummaryExpenseContext"
import AppDataContext from "../../context/AppDataContext"
import ProvisionReducer from "../../context/ProvisionsReducer"
import moment from "moment"

const ExpensesModalInfoControl: React.FC<i_InfoControl> = ({
  title,
  description,
  setIndexHandler,
  isLastControl,
  firstStepModal,
  beforeClosing,
  getRollOverData,
  tempExpenses,
  setTempExpenses,
  startDate,
}) => {
  const { reduceExpenses, summaryDispatch } = useContext(SummaryExpenseContext)
  const { monthlyBalanceItemsWithinInterval, isNotWithinInterval } = useContext(
    ProvisionReducer
  )
  const {
    userId,
    summary,
    monthlyBalance,
    provisioning,
    rolloverDispatch,
    isUserTPC,
  } = useContext(AppDataContext)

  const completeRolloverHandler = dispatch => {
    if (dispatch === "COMPLETE_ROLLOVER") {
      const historical_data = []
      const live_data = []

      provisioning.provisionItems.forEach(p => {
        if (p.items.length > 0) {
          const liveData = []
          const hisData = []
          for (let item of p.items) {
            const sdate = moment(startDate)
              .add(12, "month")
              .format("YYYY-MM-DD")

            if (moment(item.date).isAfter(sdate)) {
              liveData.push(item)
            } else {
              hisData.push(item)
            }
          }

          if (liveData.length > 0) {
            live_data.push({
              category: p.category,
              categoryId: p.categoryId,
              items: liveData,
            })
          }

          if (hisData.length > 0) {
            historical_data.push({
              category: p.category,
              categoryId: p.categoryId,
              items: hisData,
            })
          }
        } else {
          live_data.push({
            category: p.category,
            categoryId: p.categoryId,
            items: [],
          })
        }
      })

      summaryDispatch({
        type: dispatch,
        payload: {
          historical_data,
          live_data,
          expenseId: "",
          userId,
          summary,
          monthlyBalance,
          provisioning,
          changedExpenses: reduceExpenses.expenses.expenses,
          monthlyBalanceItemsWithinInterval,
          isNotWithinInterval,
          isUserTPC,
          startDate,
        },
      })
    } else if (dispatch === "CLOSE_ROLLOVER_MODAL") {
      rolloverDispatch({ type: dispatch })
      getRollOverData()
    }
  }

  const organizeCategoryHandler = async tempExpenses => {
    if (tempExpenses.length > 0) {
      await summaryDispatch({
        type: "MONEY_SMARTS_SELECTOR",
        payload: {
          categoryExpense: tempExpenses,
        },
      })
    }
  }

  return (
    <div className={style.rolloverModalB}>
      <h4>{title}</h4>
      <div>{description}</div>
      <div className={style.buttonFooter}>
        {isLastControl && (
          <button
            className={style.btnNext}
            onClick={() => {
              completeRolloverHandler("CLOSE_ROLLOVER_MODAL")
            }}
          >
            <span>Complete</span>
          </button>
        )}

        {!isLastControl && (
          <>
            <button
              className={style.btnPrev}
              onClick={async () => {
                if (firstStepModal) {
                  setTempExpenses([])
                  summaryDispatch({
                    type: "USER_CANCEL_ROLLOVER",
                    payload: {
                      expenseId: "",
                    },
                  })
                  rolloverDispatch({ type: "CLOSE_ROLLOVER_MODAL" })
                } else {
                  setIndexHandler("back")
                  organizeCategoryHandler(tempExpenses)
                }
              }}
            >
              {firstStepModal ? "Cancel" : "Back"}
            </button>
            <button
              className={style.btnNext}
              onClick={() => {
                organizeCategoryHandler(tempExpenses)
                if (beforeClosing) {
                  setIndexHandler("next")
                  completeRolloverHandler("COMPLETE_ROLLOVER")
                } else {
                  setIndexHandler("next")
                }
              }}
            >
              Next
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default ExpensesModalInfoControl
