import React, { useContext } from "react"
import ReactDOM from "react-dom"
import style from "./Rollover.module.scss"
import RollOverInit from "./Rollover"
import AppDataContext from "../../context/AppDataContext"

const RolloverSplash: React.FC<Props> = ({ getRollOverData }) => {
  const modalRoot = document.getElementById("modals")
  const { showRolloverState, rolloverDispatch } = useContext(AppDataContext)

  if (!showRolloverState.showSplash && showRolloverState.showRolloverModal) {
    return ReactDOM.createPortal(
      <RollOverInit getRollOverData={getRollOverData} />,
      modalRoot
    )
  } else if (
    showRolloverState.showSplash &&
    !showRolloverState.showRolloverModal
  ) {
    return ReactDOM.createPortal(
      <div className={style.wholeModalWrapper}>
        <div className={style.modalContainer}>
          <div className={style.splash}>
            <p
              onClick={() => rolloverDispatch({ type: "CLOSE_ROLLOVER_MODAL" })}
              className={style.exitBtn}
            >
              &times;
            </p>
            <h2>Rollover</h2>
            <p>
              By continuing with the rollover, you will be starting a new
              12-month provisioning period. Your previous data will be archived
              and still viewable.
            </p>
            <p>
              When you start the rollover, we will guide you through the
              rollover checklist.
            </p>
            <button
              className="button"
              onClick={() => rolloverDispatch({ type: "SHOW_ROLLOVER_MODAL" })}
            >
              Commence Rollover
            </button>
          </div>
        </div>
      </div>,
      modalRoot
    )
  } else {
    return null
  }
}

export default RolloverSplash

interface Props {
  getRollOverData: () => void
}
