exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes _2g_WYa_ENhJXzEG9XXpIma {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes _2g_WYa_ENhJXzEG9XXpIma {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n.i2-u2dDg3Tup1WmtsU4-5 {\n  display: grid;\n  grid-gap: 1em; }\n\n@media (min-width: 1280px) {\n  .i2-u2dDg3Tup1WmtsU4-5 {\n    grid-template-columns: 1fr 1fr; }\n  ._3WoYox0AvZcE1u3aziDRNu {\n    font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n    text-align: center; } }\n", ""]);
// Exports
exports.locals = {
	"lineChartContainer": "i2-u2dDg3Tup1WmtsU4-5",
	"graphHeader": "_3WoYox0AvZcE1u3aziDRNu",
	"fadeInDown": "_2g_WYa_ENhJXzEG9XXpIma"
};