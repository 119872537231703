exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes NRtL8sR85rE3JqiKFi0my {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes NRtL8sR85rE3JqiKFi0my {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n._1JLuMrIif43A9l845S7cdD {\n  overflow: hidden;\n  height: 0; }\n\n._3RmGXl2o7vvkUwZUcs8pZZ {\n  border-collapse: separate;\n  border-spacing: 0 0.7em;\n  width: 100%; }\n\n._13gwb9Yt-EsIOg5L5wBhWx {\n  border-right: 1px #fff solid;\n  margin-bottom: 0.7em; }\n\n.Gdha7B0WYyomwd4nY2tzP {\n  background: #e7e7e7;\n  border-radius: 5px;\n  margin-bottom: 0.5em;\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }\n  .Gdha7B0WYyomwd4nY2tzP > td {\n    padding: 0.5em !important;\n    font-family: \"Poppins\", \"Helvetica\", \"sans-serif\", -apple-system, monospace !important; }\n  .Gdha7B0WYyomwd4nY2tzP > th {\n    padding: 0.5em !important;\n    font-family: \"Poppins\", \"Helvetica\", \"sans-serif\", -apple-system, monospace !important; }\n", ""]);
// Exports
exports.locals = {
	"tableWrapperDiv": "_1JLuMrIif43A9l845S7cdD",
	"incomeTable": "_3RmGXl2o7vvkUwZUcs8pZZ",
	"incomeRow": "_13gwb9Yt-EsIOg5L5wBhWx",
	"tableRow": "Gdha7B0WYyomwd4nY2tzP",
	"fadeInDown": "NRtL8sR85rE3JqiKFi0my"
};