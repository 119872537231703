import React, { useContext } from "react"
import { Doughnut } from "react-chartjs-2"
import { options } from "../../lib/gaugehelpers"
import { formatCurrency } from "../../lib/utils"
import styles from "./SurplusTargetGauge.module.scss"
import AppDataContext from "../../context/AppDataContext"
import clsx from "clsx"

const SurplusTargetGauge: React.FC = () => {
  const {
    isDesktop,
    isTabletSmall,
    newDashboardValues,
    accumulatedSurplus,
  } = useContext(AppDataContext)

  let targetedAnnualSurplus =
    newDashboardValues.totalNetIncome - newDashboardValues.totalExpenditure
  let doughnutGap
  if (
    accumulatedSurplus &&
    accumulatedSurplus < targetedAnnualSurplus &&
    Math.sign(accumulatedSurplus) !== -1
  ) {
    doughnutGap = targetedAnnualSurplus - accumulatedSurplus
  } else if (
    accumulatedSurplus &&
    accumulatedSurplus < targetedAnnualSurplus &&
    Math.sign(accumulatedSurplus) === -1
  ) {
    doughnutGap = targetedAnnualSurplus + accumulatedSurplus
  } else if (
    accumulatedSurplus &&
    accumulatedSurplus >= targetedAnnualSurplus
  ) {
    doughnutGap = 0
  } else if (accumulatedSurplus === 0) {
    doughnutGap = targetedAnnualSurplus
  }

  doughnutGap = Math.sign(doughnutGap) === -1 ? 0 : doughnutGap

  let surplusBackgroundColor =
    accumulatedSurplus > 0 ? "RGB(163,209,55)" : "rgb(252,44,64)"

  const doughnutData = {
    datasets: [
      {
        data: [accumulatedSurplus, doughnutGap],
        label: "one",
        labels: ["Current Surplus", "The Gap"],
        backgroundColor: [surplusBackgroundColor],
        fill: true,
      },
      {
        data: [targetedAnnualSurplus?.toFixed(2)],
        label: "two",
        labels: ["Target Annual Surplus"],
        backgroundColor: ["RGB(254,154,46)"],
        fill: true,
      },
    ],
  }

  let doughtnutSize: number

  if (isDesktop && !isTabletSmall) {
    doughtnutSize = 300
  } else if (isTabletSmall && !isDesktop) {
    doughtnutSize = 200
  } else {
    doughtnutSize = 250
  }

  return (
    <div className={styles.doughtnutChartWrapper}>
      <div>
        <Doughnut
          data={doughnutData}
          options={options}
          height={doughtnutSize}
          width={doughtnutSize}
        />
      </div>
      <div className={clsx(styles.insideCircleText)}>
        <p className={styles.totalText}>Current Surplus</p>
        <p className={clsx(styles.totalText, styles.amountText)}>
          {formatCurrency(accumulatedSurplus)}
        </p>
      </div>
    </div>
  )
}

export default SurplusTargetGauge
