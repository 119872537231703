import React, { useContext, useState, useEffect } from "react"
import RenderRolloverRow from "./RenderRolloverRow"
import ExpensesModalInfoControl from "./ExpensesModalInfoControl"
import SummaryExpenseContext from "../../context/SummaryExpenseContext"
import PlusBtn from "../../images/icons/plus-circle-solid.svg"
import _ from "lodash"
import { i_ExpensesModal } from "./Rollover.interface"
import style from "./Rollover.module.scss"

import {
  getLivingLifestyleExpenses,
  getCreditCardExpenses,
  getDirectPaymentsExpenses,
  getProvisionsExpenses,
  getMonthlyFrequencyMultiplier,
  getUnallocatedExpense,
} from "./Rollover.utils"
import AppDataContext from "../../context/AppDataContext"
import moment from "moment"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const ExpensesModal: React.FC<i_ExpensesModal> = ({
  category,
  description,
  hidePrimaryModal,
  infoControl,
  setIndexHandler,
  firstStepModal,
  beforeClosing,
  getRollOverData,
}) => {
  const { reduceExpenses, summaryDispatch } = useContext(SummaryExpenseContext)
  const { monthlyBalance } = useContext(AppDataContext)
  const sdata = monthlyBalance.startData ?? null

  const sdate = sdata
    ? moment(`${monthNames[sdata.month]} ${sdata.day}, ${sdata.year}`).format(
        "YYYY-MM-DD"
      )
    : moment().format("YYYY-MM-DD")

  const [startDate, setStartDate] = useState(sdate)
  useEffect(
    function() {
      const { year: y, month: m, day: d } = monthlyBalance.startData
      const nwdate = moment(`${monthNames[m]} ${d}, ${y}`).format("YYYY-MM-DD")
      setStartDate(nwdate)
    },
    [monthlyBalance.startData]
  )

  const shortCut = expenseObject => expenseObject.expenses.expenses

  let allCustomExpense = shortCut(reduceExpenses)
    .filter(expense => expense.customExpense)
    .map(expense => {
      if (expense.desc === "example") {
      }

      let expenseId = _.has(expense, "_id") ? expense._id : expense.id
      return { category: expense.category, expenseId }
    })

  useEffect(() => {}, [reduceExpenses])

  const [tempExpenses, setTempExpenses] = useState(allCustomExpense)

  const isEmpty = item => {
    if (item === null || item === 0) {
      return true
    } else {
      return false
    }
  }
  const arrayOfUnallocatedInit = getUnallocatedExpense(
    reduceExpenses.expenses.expenses
  )
    .filter(expenseItem => {
      const isEmptyAmount = isEmpty(expenseItem.amount)
      return isEmptyAmount
    })
    .map(expenseId => expenseId._id)

  const [unallocatedExpenseId, setUnallocatedExpenseId] = useState(
    // eslint-disable-line
    arrayOfUnallocatedInit
  )

  const getExpensesByCategory = () => {
    const { expenses } = reduceExpenses.expenses

    switch (category) {
      case "Living and Lifestyle Account":
        return getLivingLifestyleExpenses(expenses)
      case "Credit Card":
        return getCreditCardExpenses(expenses)
      case "Direct Payments Jar":
        return getDirectPaymentsExpenses(expenses)
      case "Provisions Roll Over":
        return getProvisionsExpenses(expenses)
      default:
        return getUnallocatedExpense(expenses).filter(expenseItem => {
          return unallocatedExpenseId.includes(expenseItem._id)
        })
    }
  }

  let dispatchCategory
  switch (category) {
    case "Living and Lifestyle Account":
      dispatchCategory = "7 Days Float"
      break
    case "Credit Card":
      dispatchCategory = "Paid by Credit"
      break
    case "Direct Payments Jar":
      dispatchCategory = "Direct from Primary"
      break
    case "Provisions Roll Over":
      dispatchCategory = "Provisioning"
      break
    default:
      dispatchCategory = ""
  }

  const dispatchHandler = type => {
    if (type === "ADD_ADDITIONAL_BILL") {
      summaryDispatch({
        type: "ADD_ADDITIONAL_BILL",
        payload: {
          id: "",
          category: dispatchCategory,
        },
      })
    } else if (type === "ADD_ADDITIONAL_EXPENSE") {
      summaryDispatch({
        type: "ADD_ADDITIONAL_EXPENSE",
        payload: {
          id: "",
          category: dispatchCategory,
        },
      })
    }
  }

  return (
    <div className={style.modalContainer}>
      <div>
        <ExpensesModalInfoControl
          beforeClosing={beforeClosing}
          title={infoControl.title}
          description={infoControl.description}
          setIndexHandler={setIndexHandler}
          isLastControl={hidePrimaryModal}
          firstStepModal={firstStepModal}
          getRollOverData={getRollOverData}
          tempExpenses={tempExpenses}
          setTempExpenses={setTempExpenses}
          startDate={startDate}
        />
      </div>
      <div>
        {!hidePrimaryModal && (
          <div className={style.rolloverModalA}>
            <h2>
              {category} -<small>{description}</small>
            </h2>
            <table>
              <tbody>
                <tr>
                  <th></th>
                  <th>Essential</th>
                  <th>Discretionary</th>
                  <th>Amount</th>
                  <th>Frequency</th>
                  <th>Monthly</th>
                  <th>Money S.M.A.R.T.S.</th>
                  <th></th>
                </tr>
                {getExpensesByCategory()
                  .map(expense => ({
                    ...expense,
                    amount: expense.frequency
                      ? expense.amount *
                        getMonthlyFrequencyMultiplier(expense.frequency)
                      : expense.amount,
                  }))
                  .map((expense, i) => {
                    return (
                      <RenderRolloverRow
                        key={`rollover-form-row-${i}`}
                        expenseItem={expense}
                        index={i}
                        setTempExpenses={setTempExpenses}
                        tempExpenses={tempExpenses}
                      />
                    )
                  })}
              </tbody>
            </table>
            <div className={style.addBillsBtnWrapper}>
              {dispatchCategory !== "" ? (
                <>
                  <div
                    onClick={() => dispatchHandler("ADD_ADDITIONAL_BILL")}
                    className={style.addExpenseButton}
                  >
                    <p>Add Additional Bill</p>
                    <img
                      alt="circle plus button"
                      className={style.plusBtnAdd}
                      src={PlusBtn}
                    />
                  </div>
                  <div
                    onClick={() => dispatchHandler("ADD_ADDITIONAL_EXPENSE")}
                    className={style.addExpenseButton}
                  >
                    <p>Add Additional Expense Item</p>
                    <img
                      alt="circle plus button"
                      className={style.plusBtnAdd}
                      src={PlusBtn}
                    />
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <br />
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpensesModal
