import React, { useContext, useState } from "react"
import clsx from "clsx"
import { formatCurrency } from "../../lib/utils"
import AppDataContext from "../../context/AppDataContext"
import styles from "./Snapshot.module.scss"
import MonthlyInTable from "../Primary-Account-Section/MonthlyInTable"
import CircleButton from "../Circle-Button/CircleButton"
import MoneyOutExpenses from "../Primary-Account-Section/MoneyOutExpenses"
import useMeasure from "react-use-measure"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"

const SnapshotCards: React.FC<{
  type: string
  label: string
  moneyIn: number
  moneyOut: number
  isEditable: boolean
}> = ({ type, label, moneyIn, moneyOut, isEditable }) => {
  const { summary, isDesktop, newDashboardValues } = useContext(AppDataContext)
  const [showPrimaryMoneyIn, setShowPrimaryMoneyIn] = useState(false)
  const [showPrimaryMoneyOut, setShowPrimaryMoneyOut] = useState(false)

  const moneyInHandler = type => {
    if (type === "monthlyIn") {
      setShowPrimaryMoneyIn(!showPrimaryMoneyIn)
    } else {
      setShowPrimaryMoneyOut(!showPrimaryMoneyOut)
    }
  }

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const height = showPrimaryMoneyIn ? prevHeight : 0
  let prevHeightSpring = useSpring({
    height: height,
    config: {
      duration: 400,
    },
  })

  return (
    <>
      <h2 className={styles.annualMonthly}>{label}</h2>
      <div className={styles.snapshotCardsWrapper}>
        <div
          className={clsx(
            styles.snapshotCardsContainer,
            styles.durationCardIn,
            !isEditable && styles.greyedOut
          )}
        >
          <p className={styles.cardHeader}>Money In</p>
          <div className={styles.buttonWithAmount}>
            <p className={styles.moneyValue}>{formatCurrency(moneyIn)}</p>
            {type === "Monthly" ? (
              <div onClick={() => moneyInHandler("monthlyIn")}>
                <CircleButton
                  type="upwards"
                  greyedOut={!isEditable}
                  rotate={showPrimaryMoneyIn}
                />
              </div>
            ) : null}
          </div>
        </div>
        {type === "Monthly" ? (
          <div className={styles.primaryIn}>
            <a.div
              style={prevHeightSpring}
              className={clsx(styles.tableWrapperDiv)}
            >
              <div ref={mainRef}>
                <MonthlyInTable
                  summary={summary}
                  moneyInOnly={showPrimaryMoneyIn}
                  wide={false}
                  isDesktop={isDesktop}
                  newDashboardValues={newDashboardValues}
                />
              </div>
            </a.div>
          </div>
        ) : null}
        <div
          className={clsx(
            styles.snapshotCardsContainer,
            styles.durationCardOut,
            !isEditable && styles.greyedOut
          )}
        >
          <p className={styles.cardHeader}>Money Out</p>
          <div className={styles.buttonWithAmount}>
            <p className={styles.moneyValue}>{formatCurrency(moneyOut)}</p>
            {type === "Monthly" ? (
              <div onClick={() => moneyInHandler("monthlyOut")}>
                <CircleButton
                  type="upwards"
                  greyedOut={!isEditable}
                  rotate={showPrimaryMoneyOut}
                />
              </div>
            ) : null}
          </div>
        </div>
        {type === "Monthly" ? (
          <div className={styles.primaryOut}>
            <div>
              <MoneyOutExpenses moneyOutOnly={showPrimaryMoneyOut} />
            </div>
          </div>
        ) : null}
        <div
          className={clsx(
            styles.snapshotCardsContainer,
            styles.surplusCard,
            !isEditable && styles.greyedOut
          )}
        >
          <p className={styles.cardHeader}>{`Targeted ${type} Surplus`}</p>
          <div className={styles.annualSurplusDiv}>
            <p className={styles.moneyValue}>
              {formatCurrency(moneyIn - moneyOut)}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SnapshotCards
