import React from "react"
import ArrowUp from "../../images/icons/arrow-up-solid.svg"
import styles from "./CircleButton.module.scss"
import clsx from "clsx"

const CircleButton: React.FC<{
  rotate: boolean
  greyedOut?: boolean | undefined
  onClick?: () => void
  type: string
}> = ({ rotate, greyedOut, onClick, type }) => {
  return (
    <button
      className={clsx(styles.circleButton, greyedOut && styles.greyedOut)}
      onClick={onClick && onClick}
      data-cy="btn-rotate-circle"
    >
      <img
        alt="circle-button-arrow"
        src={ArrowUp}
        className={clsx(
          type === "downwards" ? styles.arrowIconDown : styles.arrowIcon,
          rotate && styles.rotate
        )}
      />
    </button>
  )
}

export default CircleButton
