import React, { useContext } from "react"
import { ClientSummarySchema } from "../../schemas/clientSummaryResponseSchema"
import AppDataContext from "../../context/AppDataContext"
import clsx from "clsx"
import styles from "./PrimaryAccount.module.scss"
import { incomeArray } from "./PrimaryAccountHelpers"
import { formatCurrency } from "../../lib/utils"

interface MonthlyInTableProps {
  summary: ClientSummarySchema
  moneyInOnly: boolean
  wide: boolean
  isDesktop: boolean
  newDashboardValues: any
}

export const MonthlyInTable: React.FC<MonthlyInTableProps> = ({
  summary,
  wide,
  newDashboardValues,
}) => {
  const { userHasSecondary } = useContext(AppDataContext)

  const combinedIncome: Array<any> = incomeArray(summary, newDashboardValues)

  return (
    <table className={clsx(styles.incomeTable, wide && styles.wideTable)}>
      <thead>
        <tr className={clsx(styles.tableRow)}>
          <td colSpan={3} className={styles.tableLabel}>
            Monthly In
          </td>
        </tr>
        <tr className={clsx(styles.tableRow, wide && styles.wideTableGrid)}>
          <th></th>
          <th className={styles.clientName}>
            {summary.personalInfo.client1.fName}
          </th>
          {summary.personalInfo.client2 ? (
            <th className={styles.clientName}>
              {summary.personalInfo.client2.fName}
            </th>
          ) : null}
        </tr>
        {combinedIncome &&
          combinedIncome
            .filter(income => {
              return income.primary !== "$0.00" || income.secondary !== "$0.00"
            })
            .map((income, index) => {
              let makeBold =
                income.incomeType === "Total Gross Income" ||
                income.incomeType === "Total Net Income"
                  ? true
                  : false
              let incomePrimary
              let incomeSecondary

              switch (income.incomeType) {
                case "Superannuation Salary Sacrifice":
                  incomePrimary = `( ${income.primary} )`
                  incomeSecondary = `( ${income.secondary} )`
                  break
                case "Investment Property Deductions":
                  incomePrimary = `( ${income.primary} )`
                  incomeSecondary = `( ${income.secondary} )`
                  break
                case "Other Tax Deductions":
                  incomePrimary = `( ${income.primary} )`
                  incomeSecondary = `( ${income.secondary} )`
                  break
                case "Estimated Tax Paid":
                  incomePrimary = `( ${formatCurrency(
                    newDashboardValues.estimatedTaxPaidClient1 / 12
                  )} )`
                  incomeSecondary = `( ${formatCurrency(
                    newDashboardValues.estimatedTaxPaidClient2 / 12
                  )} )`
                  break
                case "Pre-Tax Deductions":
                  incomePrimary = `( ${income.primary} )`
                  incomeSecondary = `( ${income.secondary} )`
                  break
                case "Total Net Income":
                  incomePrimary = formatCurrency(
                    newDashboardValues.totalNetIncomeClient1 / 12
                  )
                  incomeSecondary = formatCurrency(
                    newDashboardValues.totalNetIncomeClient2 / 12
                  )
                  break
                default:
                  incomePrimary = income.primary
                  incomeSecondary = income.secondary
              }

              return (
                <tr
                  className={clsx(
                    styles.tableRow,
                    wide && styles.wideTableGrid
                  )}
                  key={`income-table-${index}`}
                >
                  <td
                    className={clsx(
                      styles.incomeRow,
                      makeBold && styles.incomeBold
                    )}
                  >
                    {income.incomeType}
                  </td>
                  <td className={clsx(makeBold && styles.incomeBold)}>
                    {incomePrimary}
                  </td>
                  {userHasSecondary ? (
                    <td className={clsx(makeBold && styles.incomeBold)}>
                      {incomeSecondary}
                    </td>
                  ) : null}
                </tr>
              )
            })}
      </thead>
      <tbody></tbody>
    </table>
  )
}

export default MonthlyInTable
