import { createContext } from "react"

type ContextProps = {
  setShowMessage: Function
  showMessage: boolean
}

const MoneyStretchContext = createContext<Partial<ContextProps>>({})

export default MoneyStretchContext
