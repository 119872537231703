import { createContext } from "react"

type ProvisionProps = {
  provisionDispatch: React.Dispatch<any>
  reducerProvisions: any
  startDate: any
  monthlyBalanceItemsWithinInterval: Array<any>
  isNotWithinInterval: Array<any>
}

const ProvisionReducer = createContext<Partial<ProvisionProps>>({})

export default ProvisionReducer
