import React, { useEffect, useState, useReducer, useContext } from "react"
import uniqueString from "unique-string"
import _ from "lodash"
import * as dateFns from "date-fns"
import moment from "moment"

import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema"
import {
  MonthlyBalanceItemSchema,
  MonthlyBalanceResponseSchema,
} from "../schemas/monthlyBalanceResponseSchema"
import { ProvisioningResponseSchema } from "../schemas/provisioningResponseSchema"
import {
  decodeMonthlyBalanceStartDate,
  decodeMonthlyBalanceItemDate,
  encodeMonthlyBalanceStartDate,
  DraftMonthlyBalanceItem,
  converStartData,
} from "../lib/utils"
import { draftMonthlyBalanceItemsReducer } from "../lib/reducers"
import { editProvisionReducer } from "../lib/reducers"
import {
  calculateMonthlyProvisions,
  calculateMonthlyCashPosition,
  calculateMonthlyProvisioningsSpentTable,
} from "../lib/calculator"

// Components
import MonthlyCheckupSection from "./MonthlyCheckupSection"
import CheckupReportingTableSection from "./CheckupReportingTableSection"
import TrackProvisionSpendingSection from "./TrackProvisionSpendingSection"
import TrackRegularSpendingSection from "./TrackRegularSpendingSection"
import ProvisionSectionWrapper from "../components/ProvisionSectionWrapper"
import ProvisionReducerContext from "../context/ProvisionsReducer"
import RolloverSplash from "../components/Rollover/RolloverSplash"
import AppDataContext from "../context/AppDataContext"

const BudgetIntervalContainer: React.FC<Props> = ({
  summary,
  monthlyBalance,
  provisioning,
  onDraftMonthlyBalanceSubmit,
  isEditable,
  rolloverSwitch,
  getRollOverData,
}) => {
  const hasAssets = _.has(summary, "assets")
  const propertyAssets = hasAssets ? summary.assets.properties : []
  const monthlyBalanceItems =
    (monthlyBalance.monthlyBalance[0] || {}).items || []
  const [
    draftMonthlyBalanceStartDate,
    setDraftMonthlyBalanceStartDate,
  ] = useState(decodeMonthlyBalanceStartDate(monthlyBalance.startData))

  const [
    draftMonthlyBalanceItems,
    draftMonthlyBalanceItemsDispatch,
  ] = useReducer(
    draftMonthlyBalanceItemsReducer,
    monthlyBalanceItems.map(monthlyBalanceItemToDraftMonthlyBalanceItem)
  )

  const { newDashboardValues, setAccumulatedSurplus } = useContext(
    AppDataContext
  )

  const targetSurplus =
    newDashboardValues.totalNetIncome / 12 -
    newDashboardValues.totalExpenditure / 12

  const submitUpdatedStartDate = () => {
    const newMonthlyBalance: MonthlyBalanceResponseSchema = {
      startData: encodeMonthlyBalanceStartDate(draftMonthlyBalanceStartDate),
      monthlyBalance: [
        {
          items: draftMonthlyBalanceItems.map(
            draftMonthlyBalanceItemToMonthlyBalanceItem
          ),
        },
      ],
    }
    onDraftMonthlyBalanceSubmit(newMonthlyBalance)
  }

  const onChangeDateHandler = newStartDate => {
    setDraftMonthlyBalanceStartDate(newStartDate)
    draftMonthlyBalanceItemsDispatch({
      type: "CREATE_ITEMS",
      monthlyBalanceStartDate: newStartDate,
    })
  }

  useEffect(() => {
    setDraftMonthlyBalanceStartDate(
      decodeMonthlyBalanceStartDate(monthlyBalance.startData)
    )
    draftMonthlyBalanceItemsDispatch({
      type: "INITIALISE_ITEMS",
      initialState: monthlyBalanceItems.map(
        monthlyBalanceItemToDraftMonthlyBalanceItem
      ),
    })
  }, [monthlyBalance])

  const budgetInterval = createYearIntervalFromStartDate(
    draftMonthlyBalanceStartDate
  )

  const sortedMonthlyBalanceItems = draftMonthlyBalanceItems.sort((a, b) =>
    dateFns.compareAsc(
      decodeMonthlyBalanceItemDate(a),
      decodeMonthlyBalanceItemDate(b)
    )
  )
  let monthlyBalanceItemsWithinInterval = sortedMonthlyBalanceItems.filter(
    monthlyBalanceItem => {
      const date = decodeMonthlyBalanceItemDate(monthlyBalanceItem)
      return isMonthEqualOrWithinInterval(date, budgetInterval)
    }
  )

  const [monthCheckDate, setMonthCheckDate] = useState<string>()

  useEffect(() => {
    const sdate = moment(draftMonthlyBalanceStartDate).format("YYYY-MM-DD")
    setMonthCheckDate(sdate)
  }, [draftMonthlyBalanceStartDate])

  let isNotWithinInterval = sortedMonthlyBalanceItems.filter(
    monthlyBalanceItem => {
      const date = decodeMonthlyBalanceItemDate(monthlyBalanceItem)
      return !isMonthEqualOrWithinInterval(date, budgetInterval)
    }
  )

  const firstEntryNextRollover =
    monthlyBalanceItemsWithinInterval[
      monthlyBalanceItemsWithinInterval.length - 1
    ]

  isNotWithinInterval.unshift(firstEntryNextRollover)

  if (monthlyBalanceItemsWithinInterval.length <= 0) {
    const date = new Date(draftMonthlyBalanceStartDate)
    let month = converStartData(String(date.getMonth()))
    let year = String(date.getFullYear())
    monthlyBalanceItemsWithinInterval = [
      { amount: 0, creditCardBalance: 0, day: "1", year, month },
    ]
  }

  const [reducerProvisions, provisionDispatch] = useReducer(
    editProvisionReducer,
    provisioning
  )

  // Use functions for change checks so we can take advantage of short-circuit
  // evaluation as an optimisation
  const hasMonthlyBalanceStartDateChanged = () =>
    !_.isEqual(
      decodeMonthlyBalanceStartDate(monthlyBalance.startData),
      draftMonthlyBalanceStartDate
    )
  const haveMonthlyBalanceItemsChanged = () =>
    !_.isEqual(
      monthlyBalanceItems,
      draftMonthlyBalanceItems.map(draftMonthlyBalanceItemToMonthlyBalanceItem)
    )

  const targetedMonthlySurplus = monthlyBalanceItemsWithinInterval.map(
    (_, i) => targetSurplus
  )

  const rollingTargetedSurplus = targetedMonthlySurplus.map((target, i) => {
    return target * i
  })

  const primaryAccountAmount = monthlyBalanceItemsWithinInterval.map(
    monthlyBalanceItem => monthlyBalanceItem.amount
  )

  const provisioningSpent = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalanceItem, index) => {
      return calculateMonthlyProvisioningsSpentTable(
        draftMonthlyBalanceStartDate,
        monthlyBalanceItem,
        reducerProvisions.provisionItems,
        index
      )
    }
  )

  const yearlyProvisionRef = [...provisioningSpent]
  yearlyProvisionRef.unshift(0)
  yearlyProvisionRef.pop()

  const monthlyAllocated = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalanceItems, i) =>
      calculateMonthlyProvisions(
        summary.expenses.expenses,
        propertyAssets,
        i,
        true
      )
  )

  let computeYearly = []
  const yearlyProvision = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalance, i) => {
      let computeMonth
      if (i === 0) {
        computeMonth = monthlyAllocated[i] - yearlyProvisionRef[i]
        computeYearly.push(computeMonth)
      } else {
        computeMonth =
          monthlyAllocated[i] - yearlyProvisionRef[i] + computeYearly[i - 1]
        computeYearly.push(computeMonth)
      }
      return computeMonth
    }
  )

  const cashPosition = monthlyBalanceItemsWithinInterval.map(
    monthlyBalanceItem => {
      return calculateMonthlyCashPosition(monthlyBalanceItem)
    }
  )

  const accumulatedSurplus = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalance, i) => {
      const cashPositionAbs = cashPosition[0]
      let accumulated
      if (i === 0) {
        accumulated = 0
      } else {
        accumulated = cashPosition[i] - yearlyProvision[i] - cashPositionAbs
      }
      return accumulated
    }
  )

  const monthlyActualSurplus = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalance, i) => {
      let actualSurplus
      if (i === 0) {
        actualSurplus = 0
      } else if (i === 1) {
        actualSurplus = accumulatedSurplus[i]
      } else {
        actualSurplus = accumulatedSurplus[i] - accumulatedSurplus[i - 1]
      }
      return actualSurplus
    }
  )

  const monthlyExcessSurplus = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalance, i) => {
      let monthlyExcess
      if (i === 0) {
        monthlyExcess = 0
      } else {
        monthlyExcess = monthlyActualSurplus[i] - targetedMonthlySurplus[i]
      }
      return monthlyExcess
    }
  )

  let tempAccumulated = []
  const accumulatedExcessSurplus = monthlyBalanceItemsWithinInterval.map(
    (monthlyBalance, i) => {
      let accumulatedExcess
      if (i === 0) {
        accumulatedExcess = 0
        tempAccumulated.push(accumulatedExcess)
      } else if (i === 1) {
        accumulatedExcess = monthlyExcessSurplus[i]
        tempAccumulated.push(accumulatedExcess)
      } else {
        accumulatedExcess = tempAccumulated[i - 1] + monthlyExcessSurplus[i]
        tempAccumulated.push(accumulatedExcess)
      }
      return accumulatedExcess
    }
  )

  setAccumulatedSurplus(accumulatedSurplus[accumulatedSurplus.length - 1])

  return (
    <>
      <ProvisionReducerContext.Provider
        value={{
          reducerProvisions,
          provisionDispatch,
          startDate: draftMonthlyBalanceStartDate,
          monthlyBalanceItemsWithinInterval,
          isNotWithinInterval,
        }}
      >
        <ProvisionSectionWrapper
          wrapperProvision={provisioning}
          monthCheckDate={monthCheckDate}
        />
        <RolloverSplash getRollOverData={getRollOverData} />
        <MonthlyCheckupSection
          allMonthlyBalanceItems={sortedMonthlyBalanceItems}
          monthlyBalanceStartDate={draftMonthlyBalanceStartDate}
          monthlyBalanceItems={monthlyBalanceItemsWithinInterval}
          onMonthlyBalanceStartDateChange={newStartDate =>
            onChangeDateHandler(newStartDate)
          }
          isEditable={isEditable}
          onMonthlyBalanceItemsCreate={() =>
            draftMonthlyBalanceItemsDispatch({
              type: "CREATE_ITEM",
              monthlyBalanceStartDate: draftMonthlyBalanceStartDate,
            })
          }
          onMonthlyBalanceItemsUpdate={monthlyBalanceItem =>
            draftMonthlyBalanceItemsDispatch({
              type: "UPDATE_ITEM",
              newItem: monthlyBalanceItem,
            })
          }
          onMonthlyBalanceItemsDelete={id =>
            draftMonthlyBalanceItemsDispatch({ type: "DELETE_ITEM", id })
          }
          deleteAllMonthlyBalanceItems={() =>
            draftMonthlyBalanceItemsDispatch({ type: "DELETE_ALL_ITEMS" })
          }
          hasNext={hasNextPage(sortedMonthlyBalanceItems, budgetInterval)}
          onSubmit={submitUpdatedStartDate}
          hasUnsavedChanges={
            hasMonthlyBalanceStartDateChanged() ||
            haveMonthlyBalanceItemsChanged()
          }
          rolloverSwitch={rolloverSwitch}
          {...getPagination(sortedMonthlyBalanceItems, budgetInterval)}
        />
        <hr />
        <CheckupReportingTableSection
          monthlyBalanceItems={monthlyBalanceItemsWithinInterval}
          isEditable={isEditable}
          targetedMonthlySurplus={targetedMonthlySurplus}
          primaryAccountAmount={primaryAccountAmount}
          provisioningSpent={yearlyProvisionRef}
          monthlyAllocated={monthlyAllocated}
          yearlyProvision={yearlyProvision}
          cashPosition={cashPosition}
          accumulatedSurplus={accumulatedSurplus}
          monthlyActualSurplus={monthlyActualSurplus}
          monthlyExcessSurplus={monthlyExcessSurplus}
          accumulatedExcessSurplus={accumulatedExcessSurplus}
          rollingTargetedSurplus={rollingTargetedSurplus}
        />
        <hr />
        <TrackProvisionSpendingSection
          summary={summary}
          monthlyBalanceItems={monthlyBalanceItemsWithinInterval}
          provisioningSpent={provisioningSpent}
          yearlyProvisionRef={yearlyProvisionRef}
        />
        <hr />
        <TrackRegularSpendingSection
          summary={summary}
          monthlyBalanceItems={monthlyBalanceItemsWithinInterval}
          targetedMonthlySurplus={targetedMonthlySurplus}
          primaryAccountAmount={primaryAccountAmount}
          accumulatedExcessSurplus={accumulatedExcessSurplus}
          monthlyActualSurplus={monthlyActualSurplus}
          monthlyExcessSurplus={monthlyExcessSurplus}
          accumulatedSurplus={accumulatedSurplus}
          rollingTargetedSurplus={rollingTargetedSurplus}
        />
      </ProvisionReducerContext.Provider>
    </>
  )
}

interface Props {
  summary: ClientSummarySchema
  monthlyBalance: MonthlyBalanceResponseSchema
  provisioning: ProvisioningResponseSchema
  onDraftMonthlyBalanceSubmit: (
    newMonthlyBalance: MonthlyBalanceResponseSchema
  ) => void
  onDraftProvisioningSubmit: (
    newProvisioning: ProvisioningResponseSchema
  ) => void
  isEditable: boolean
  rolloverSwitch: () => React.ReactNode
  getRollOverData: () => void
}

// ============================================================================
// Helpers
// ============================================================================

// Getters allow us to easily derive one of the values, as seen with
// createYearIntervalFromStartDate
interface Interval {
  getStart: () => Date
  getEnd: () => Date
}

const createYearIntervalFromStartDate = (startDate: Date): Interval => ({
  getStart: () => startDate,
  getEnd: () => dateFns.addYears(startDate, 1),
})

const isMonthEqualOrWithinInterval = (date: Date, interval: Interval) =>
  dateFns.isSameMonth(date, interval.getStart()) ||
  dateFns.isSameMonth(date, interval.getEnd()) ||
  dateFns.isWithinInterval(date, {
    start: interval.getStart(),
    end: interval.getEnd(),
  })

const getNumberOfPages = (date1, date2) => {
  const numberOfItemsPerPage = 13
  const diffInMonths = dateFns.differenceInCalendarMonths(date1, date2)
  return Math.ceil(diffInMonths / numberOfItemsPerPage)
}

const getPagination = (
  monthlyBalanceItems: MonthlyBalanceItemSchema[],
  budgetInterval: Interval
) => {
  if (monthlyBalanceItems.length > 0) {
    const firstDate = decodeMonthlyBalanceItemDate(monthlyBalanceItems[0])
    const lastDate = decodeMonthlyBalanceItemDate(
      monthlyBalanceItems[monthlyBalanceItems.length - 1]
    )

    if (
      dateFns.isBefore(budgetInterval.getStart(), firstDate) &&
      dateFns.isAfter(budgetInterval.getEnd(), lastDate)
    ) {
      return { numberOfPages: 1, pageNumber: 1 }
    }

    const numberOfPagesBefore = getNumberOfPages(
      budgetInterval.getStart(),
      firstDate
    )
    const numberOfPagesAfter = getNumberOfPages(
      lastDate,
      budgetInterval.getEnd()
    )

    return {
      numberOfPages: numberOfPagesBefore + numberOfPagesAfter + 1,
      pageNumber: numberOfPagesBefore + 1,
    }
  }
  return { numberOfPages: 0, pageNumber: 0 }
}

const hasNextPage = (
  monthlyBalanceItems: MonthlyBalanceItemSchema[],
  budgetInterval: Interval
) => {
  const { numberOfPages, pageNumber } = getPagination(
    monthlyBalanceItems,
    budgetInterval
  )
  return pageNumber < numberOfPages
}

const monthlyBalanceItemToDraftMonthlyBalanceItem = (
  monthlyBalanceItem: MonthlyBalanceItemSchema
): DraftMonthlyBalanceItem => {
  return { ...monthlyBalanceItem, id: uniqueString() }
}

const draftMonthlyBalanceItemToMonthlyBalanceItem = (
  draftMonthlyBalanceItem: DraftMonthlyBalanceItem
): MonthlyBalanceItemSchema => {
  const copy = { ...draftMonthlyBalanceItem }
  delete copy.id
  return copy
}

export default BudgetIntervalContainer
