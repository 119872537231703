import React, { useState, useContext, useEffect } from "react"
import styles from "./ProvisionSectionJar.module.scss"
import AppDataContext from "../../context/AppDataContext"
import {
  getProvisionsExpenses,
  getYearlyFrequencyMultiplier,
} from "../../lib/utils"
import Portal from "../../Portals/Portal"
import * as dateFns from "date-fns"
import { formatCurrency } from "../../lib/utils"
import { calculateYearlyProvisions } from "../../lib/calculator"
import moment from "moment"
import editIcon from "../../images/icons/pen-solid.svg"
import ProvisionModal from "./ProvisionModal"
import ProvisionReducer from "../../context/ProvisionsReducer"
import clsx from "clsx"
import fileIcon from "../../images/icons/file-alt-solid.svg"
import SectionHeader from "../Section-Header/SectionHeader"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import _ from "lodash"

const ProvisionsSectionJar = ({ jarProvision, monthCheckDate }) => {
  const [showModal, setShowModal] = useState(false)

  const { summary, portalDiv, isEditable } = useContext(AppDataContext)
  const { reducerProvisions, provisionDispatch, startDate } = useContext(
    ProvisionReducer
  )
  const [selectedId, setSelectedId] = useState("")
  const [addTableHeight, setAddTableHeight] = useState(false)
  const [showExpenditureTable, setShowExpenditureTable] = useState(false)

  const hasAssets = _.has(summary, "assets")
  const propertyAssets = hasAssets ? summary.assets.properties : []

  useEffect(() => {
    provisionDispatch({
      type: "INITIALISE_PROVISION",
      payload: {
        newProvision: jarProvision,
      },
    })
  }, [jarProvision])

  const showModalHandler = id => {
    setSelectedId(id)
    setShowModal(!showModal)
  }

  //logic in displaying Monthly Check-up
  const monthlyEndDate = months => {
    const sdate = monthCheckDate
      ? monthCheckDate
      : moment().format("YYYY-MM-DD")

    return moment(sdate)
      .add(months, "month")
      .format("YYYY-MM-DD")
  }

  const allprovisionexpense = getProvisionsExpenses(
    summary.expenses.expenses,
    propertyAssets
  )
  const totalSpentProvisions = reducerProvisions.provisionItems
    .flatMap(provisionItem => {
      return provisionItem.items.map(property => ({
        ...property,
        category: provisionItem.category,
        date: new Date(property.date),
        categoryId: provisionItem.categoryId,
      }))
    })
    .sort((a, b) => dateFns.compareAsc(a.date, b.date))
    .filter(item => {
      let d1 = item.date
      let d2 = monthlyEndDate(13)
      let sdate = moment(monthCheckDate).format("YYYY-MM-DD")
      return moment(d1).isSameOrAfter(sdate) && moment(d1).isSameOrBefore(d2)
    })
    .reduce((prev, curr) => {
      return Number(prev) + Number(curr.amount)
    }, 0)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const yearlyProvisions = calculateYearlyProvisions(
    summary.expenses.expenses,
    propertyAssets
  )
  const balanceLeft = Number(yearlyProvisions - totalSpentProvisions)

  let totalBarWidth = (totalSpentProvisions / yearlyProvisions) * 100
  if (totalBarWidth >= 100) {
    totalBarWidth = 100
  }

  const totalProvision = allprovisionexpense
    .filter(expenseItem => {
      return expenseItem.frequency !== null && _.has(expenseItem, "frequency")
    })
    .map(provisionExpense => {
      let amount = provisionExpense.basic ? provisionExpense.basic : 0

      let discretionary = provisionExpense.discretionary
        ? provisionExpense.discretionary
        : 0
      let monthlyTotal =
        (discretionary + amount) *
        getYearlyFrequencyMultiplier(provisionExpense.frequency)
      return monthlyTotal
    })
    .reduce((prev, curr) => {
      return Number(prev) + Number(curr)
    }, 0)

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const prevHeightSpring = useSpring({
    height: addTableHeight ? prevHeight : 0,
    overflow: "hidden",
    config: {
      duration: 300,
    },
  })

  const [tableRef, { height: tableHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const showTableHandler = () => {
    setShowExpenditureTable(!showExpenditureTable)
  }

  const expenditureTableHeight = useSpring({
    height: showExpenditureTable ? tableHeight + 30 : 0,
    overflow: "hidden",
    config: {
      duration: 300,
    },
  })

  return (
    <section className={styles.provisionSectionContainer}>
      <SectionHeader
        title="Provisions Jar"
        rotate={addTableHeight}
        onClickHandler={setTableHeightHanlder}
        showSubheader={true}
        centerSubHeaderTitle={null}
        centerSubHeaderAmount={null}
        monthlyOutAmount={null}
        monthlyInAmount={null}
        textTitle={null}
        greyedOut={!isEditable}
      />
      <a.div style={prevHeightSpring}>
        <div className={styles.categoryList} ref={mainRef}>
          {allprovisionexpense
            .filter(expenseItem => {
              return (
                expenseItem.frequency !== null &&
                _.has(expenseItem, "frequency")
              )
            })
            .map((expense, index) => {
              let amount = expense?.basic ? expense.basic : 0
              let discretionary = expense.discretionary
                ? expense.discretionary
                : 0
              let monthlyTotal =
                (Number(discretionary) + Number(amount)) *
                getYearlyFrequencyMultiplier(expense.frequency)

              const lookUpProvision = reducerProvisions.provisionItems.find(
                provision => {
                  return provision.categoryId === expense._id
                }
              )

              monthlyTotal = _.isNaN(monthlyTotal) ? 0 : monthlyTotal

              const totalAmountProvisions =
                lookUpProvision &&
                lookUpProvision.items.reduce((prev, curr) => {
                  return Number(prev) + Number(curr.amount)
                }, 0)
              let fillerBarWidth =
                totalAmountProvisions === 0
                  ? 0
                  : (totalAmountProvisions / monthlyTotal) * 100
              let backgroundColor

              if (fillerBarWidth > 100) {
                fillerBarWidth = 100
              }

              if (fillerBarWidth >= 100) {
                backgroundColor = "#FA2421"
              } else {
                backgroundColor = "#ffc8ca"
              }

              const amountLeft = monthlyTotal - totalAmountProvisions

              return (
                <div className={styles.provisionSectionWrapper}>
                  <div
                    key={`epxense-desc-${index}`}
                    className={styles.categoryList}
                  >
                    <p>{expense.desc}</p>
                  </div>
                  <div
                    key={`expense-monthly-${index}`}
                    className={styles.categoryList}
                  >
                    <p>{`${formatCurrency(monthlyTotal)}`}</p>
                  </div>
                  <div key={`expense-bar-${index}`}>
                    <div className={styles.barWrapper}>
                      <div className={styles.textBarWrapper}>
                        <p
                          className={clsx(styles.textUpBar, styles.first)}
                        >{`${formatCurrency(
                          totalAmountProvisions
                        )} of ${formatCurrency(monthlyTotal)} Spent`}</p>
                        <p
                          className={clsx(styles.textUpBar, styles.second)}
                        >{`${formatCurrency(amountLeft)} Left`}</p>
                      </div>
                      <div className={styles.amountBar}>
                        <div
                          style={{
                            width: `${fillerBarWidth}%`,
                            background: backgroundColor,
                          }}
                          className={styles.fillerBar}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.categoryList}
                    key={`edit-icon-${index}`}
                  >
                    <img
                      onClick={() => showModalHandler(expense._id)}
                      className={styles.penEditorImg}
                      src={editIcon}
                      alt="edit icon"
                    />
                  </div>
                </div>
              )
            })}
          <div
            className={clsx(
              styles.provisionSectionWrapper,
              styles.totalProvisionSection
            )}
          >
            <div className={styles.categoryList}>
              <p>Total Provision Balance</p>
            </div>
            <div className={styles.categoryList}>
              <p>{`${formatCurrency(totalProvision)}`}</p>
            </div>
            <div>
              <div className={clsx(styles.barWrapper)}>
                <div className={clsx(styles.amountBar, styles.totalBar)}>
                  <span
                    className={styles.spentExpenseTotal}
                  >{`Spent ${formatCurrency(totalSpentProvisions)}`}</span>
                  <span
                    className={clsx(
                      styles.spentExpenseTotal,
                      styles.remainingLeft
                    )}
                  >{`${formatCurrency(balanceLeft)} Remaining`}</span>
                  <div
                    style={{ width: `${totalBarWidth}%` }}
                    className={clsx(styles.fillerBar, styles.totalFillerBar)}
                  ></div>
                </div>
              </div>
            </div>
            <div className={styles.categoryList}>
              <img
                onClick={showTableHandler}
                className={styles.penEditorImg}
                src={fileIcon}
                alt="edit icon"
              />
            </div>
          </div>
          <a.div style={expenditureTableHeight}>
            <div ref={tableRef}>
              <h2 className={styles.expenditureTitle}>Expenditures</h2>
              <div className={styles.provisionListWrapper}>
                <table>
                  <thead>
                    <tr className={styles.tableRow}>
                      <th>Description</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reducerProvisions.provisionItems
                      .flatMap(provisionItem => {
                        return provisionItem.items.map(property => ({
                          ...property,
                          category: provisionItem.category,
                          date: new Date(property.date),
                        }))
                      })
                      .sort((a, b) => dateFns.compareAsc(a.date, b.date))
                      .filter(item => {
                        let d1 = item.date
                        let d2 = monthlyEndDate(13)
                        let sdate = moment(monthCheckDate).format("YYYY-MM-DD")

                        return (
                          moment(d1).isSameOrAfter(sdate) &&
                          moment(d1).isSameOrBefore(d2)
                        )
                      })
                      .map((property, i) => {
                        return (
                          <tr
                            className={styles.tableRow}
                            key={`provision-expense-${i}`}
                          >
                            <td>{property.name}</td>
                            <td>{formatCurrency(property.amount)}</td>
                            <td>
                              {moment(property.date).format("DD-MM-YYYY")}
                            </td>
                            <td>{property.category}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </a.div>
          {showModal ? (
            <Portal portalRoot={portalDiv}>
              <ProvisionModal
                reducerProvisions={reducerProvisions}
                showModalHandler={showModalHandler}
                expenseId={selectedId}
                isEditable={isEditable}
                startDate={startDate}
              />
            </Portal>
          ) : null}
        </div>
      </a.div>
    </section>
  )
}

export default ProvisionsSectionJar
