import React from "react"
import { FormProps } from "./FormStretch"
import { Typography, Grid } from "@material-ui/core"
import InputForm from "../Forms/Input/InputForm"
import styles from "./FormStretch.module.scss"

interface MoneyAvailableProps extends FormProps {
  currencyProps?: any
}

const MoneyAvailable: React.FC<MoneyAvailableProps> = ({
  form,
  handleFormChange,
  currencyProps,
  getTotalMoneyAvailable,
}) => {
  return (
    <>
      <Typography variant="h5" className={styles.title}>
        Money Available
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Total Cash In Bank </b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="cashInBank"
                value={form.cashInBank}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle2" className={styles.paragraph}>
            <b> Other Available Funds </b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="otherAvailableFunds"
                value={form.otherAvailableFunds}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1" className={styles.paragraph}>
            <b> Total Money Available </b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="otherAvailableFunds"
                value={getTotalMoneyAvailable()}
                disabled
                inputProps={currencyProps}
                inputClass={styles.inputDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(MoneyAvailable)
