import React from "react"
import {
  withStyles,
  StyleRules,
  WithStyles,
  Theme,
} from "@material-ui/core/styles"
import {
  Grid,
  Paper,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Card,
  CircularProgress,
} from "@material-ui/core"
import _ from "lodash"
import { Bars } from "./Bars"
import { Helpers } from "./Helpers"
import FamilyCard from "./FamilyCard"
import moment from "moment"
const TotalAnnualSurplusGraph: React.FC<Props> = ({
  classes,
  moneyFitPromise,
  clientSummary,
  countTotal,
  totalSurplus,
}) => {
  const [data, setData] = React.useState([])
  const [age, setAge] = React.useState("")
  const [ageBounds, setAgeBounds] = React.useState("All")
  const [location, setLocation] = React.useState()
  const [stateSelect, setStateSelect] = React.useState("All")
  const [total, setTotal] = React.useState(0)
  const [ageFilter, setAgeFilter] = React.useState([])
  const [stateFilter, setStateFilter] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [icon, setIcon] = React.useState("male")

  React.useEffect(() => {
    let filter = {
      filters: {} as any,
      measure: "surplusCash",
    }
    if (ageFilter.length > 0) {
      filter.filters.age1 = [ageFilter[0]]
      if (ageFilter.length > 1) {
        filter.filters.age1$ = [ageFilter[1]]
      }
    }
    if (stateFilter.length > 0) {
      filter.filters.state1 = stateFilter
    }

    filter.filters.surplusCash = [
      { operator: ">", isNumber: "1", value: "-1200" },
    ]
    filter.filters.surplusCash$ = [
      { operator: "<", isNumber: "1", value: "120000" },
    ]
    filter.filters.household = getHouseholdCompFilter(clientSummary)
    filter.filters.dependants = getDependantsFilter(clientSummary)

    setIsLoading(true)
    moneyFitPromise(JSON.stringify(filter)).then(res => {
      setTotal(countTotal(res))
      setData(res)
      setIsLoading(false)
    })
  }, [ageFilter, stateFilter])

  if (!age) {
    let clientDob = _.get(clientSummary, "personalInfo.client1.dob", "NA")
    let clientAge = "NA"
    if (clientDob === "NA" || _.isNil(clientDob)) {
      setAge("NA")
      setAgeBounds("All")
    } else {
      clientAge = moment()
        .diff(moment(clientDob), "years")
        .toString()
      setAge(clientAge)
      if (parseInt(clientAge) > 20) {
        setAgeBounds(Helpers.getAgeRange(clientAge))
      } else {
        setAgeBounds("All")
      }
    }
    let filter = getAgeFilter(Helpers.getAgeRange(clientAge))
    setAgeFilter(filter)
  }

  if (!location) {
    let clientState = _.get(
      clientSummary,
      "personalInfo.client1.address.state",
      "NA"
    )
    let ifNoClientState = clientState === null ? "NA" : clientState
    setLocation(ifNoClientState)
  }
  let personalInfo = _.get(clientSummary, "personalInfo", {})

  return (
    <Paper className={classes.paperContainer}>
      {isLoading && (
        <div className={classes.mainLoaderContainer}>
          <CircularProgress color="primary" size={50} />
        </div>
      )}

      <Grid container justify="center" alignItems="flex-start">
        <Grid item alignContent="flex-end">
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            className={classes.subHeader}
          >
            Your Household Annual Surplus Cash vs
          </Typography>
          <Typography
            variant="h5"
            align="left"
            gutterBottom
            className={classes.totalLabel}
          >
            Total Annual Surplus Cash:{" "}
            <u>{Helpers.toCurrencyTitle(totalSurplus)}</u>
          </Typography>
          <Grid container justify="center">
            <Grid item className={classes.spacing} alignContent="center">
              <Card className={classes.paper}>
                <FamilyCard personalInfo={personalInfo}></FamilyCard>
                <Typography variant="h5" align="center">
                  {Helpers.getAgeRange(age) + " (" + location + ")"}
                </Typography>
              </Card>
            </Grid>
            <Grid item alignItems="center" className={classes.spacing}>
              <Paper className={classes.paper} elevation={3}>
                <div>
                  <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    className={classes.heading}
                  >
                    How do you compare?
                  </Typography>
                  <Grid item md={12}>
                    <Grid>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          className={classes.select}
                          value={ageBounds}
                          inputProps={{
                            name: "status",
                            id: "select-status",
                          }}
                          onChange={e => {
                            setAgeBounds(String(e.target.value))
                            let filter = getAgeFilter(String(e.target.value))
                            setAgeFilter(filter)
                          }}
                        >
                          <MenuItem value={"All"}>All Ages</MenuItem>
                          <MenuItem value={"21-25"}>21-25</MenuItem>
                          <MenuItem value={"26-30"}>26-30</MenuItem>
                          <MenuItem value={"31-35"}>31-35</MenuItem>
                          <MenuItem value={"36-40"}>36-40</MenuItem>
                          <MenuItem value={"41-45"}>41-45</MenuItem>
                          <MenuItem value={"46-50"}>46-50</MenuItem>
                          <MenuItem value={"51-55"}>51-55</MenuItem>
                          <MenuItem value={"56-60"}>56-60</MenuItem>
                          <MenuItem value={"61-65"}>61-65</MenuItem>
                          <MenuItem value={"66-70"}>66-70</MenuItem>
                          <MenuItem value={"71-75"}>71-75</MenuItem>
                          <MenuItem value={"76-80"}>76-80</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <div>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Select
                            value={stateSelect}
                            className={classes.select}
                            inputProps={{
                              name: "state",
                              id: "select-state",
                            }}
                            onChange={e => {
                              setStateSelect(String(e.target.value))
                              let filter = getStateFilter(
                                String(e.target.value)
                              )
                              setStateFilter(filter)
                            }}
                          >
                            <MenuItem value={"All"}>Australia</MenuItem>
                            {defaultLocations.map(row => {
                              return (
                                <MenuItem value={row}>
                                  {Helpers.getDisplayString(row)}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignContent="flex-start">
          <div style={{ marginTop: 10 }}>
            <div className="smallScreen">
              <Bars
                dataset={data}
                text="Surplus Cash"
                total={total}
                xText="Households"
                yText="Surplus Cash"
                userRank={totalSurplus}
              />
            </div>
          </div>
          <Typography
            paragraph={true}
            align="center"
            variant="subtitle1"
            gutterBottom
          >
            * Based on a survey of {total} households.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
const styles = (theme: Theme) => {
  const style: StyleRules = {
    root: {
      display: "flex",
      flexWrap: "wrap",
      fontFamily: "Poppins",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(1) * 2,
    },
    select: {
      textAlign: "left",
      borderColor: "#ccc",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 4,
      padding: "5px 10px",
    },
    inputLabel: {
      fontSize: 18,
      textAlign: "left",
      left: -14,
      fontWeight: 200,
      color: "black",
    },
    paper: {
      padding: 5,
      minWidth: 180,
      borderColor: "#ffd700",
      borderWidth: 4,
      borderStyle: "solid",
    },

    subHeader: {
      padding: 3,
      width: 400,
      borderBottom: "1px black solid",
      fontWeight: 500,
    },
    paperContainer: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
      position: "relative",
    },
    heading: {
      marginBottom: 10,
      fontWeight: 400,
    },
    totalLabel: {
      fontWeight: 500,
      marginTop: 10,
      textAlign: "center",
    },
    labelRoot: {
      fontSize: 12,
      color: "black",
    },
    spacing: {
      margin: theme.spacing(1),
    },
    imageHolder: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "3em",
    },
    mainLoaderContainer: {
      position: "absolute",
      paddingTop: "15%",
      width: "99%",
      height: "98%",
      zIndex: 1000,
      backgroundColor: "rgba(0,0,0,0.1)",

      textAlign: "center",
    },
  }
  return style
}

function getAgeFilter(filterBounds: string) {
  if (filterBounds !== "All") {
    // (isNumber) = 0 if value is string 1 if number
    let filter = [
      { operator: ">=", isNumber: "1", value: "" },
      { operator: "<=", isNumber: "1", value: "" },
    ]
    let bounds = filterBounds.split("-")
    filter[0].value = bounds[0]
    filter[1].value = bounds[1]
    if (parseInt(bounds[0]) > 20) {
      return filter
    } else {
      return []
    }
  }

  return []
}

function getStateFilter(stateValue: string) {
  if (stateValue !== "All") {
    let stateFilter = [{ operator: "=", isNumber: "0", value: stateValue }]
    return stateFilter
  }
  return []
}

function getHouseholdCompFilter(clientSummary: object) {
  return [
    {
      operator: "=",
      value: "'" + Helpers.getHouseholdComp(clientSummary) + "'",
      isNumber: "0",
    },
  ]
}

function getDependantsFilter(clientSummary: object) {
  let dependantsCount = Helpers.getDependantsTotal(clientSummary)
  return [{ operator: "=", isNumber: "1", value: dependantsCount }]
}

const defaultLocations = [
  "'ACT'",
  "'NSW'",
  "'NT'",
  "'QLD'",
  "'SA'",
  "'TAS'",
  "'VIC'",
  "'WA'",
]

interface Props extends WithStyles<typeof styles, false> {
  moneyFitPromise: Function
  clientSummary: object
  countTotal: Function
  totalSurplus: number | null | undefined
}

export default withStyles(styles)(TotalAnnualSurplusGraph)
