import React, { useState } from "react"
import * as dateFns from "date-fns"
import useMeasure from "react-use-measure"
import { animated as a, useSpring } from "react-spring"
import { ResizeObserver } from "@juggle/resize-observer"

import SectionHeader from "./Section-Header/SectionHeader"
import { MonthlyBalanceItemSchema } from "../schemas/monthlyBalanceResponseSchema"
import { formatCurrency, decodeMonthlyBalanceItemDate } from "../lib/utils"
import tableStyles from "../lib/tables.module.scss"
import clsx from "clsx"
import { calculateMonthToMonthChangeInCashPosition } from "../lib/calculator"

const CheckupReportingTableSection: React.FC<Props> = ({
  monthlyBalanceItems,
  isEditable,
  targetedMonthlySurplus,
  primaryAccountAmount,
  provisioningSpent,
  monthlyAllocated,
  yearlyProvision,
  cashPosition,
  accumulatedSurplus,
  monthlyActualSurplus,
  monthlyExcessSurplus,
  accumulatedExcessSurplus,
  rollingTargetedSurplus,
}) => {
  const [mainRef] = useMeasure({
    polyfill: ResizeObserver,
  })

  const [addTableHeight, setAddTableHeight] = useState(false)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const prevHeightSpring = useSpring({
    height: addTableHeight ? 530 : 0,
    config: {
      duration: 300,
    },
  })

  return (
    <section>
      <SectionHeader
        title="Check-up Reporting Table"
        rotate={addTableHeight}
        onClickHandler={setTableHeightHanlder}
        showSubheader={true}
        centerSubHeaderTitle={null}
        centerSubHeaderAmount={null}
        monthlyOutAmount={null}
        monthlyInAmount={null}
        textTitle={null}
        greyedOut={!isEditable}
      />
      <a.div className={clsx(tableStyles.tableLayOut)} style={prevHeightSpring}>
        <table className={tableStyles.table} ref={mainRef}>
          <thead>
            <tr>
              <th></th>
              {monthlyBalanceItems.map((monthlyBalanceItem, i) => {
                const date = decodeMonthlyBalanceItemDate(monthlyBalanceItem)
                return (
                  <th
                    key={i}
                    className={clsx(!isEditable && tableStyles.greyedOut)}
                  >
                    {i === 0
                      ? "Starting Balance"
                      : dateFns.format(date, "MMM''yy")}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Primary Account Balance</td>
              {primaryAccountAmount.map((amount, i) => (
                <td key={`primary-account-${i}`}>{formatCurrency(amount)}</td>
              ))}
            </tr>
            <tr>
              <td>Credit Card Balance</td>
              {monthlyBalanceItems.map((monthlyBalanceItem, i) => (
                <td key={`credit-card-balance-${i}`}>
                  {formatCurrency(monthlyBalanceItem.creditCardBalance)}
                </td>
              ))}
            </tr>
            <tr className={tableStyles.space}></tr>
            <tr>
              <td>Cash Position</td>
              {cashPosition.map((cash, i) => (
                <td key={`cash-position-${i}`}>{formatCurrency(cash)}</td>
              ))}
            </tr>
            <tr>
              <td>Change in Cash Position</td>
              {monthlyBalanceItems.map((_, i, self) => (
                <td key={`change-in-cash-pos-${i}`}>
                  {formatCurrency(
                    calculateMonthToMonthChangeInCashPosition(self, i)
                  )}
                </td>
              ))}
            </tr>
            <tr className={tableStyles.space}></tr>
            <tr>
              <td>Provisionings Spent</td>
              {provisioningSpent.map((spent, i) => (
                <td key={`provision-spent-${i}`}>{formatCurrency(spent)}</td>
              ))}
            </tr>
            <tr>
              <td>Monthly Allocated Provisions Spending</td>
              {monthlyAllocated.map((allocated, i) => (
                <td key={`monthly-allocated-prov-${i}`}>
                  {formatCurrency(allocated)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Yearly Remaining Provisionings Total</td>
              {yearlyProvision.map((yearlyProvision, i) => (
                <td key={`yearly-remaining-prov-${i}`}>
                  {formatCurrency(yearlyProvision)}
                </td>
              ))}
            </tr>
            <tr className={tableStyles.space}></tr>
            <tr>
              <td>Accumulated Actual Surplus</td>
              {accumulatedSurplus.map((accumulated, i) => (
                <td key={`accumulated-actual-surplus-${i}`}>
                  {formatCurrency(accumulated)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Monthly Actual Surplus</td>
              {monthlyActualSurplus.map((monthlyActualSurplus, i) => (
                <td key={`monthly-actual-surplus-${i}`}>
                  {formatCurrency(monthlyActualSurplus)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Targeted Monthly Surplus</td>
              {targetedMonthlySurplus.map((targetValues, index) => (
                <td key={`targeted-monthly-surplus-${index}`}>
                  {index === 0
                    ? formatCurrency(0)
                    : formatCurrency(targetValues)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Rolling Targeted Surplus</td>
              {rollingTargetedSurplus.map((rollingTarget, i) => (
                <td key={`rolling-targeted-surpl-${i}`}>
                  {formatCurrency(rollingTarget)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Monthly Excess Surplus</td>
              {monthlyExcessSurplus.map((monthlyExcess, i) => (
                <td key={`monthly-excess-surp-${i}`}>
                  {formatCurrency(monthlyExcess)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Accumulated Excess Surplus</td>
              {accumulatedExcessSurplus.map((accumulatedExcessSurp, i) => (
                <td key={`accumulated-excess-${i}`}>
                  {formatCurrency(accumulatedExcessSurp)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </a.div>
    </section>
  )
}

interface Props {
  monthlyBalanceItems: MonthlyBalanceItemSchema[]
  isEditable: boolean
  targetedMonthlySurplus: Array<number>
  primaryAccountAmount: Array<number>
  provisioningSpent: Array<number>
  monthlyAllocated: Array<number>
  yearlyProvision: Array<number>
  cashPosition: Array<number>
  accumulatedSurplus: Array<number>
  monthlyActualSurplus: Array<number>
  monthlyExcessSurplus: Array<number>
  accumulatedExcessSurplus: Array<number>
  rollingTargetedSurplus: Array<number>
}

export default CheckupReportingTableSection
