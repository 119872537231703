exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes _27rAOtU4c4foyROG1IbDCf {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes _27rAOtU4c4foyROG1IbDCf {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n._3BvZd_OVEhuw17Nwh0uBYc {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  overflow-x: scroll; }\n\n._2yOxOyaPkrVYVGP0-jFP4z {\n  font-family: \"Poppins\", \"Helvetica\", \"sans-serif\", -apple-system, monospace !important;\n  font-size: 13px;\n  border-collapse: collapse;\n  margin: 1em 0; }\n  ._2yOxOyaPkrVYVGP0-jFP4z > thead {\n    color: #000; }\n    ._2yOxOyaPkrVYVGP0-jFP4z > thead .KgsYCcABwme-zIO6kyPeM {\n      color: #000; }\n  ._2yOxOyaPkrVYVGP0-jFP4z td {\n    border: 1px solid #000000;\n    padding: 0.5em;\n    min-width: 8em; }\n  ._2yOxOyaPkrVYVGP0-jFP4z td:not(:first-of-type) {\n    text-align: center; }\n  ._2yOxOyaPkrVYVGP0-jFP4z td:first-of-type {\n    white-space: nowrap; }\n\n.CAkBy0gJV1kzyebNe4k81 {\n  display: block;\n  margin-bottom: 0.5em; }\n", ""]);
// Exports
exports.locals = {
	"tableLayOut": "_3BvZd_OVEhuw17Nwh0uBYc",
	"table": "_2yOxOyaPkrVYVGP0-jFP4z",
	"greyedOut": "KgsYCcABwme-zIO6kyPeM",
	"space": "CAkBy0gJV1kzyebNe4k81",
	"fadeInDown": "_27rAOtU4c4foyROG1IbDCf"
};