export const config = [
  {
    firstStepModal: true,
    title: "Living and Lifestyle Account",
    desc: "How is your 7 days holding up?",
    infoControl: {
      title: "Let's get started! With your Living and Lifestyle funds!",
      description: `Please see the following that you have chosen as your
          previous years living and lifestyle expenses. Now is the
          time to check and make any adjustments (up or preferably down).
        Click the next button once you are happy with the
        Living and Lifestyle expenses.
        
        (Missing and expense? Don't worry there is a section
        at the end of fields previously not filled out you will
        have the opportunity to edit those fields there)`,
    },
  },
  {
    title: "Credit Card",
    desc: "This should be your fixed bills, to prevent unnecessary over spend.",
    infoControl: {
      title: `Next let's look at your Credit 
      Card expenses!`,
      description: `Please check your expenses to see if what you
        previously entered in is reflective of your current
        situation?
        Have you bills gone up?
        Have your bills gone down?
        Did you cancel that subscription?
        Do you really need that subscription?
        Nows the time to update them in this section!
        Keep in mind if the expense is no longer coming from
        the credit card you can delete the figure here also!`,
    },
  },
  {
    title: "Direct Payments Jar",
    desc: "Bills unable to be paid by the credit card.",
    infoControl: {
      title: `Next let's look at your Direct
      Payments`,
      description: `Please check your expenses to see if what you 
      previously entered in is reflective of your current
      situation?
      Have you bills gone up?
      Have your bills gone down?
      Did you cancel that subscription?
      Do you really need that subscription?
      Nows the time to update them in this section!`,
    },
  },
  {
    title: "Provisions Roll Over",
    desc: "What will you be provisioning this year?",
    infoControl: {
      title: "Provision Roll Over!",
      description: `Your Provisioning for this new year starts fresh!
        In this process, you will be putting and archive on last periods provisioningsand setting a clean slate for this period!\
      Time to think of the one off large expenses you are
      setting money aside for which you can spend at any
      point in time over the year - for example that
      bathroom renovation or the family holiday.`,
    },
  },
  {
    title: "Unallocated Payments",
    desc:
      "These are expenses you have left blank, please check to see if you are not spending in any of these expenses. If you are, please fill in the amount and allocate to the account you wish to use with the Money SMARTS system.",
    infoControl: {
      title: `Nearly there! Finally
      Your unallocated expenses:`,
      description: `These are fields you have left blank last year as you
      do not spend in these categories.
      However, now is the time to check in if you have spent
      in these categories and would like to record them in
      your Money SMARTS system.
      Please note the buttons on the bottom of the section,
      if there are expenses not already listed, time to add 
      them here.`,
    },
    beforeClosing: true,
  },
  {
    hidePrimaryModal: true,
    infoControl: {
      title: `Congratulations,
      You're all done!`,
      description: `You've now finished rolling over your provisions for a new 12-month period.
        
        Please go ahead and enter in a new starting balance and date under 'monthly check-up'.
        
        If you would like to view your previous periods, these are now available for you to view.`,
    },
  },
]
