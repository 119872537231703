export const frequencyOptions = [
  {
    value: "Weekly",
    name: "Weekly",
  },
  {
    value: "Fortnightly",
    name: "Fortnightly",
  },
  {
    value: "Monthly",
    name: "Monthly",
  },
  {
    value: "Every 2 months",
    name: "Every 2 months",
  },
  {
    value: "Every 3 months",
    name: "Every 3 months",
  },
  {
    value: "Every 6 months",
    name: "Every 6 months",
  },
  {
    value: "Yearly",
    name: "Yearly",
  },
  {
    value: "Every 2 years",
    name: "Every 2 years",
  },
  {
    value: "Quarterly",
    name: "Quarterly",
  },
  {
    value: "Bi-Monthly",
    name: "Bi-Monthly",
  },
]

export const frequencyOptionsYearly = [
  {
    value: "Weekly",
    name: "Weekly",
  },
  {
    value: "Fortnightly",
    name: "Fortnightly",
  },
  {
    value: "Monthly",
    name: "Monthly",
  },
  {
    value: "Every 2 months",
    name: "Every 2 months",
  },
  {
    value: "Every 3 months",
    name: "Every 3 months",
  },
  {
    value: "Every 6 months",
    name: "Every 6 months",
  },
  {
    value: "Yearly",
    name: "Yearly",
  },
  {
    value: "Every 2 years",
    name: "Every 2 years",
  },
]

export const intialValues = {
  totalNetIncomeClient1: 0,
  totalNetIncomeClient2: 0,
  totalNetIncomeClient1Percentage: 90,
  totalNetIncomeClient2Percentage: 90,
  totalMonthlyBillsEssential: 0,
  totalMonthlySpendingEssential: 0,
  netIncomeFrequency: "Monthly",
  cashInBank: 0,
  otherAvailableFunds: 0,
  totalLoanRepayments: 0,
  totalLoanRepaymentsFrequency: "Monthly",
  monthlyInvestmentPropertyCost: 0,
  monthlyInvestmentPropertyCostFrequency: "Monthly",
  monthlyOtherPropertyCost: 0,
  monthlyOtherPropertyCostFrequency: "Monthly",
  bills: [],
  spending: [],
}
