import React from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const CustomSlider = withStyles({
  root: {
    color: "var(--portal-page-primary-background-alt)",
    height: 10,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "var(--portal-page-primary-background)",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 10,
    background: "var(--portal-page-primary-background-alt)",
  },
  rail: {
    height: 10,
    background: "#CECECE",
    boxShadow: "0px 3px 6px #CECECE",
  },
})(Slider);

const TemperatureSlider: React.FC<Props> = ({
  name,
  min = 0,
  max = 100,
  step = 1,
  value,
  onChange,
  onChangeCommitted,
}) => (
  <CustomSlider
    valueLabelDisplay="auto"
    aria-label="pretto slider"
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={onChange}
    onChangeCommitted={onChangeCommitted}
    name={name}
  />
);

interface Props {
  onChangeCommitted?: () => void;
  onChange: () => void;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  name: string;
}

export default TemperatureSlider;
