// process the value irrespective of representation type

// let val1El = "#FC2B40"
// let val2El = "#ac5355"
// let steps = 5

//return a workable RGB int array [r,g,b] from hex representation
function processHEX(val) {
  //does the hex contain extra char?
  let hex = val.length > 6 ? val.substr(1, val.length - 1) : val
  let r: string
  let g: string
  let b: string
  // is it a six character hex?
  if (hex.length > 3) {
    //scrape out the numerics
    r = hex.substr(0, 2)
    g = hex.substr(2, 2)
    b = hex.substr(4, 2)

    // if not six character hex,
    // then work as if its a three character hex
  } else {
    // just concat the pieces with themselves
    r = hex.substr(0, 1) + hex.substr(0, 1)
    g = hex.substr(1, 1) + hex.substr(1, 1)
    b = hex.substr(2, 1) + hex.substr(2, 1)
  }
  // return our clean values
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)]
}

export function gradientGenerator(val1El, val2El, steps, baseColor) {
  //attach start value
  let val1RGB = processHEX(val1El)
  let val2RGB = processHEX(val2El)
  let colors = [
    // somewhere to dump gradient
  ]
  // the pre element where we spit array to user
  // let spitter = document.getElementById('spitter');

  //the number of steps in the gradient
  let stepsInt = parseInt(steps, 10)
  //the percentage representation of the step
  let stepsPerc = 100 / (stepsInt + 1)

  // diffs between two values
  let valClampRGB = [
    val2RGB[0] - val1RGB[0],
    val2RGB[1] - val1RGB[1],
    val2RGB[2] - val1RGB[2],
  ]
  let colorArray = []
  // build the color array out with color steps
  for (let i = 0; i < stepsInt; i++) {
    let clampedR =
      valClampRGB[0] > 0
        ? pad(
            Math.round((valClampRGB[0] / 100) * (stepsPerc * (i + 1))).toString(
              16
            ),
            2,
            baseColor
          )
        : pad(
            Math.round(
              val1RGB[0] + (valClampRGB[0] / 100) * (stepsPerc * (i + 1))
            ).toString(16),
            2,
            baseColor
          )

    let clampedG =
      valClampRGB[1] > 0
        ? pad(
            Math.round((valClampRGB[1] / 100) * (stepsPerc * (i + 1))).toString(
              16
            ),
            2,
            baseColor
          )
        : pad(
            Math.round(
              val1RGB[1] + (valClampRGB[1] / 100) * (stepsPerc * (i + 1))
            ).toString(16),
            2,
            baseColor
          )

    let clampedB =
      valClampRGB[2] > 0
        ? pad(
            Math.round((valClampRGB[2] / 100) * (stepsPerc * (i + 1))).toString(
              16
            ),
            2,
            baseColor
          )
        : pad(
            Math.round(
              val1RGB[2] + (valClampRGB[2] / 100) * (stepsPerc * (i + 1))
            ).toString(16),
            2,
            baseColor
          )
    colors[i] = ["#", clampedR, clampedG, clampedB].join("")
    colorArray.push(colors[i])
  }
  return colorArray
}
function pad(n, width, z) {
  z = z || "0"
  n = n + ""
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}
