import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MonthlyBalanceItemSchema } from "../schemas/monthlyBalanceResponseSchema";
import { DraftMonthlyBalanceItem } from "../lib/utils";
import styles from "./MonthlyCheckupSection.module.scss";
import plus from "../images/icons/plus-purple.png";
import remove from "../images/icons/remove.png";
import AppDataContext from "../context/AppDataContext";
import moment from "moment";
import "./css/material.css";

const MonthlyCheckupSection: React.FC<Props> = ({
  monthlyBalanceStartDate,
  monthlyBalanceItems,
  onMonthlyBalanceStartDateChange,
  onMonthlyBalanceItemsCreate,
  onMonthlyBalanceItemsUpdate,
  onMonthlyBalanceItemsDelete,
  deleteAllMonthlyBalanceItems,
  onSubmit,
  isEditable,
  rolloverSwitch,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const { rolloverDispatch } = useContext(AppDataContext);
  const readyToRollover = monthlyBalanceItems.length > 12;

  const addMonthButtonHandler = () => {
    if (monthlyBalanceItems.length === 13) {
      onSubmit();
    }
    onMonthlyBalanceItemsCreate();
  };

  const addMonthButton = (
    <div>
      <img
        alt="plus"
        title="Add Month"
        src={plus}
        className={styles.icon}
        onClick={addMonthButtonHandler}
      />
    </div>
  );

  const removeMonthButton = (id) => (
    <div>
      <img
        alt="remove"
        title="Remove Month"
        src={remove}
        className={styles.icon}
        onClick={() => {
          onMonthlyBalanceItemsDelete(id);
        }}
      />
    </div>
  );

  const submitHandler = () => {
    onSubmit();
  };

  return (
    <section>
      <h2 className={styles.title}>Monthly Check-up</h2>
      <div className={styles.checkupWrapper}>
        <div className={styles.topBar}>
          <h3>Primary and Credit Card Accounts</h3>

          <div className={styles.actionBtnWrapper}>
            <button
              className={clsx(
                styles.clearButton,
                !isEditable && styles.disabledButton
              )}
              type="button"
              onClick={() => {
                setOpenModal(true);
              }}
              disabled={!isEditable}
            >
              CLEAR
            </button>
            {readyToRollover ? (
              <button
                className={clsx(
                  styles.rolloverButton,
                  !isEditable && styles.disabledButton
                )}
                type="button"
                onClick={() => {
                  rolloverDispatch({ type: "SHOW_ROLLOVER_INTRO" });
                  submitHandler();
                }}
                disabled={!isEditable}
              >
                ROLLOVER
              </button>
            ) : null}
            <button
              className={clsx(
                styles.updateButton,
                !isEditable && styles.disabledButton
              )}
              type="button"
              onClick={() => submitHandler()}
              disabled={!isEditable}
            >
              UPDATE
            </button>
          </div>
        </div>

        <br />

        <div className={styles.tableWrapper}>
          <table>
            <StartDate
              startDate={monthlyBalanceStartDate}
              onStartDateChange={onMonthlyBalanceStartDateChange}
              isEditable={isEditable}
            />
            <thead>
              <tr>
                <th></th>
                <th>Account Balance</th>
                <th>Credit Card Balance</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {monthlyBalanceItems.map((monthlyBalanceItem, i, self) => (
                <EditingMonthlyBalanceRow
                  key={monthlyBalanceItem.id}
                  defaultMonthlyBalanceItem={monthlyBalanceItem}
                  onAccept={(values) => {
                    onMonthlyBalanceItemsUpdate(values);
                  }}
                  removeMonthButton={
                    isEditable &&
                    i === monthlyBalanceItems.length - 1 &&
                    i !== 0 &&
                    removeMonthButton
                  }
                  isStartBalance={i === 0}
                  isEditable={isEditable}
                />
              ))}
              {isEditable && monthlyBalanceItems.length < 13 && (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{addMonthButton}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={styles.pagination}>{rolloverSwitch()}</div>

        <br />
        <Dialog open={openModal}>
          <div className={styles.modal}>
            <p>Confirm Clearing of Data?</p>
            <div className={styles.buttons}>
              <button
                className={styles.clearButton}
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                NO
              </button>
              <button
                className={styles.updateButton}
                onClick={() => {
                  deleteAllMonthlyBalanceItems();
                  setOpenModal(false);
                }}
              >
                YES
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </section>
  );
};

interface Props {
  allMonthlyBalanceItems: MonthlyBalanceItemSchema[];
  monthlyBalanceStartDate: Date;
  monthlyBalanceItems: DraftMonthlyBalanceItem[];
  onMonthlyBalanceStartDateChange: (monthlyBalanceStartDate: Date) => void;
  onMonthlyBalanceItemsCreate: () => void;
  onMonthlyBalanceItemsUpdate: (
    monthlyBalanceItem: DraftMonthlyBalanceItem
  ) => void;
  onMonthlyBalanceItemsDelete: (id: string) => void;
  deleteAllMonthlyBalanceItems: () => void;
  hasNext: boolean;
  onSubmit: () => void;
  hasUnsavedChanges: boolean;
  isEditable: boolean;
  rolloverSwitch: () => React.ReactNode;
}

// Start Date
const StartDate: React.FC<StartDateProps> = ({
  startDate,
  onStartDateChange,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(startDate)
  );

  const { isRollOver } = useContext(AppDataContext);

  useEffect(() => {
    setSelectedDate(new Date(startDate));
  }, [startDate]);

  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      setSelectedDate(date);
      onStartDateChange(date);
    }
  };

  return (
    <thead>
      <th>Start Date:</th>
      <td className={styles.picker}>
        <div className={styles.calendarSelector}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={selectedDate}
              onChange={(e) => handleDateChange(e)}
              disabled={isRollOver}
            />
          </MuiPickersUtilsProvider>
        </div>
      </td>
      <td></td>
    </thead>
  );
};

interface StartDateProps {
  startDate: Date;
  onStartDateChange: (date: Date) => void;
  isEditable: boolean;
}

// ============================================================================
// EditingMonthlyBalanceRow
// ============================================================================

const EditingMonthlyBalanceRow: React.FC<EditingMonthlyBalanceRowProps> = ({
  defaultMonthlyBalanceItem,
  onAccept,
  removeMonthButton,
  isStartBalance,
  isEditable,
}) => {
  const [monthlyBalanceItem, setMonthlyBalanceItem] = useState(
    defaultMonthlyBalanceItem
  );

  const onChangeMonthlyBalanceItem = (field) => (event) => {
    const { value = "" } = event.target;
    const number = value.replace(/[^0-9.]/g, "");
    const newValue = number.split(/(?=(?:\d{3})+$)/).join("");
    setMonthlyBalanceItem((prev) => ({ ...prev, [field]: newValue }));
  };

  return (
    <tr>
      <td className={styles.period}>
        {isStartBalance
          ? "Starting Balance"
          : `${monthlyBalanceItem.month} ${monthlyBalanceItem.year}`}
      </td>
      <td>
        <FormControl>
          <Input
            className={styles.monthlyInput}
            value={`$ ${monthlyBalanceItem.amount}`}
            onChange={onChangeMonthlyBalanceItem("amount")}
            onBlur={() => onAccept(monthlyBalanceItem)}
            inputProps={{ disabled: !isEditable }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl>
          <Input
            className={styles.monthlyInput}
            value={`$ ${monthlyBalanceItem.creditCardBalance}`}
            onChange={onChangeMonthlyBalanceItem("creditCardBalance")}
            onBlur={() => onAccept(monthlyBalanceItem)}
            inputProps={{ disabled: !isEditable }}
          />
        </FormControl>
      </td>
      <td>{removeMonthButton && removeMonthButton(monthlyBalanceItem.id)}</td>
    </tr>
  );
};

interface EditingMonthlyBalanceRowProps {
  defaultMonthlyBalanceItem: DraftMonthlyBalanceItem;
  onAccept: (monthlyBalanceItem: DraftMonthlyBalanceItem) => void;
  removeMonthButton: (id: string) => React.ReactNode;
  isStartBalance: boolean;
  isEditable: boolean;
}

// ============================================================================
// Helpers
// ============================================================================

export default MonthlyCheckupSection;
