import formatNumber from "format-number"
import * as dateFns from "date-fns"
import * as _ from "lodash"
import {
  ExpenseItemSchema,
  BorrowingItemSchema,
  PropertySchema,
  FrequencySchema,
  SuperFundSchema,
  InvestmentsSchema,
} from "../schemas/clientSummaryResponseSchema"
import {
  MonthlyBalanceItemSchema,
  MonthlyBalanceStartDataSchema,
} from "../schemas/monthlyBalanceResponseSchema"
import { calculateMonthlyAfterTaxContribution } from "../lib/calculator"

// ============================================================================
// Expense getters
// ============================================================================

export const getLivingLifestyleExpenses = (
  expenses: ExpenseItemSchema[],
  properties: PropertySchema[]
) => {
  const standardExpenses = expenses
    .filter(
      expense =>
        expense.category === "7 Days Float" ||
        expense.category === "via Living & Lifestyle"
    )
    .filter(
      expense =>
        expense.category ||
        expense.tier4 === "Living Lifestyle" ||
        expense.tier4 === "Household" ||
        expense.tier4 === "Regular Payments"
    )

  type PropertyDerivedExpense = Pick<
    ExpenseItemSchema,
    "amount" | "desc" | "frequency" | "_id"
  >

  let propertyDerivedExpenses: PropertyDerivedExpense[] = []
  let propertyDerivedCustomExpenses: PropertyDerivedExpense[] = []

  if (!_.isNil(properties)) {
    properties
      .map((property, i) => ({
        property,
        index: i,
        customHoldingCosts: property.defaultHoldingCosts,
      }))
      .filter(
        x =>
          x.property.purpose === "Investment" ||
          x.property.purpose === "Business Use" ||
          x.property.purpose === "Personal Use"
      )
      .map(x =>
        x.customHoldingCosts.map(ch =>
          ch.category === "7 Days Float"
            ? propertyDerivedCustomExpenses.push({
                amount: ch.value,
                desc: `${
                  x.property.purpose === "Personal Use" ? "Personal" : "INV"
                } Prop #${x.index + 1}: ${ch.name}`,
                frequency: "Yearly" as const,
                _id: ch._id,
              })
            : null
        )
      )

    properties
      .map((property, i) => ({
        property,
        index: i,
        customHoldingCosts: property.customHoldingCosts,
      }))
      .filter(
        x =>
          x.property.purpose === "Investment" ||
          x.property.purpose === "Business Use" ||
          x.property.purpose === "Personal Use"
      )
      .map(x =>
        x.customHoldingCosts.map(ch =>
          ch.category === "7 Days Float"
            ? propertyDerivedCustomExpenses.push({
                amount: ch.value,
                desc: `${
                  x.property.purpose === "Personal Use" ? "Personal" : "INV"
                } Prop #${x.index + 1}: ${ch.name}`,
                frequency: "Yearly" as const,
                _id: ch._id,
              })
            : null
        )
      )
  }

  let finalData = [
    ...standardExpenses,
    ...propertyDerivedExpenses,
    ...propertyDerivedCustomExpenses,
  ]
  return finalData
}

export const getCreditCardExpenses = (
  expenses: ExpenseItemSchema[],
  properties: PropertySchema[]
) => {
  const standardExpenses = expenses
    .filter(
      expense =>
        expense.category === "Paid by Credit" ||
        expense.category === "via Credit Card"
    )
    .filter(expense => expense.category || expense.tier4 === "Bills")

  type PropertyDerivedExpense = Pick<
    ExpenseItemSchema,
    "amount" | "desc" | "frequency" | "_id"
  >

  let propertyDerivedExpenses: PropertyDerivedExpense[] = []
  let propertyDerivedCustomExpenses: PropertyDerivedExpense[] = []

  if (!_.isNil(properties)) {
    propertyDerivedExpenses = propertyExpensesCategories.flatMap(
      propertyExpenseCategory =>
        properties
          .map((property, i) => ({ index: i, property }))
          .filter(
            x =>
              (x.property.purpose === "Investment" ||
                x.property.purpose === "Business Use" ||
                x.property.purpose === "Personal Use") &&
              x.property.holdingCostCategories[propertyExpenseCategory.key] ===
                "Paid by Credit"
          )
          .filter(x => x.property[propertyExpenseCategory.key])
          .map(x => ({
            amount: x.property[
              propertyExpenseCategory.key
            ] as ExpenseItemSchema["amount"],
            desc: `${
              x.property.purpose === "Personal Use" ? "Personal" : "INV"
            } Prop #${x.index + 1}: ${propertyExpenseCategory.label}`,
            frequency: "Yearly" as const,
          }))
    )

    properties
      .map((property, i) => ({
        property,
        index: i,
        customHoldingCosts: property.customHoldingCosts,
      }))
      .filter(
        x =>
          x.property.purpose === "Investment" ||
          x.property.purpose === "Business Use" ||
          x.property.purpose === "Personal Use"
      )
      .map(x =>
        x.customHoldingCosts.map(ch =>
          ch.category === "Paid by Credit"
            ? propertyDerivedCustomExpenses.push({
                amount: ch.value,
                desc: `${
                  x.property.purpose === "Personal Use" ? "Personal" : "INV"
                } Prop #${x.index + 1}: ${ch.name}`,
                frequency: "Yearly" as const,
              })
            : null
        )
      )
  }

  let finalData = [
    ...standardExpenses,
    ...propertyDerivedExpenses,
    ...propertyDerivedCustomExpenses,
  ]
  return finalData
}

export const getDirectPaymentsExpenses = (
  expenses: ExpenseItemSchema[],
  properties: PropertySchema[],
  superFund: SuperFundSchema[] | any,
  investments: InvestmentsSchema[]
) => {
  const standardExpenses = expenses.filter(
    expense =>
      expense.category === "Direct from Primary" ||
      expense.category === "Primary Direct"
  )

  type PropertyDerivedExpense = Pick<
    ExpenseItemSchema,
    "amount" | "desc" | "frequency" | "_id"
  >

  let propertyDerivedExpenses: PropertyDerivedExpense[] = []
  let propertyDerivedCustomExpenses: PropertyDerivedExpense[] = []
  let otherAssetsInvestments = []

  if (!_.isNil(properties)) {
    propertyDerivedExpenses = propertyExpensesCategories.flatMap(
      propertyExpenseCategory =>
        properties
          .map((property, i) => ({ index: i, property }))
          .filter(
            x =>
              (x.property.purpose === "Investment" ||
                x.property.purpose === "Business Use" ||
                x.property.purpose === "Personal Use") &&
              x.property.holdingCostCategories[propertyExpenseCategory.key] ===
                "Direct from Primary"
          )
          .filter(x => x.property[propertyExpenseCategory.key])
          .map(x => ({
            amount: x.property[
              propertyExpenseCategory.key
            ] as ExpenseItemSchema["amount"],
            desc: `${
              x.property.purpose === "Personal Use" ? "Personal" : "INV"
            } Prop #${x.index + 1}: ${propertyExpenseCategory.label}`,
            frequency: "Yearly" as const,
          }))
    )

    properties
      .map((property, i) => ({
        property,
        index: i,
        customHoldingCosts: property.customHoldingCosts,
      }))
      .filter(
        x =>
          x.property.purpose === "Investment" ||
          x.property.purpose === "Business Use" ||
          x.property.purpose === "Personal Use"
      )
      .map(x =>
        x.customHoldingCosts.map(ch =>
          ch.category === "Direct from Primary"
            ? propertyDerivedCustomExpenses.push({
                amount: ch.value,
                desc: `${
                  x.property.purpose === "Personal Use" ? "Personal" : "INV"
                } Prop #${x.index + 1}: ${ch.name}`,
                frequency: "Yearly" as const,
              })
            : null
        )
      )
  }

  if (!_.isNil(investments)) {
    otherAssetsInvestments = investments
      .map((res, i) => {
        if (
          !_.isNil(res.contributionAmount) &&
          !_.isNil(res.contributionFrequency)
        ) {
          return {
            amount:
              res.contributionAmount *
              getYearlyFrequencyMultiplier(res.contributionFrequency),
            desc: `Other Investment #${i + 1}`,
            frequency: "Yearly" as const,
          }
        }
      })
      .filter(res => !_.isNil(res))
  }

  let finalData = [
    ...standardExpenses,
    ...propertyDerivedExpenses,
    ...propertyDerivedCustomExpenses,
    ...otherAssetsInvestments,
  ]
  if (typeof superFund !== "undefined") {
    let afterTax = calculateMonthlyAfterTaxContribution(superFund) * 12
    finalData.push({
      amount: afterTax,
      desc: "After Tax Contribution",
      frequency: "Yearly",
    })
  }

  return finalData
}

export const getLoansBorrowingItems = (borrowing: BorrowingItemSchema[]) =>
  borrowing.map(borrowingItem => borrowingItem)

export const getProvisionsExpenses = (
  expenses: ExpenseItemSchema[],
  properties: PropertySchema[]
) => {
  const standardExpenses = expenses
    .filter(
      expense =>
        expense.category === "Provisioning" ||
        expense.category === "Provision for"
    )
    .filter(expense => expense.category || expense.tier4 === "Provisioning")

  type PropertyDerivedExpense = Pick<
    ExpenseItemSchema,
    "amount" | "desc" | "frequency" | "basic" | "_id" | "discretionary"
  >

  let propertyDerivedExpenses: PropertyDerivedExpense[] = []
  let propertyDerivedCustomExpenses: PropertyDerivedExpense[] = []

  if (!_.isNil(properties)) {
    properties
      .map((property, i) => ({
        property,
        index: i,
        defaultHoldingCosts: property.defaultHoldingCosts,
      }))
      .filter(
        x =>
          x.property.purpose === "Investment" ||
          x.property.purpose === "Business Use" ||
          x.property.purpose === "Personal Use"
      )
      .map(x =>
        x.defaultHoldingCosts.map(ch =>
          ch.category === "Provisioning"
            ? propertyDerivedExpenses.push({
                amount: ch.value,
                basic: ch.value,
                desc: `${
                  x.property.purpose === "Personal Use" ? "Personal" : "INV"
                } Prop #${x.index + 1}: ${ch.name}`,
                frequency: "Yearly" as const,
                _id: ch._id,
              })
            : null
        )
      )
    properties
      .map((property, i) => ({
        property,
        index: i,
        customHoldingCosts: property.customHoldingCosts,
      }))
      .filter(
        x =>
          x.property.purpose === "Investment" ||
          x.property.purpose === "Business Use" ||
          x.property.purpose === "Personal Use"
      )
      .map(x =>
        x.customHoldingCosts.map(ch =>
          ch.category === "Provisioning"
            ? propertyDerivedCustomExpenses.push({
                amount: ch.value,
                basic: ch.value,
                desc: `${
                  x.property.purpose === "Personal Use" ? "Personal" : "INV"
                } Prop #${x.index + 1}: ${ch.name}`,
                frequency: "Yearly" as const,
                _id: ch._id,
              })
            : null
        )
      )
  }

  let finalData = [
    ...standardExpenses,
    ...propertyDerivedExpenses,
    ...propertyDerivedCustomExpenses,
  ]

  return finalData
}

// ============================================================================
// Response dates
// ============================================================================

export const decodeMonthlyBalanceStartDate = (
  monthlyBalanceStartDate?: MonthlyBalanceStartDataSchema
) => {
  if (!monthlyBalanceStartDate) return new Date()
  return new Date(
    Number(monthlyBalanceStartDate.year),
    Number(monthlyBalanceStartDate.month),
    Number(monthlyBalanceStartDate.day)
  )
}

export const encodeMonthlyBalanceStartDate = (
  monthlyBalanceStartDate: Date
): MonthlyBalanceStartDataSchema => {
  const month = String(
    monthlyBalanceStartDate.getMonth()
  ) as MonthlyBalanceStartDataSchema["month"]
  const year = String(monthlyBalanceStartDate.getFullYear())
  const day = String("0" + monthlyBalanceStartDate.getDate()).slice(-2)
  return { day, month, year }
}

export const getMonthlyBalanceItemDate = (
  monthlyBalanceItem: MonthlyBalanceItemSchema
) =>
  dateFns.parse(
    monthlyBalanceItem.year + monthlyBalanceItem.month,
    "yyyyMMMM",
    new Date(2010, 1, 1)
  )

export const decodeMonthlyBalanceItemDate = (
  monthlyBalanceItem: MonthlyBalanceItemSchema
) =>
  monthlyBalanceItem &&
  dateFns.parse(
    monthlyBalanceItem.month,
    "MMMM",
    new Date(Number(monthlyBalanceItem.year), 1, 1)
  )

export const twelveMonthsLabel = (startDate: Date) => {
  let newArr = []
  for (let i = 0; i < 13; i++) {
    let month = dateFns.format(dateFns.addMonths(startDate, i), "MMM")
    let year = dateFns.format(dateFns.addMonths(startDate, i), "yy")
    newArr.push(month + "'" + year)
  }
  return newArr
}

// ============================================================================
// Other
// ============================================================================

export type DraftMonthlyBalanceItem = MonthlyBalanceItemSchema & { id: string }

export const formatCurrency = formatNumber({
  prefix: "$",
  round: 2,
  padRight: 2,
})

export const getMonthlyFrequencyMultiplier = (
  frequency: FrequencySchema
): number => {
  switch (frequency) {
    case "Weekly":
      return 52 / 12
    case "Fortnightly":
      return 26 / 12
    case "Monthly":
      return 1
    case "Every 2 months":
      return 1 / 2
    case "Every 3 months":
      return 1 / 3
    case "Every 6 months":
      return 1 / 6
    case "Yearly":
      return 1 / 12
    case "Every 2 years":
      return 1 / 24
    case "Quarterly":
      return 1 / 3
    case "Bi-Monthly":
      return 24 / 12
  }
}

export const getYearlyFrequencyMultiplier = (
  frequency: FrequencySchema
): number => {
  switch (frequency) {
    case "Weekly":
      return 52
    case "Fortnightly":
      return 26
    case "Monthly":
      return 12
    case "Every 2 months":
      return 6
    case "Every 3 months":
      return 4
    case "Every 6 months":
      return 2
    case "Yearly":
      return 1
    case "Every 2 years":
      return 1 / 2
  }
}

export const chartJsTooltipLabel = (item: Chart.ChartTooltipItem) =>
  item.yLabel ? formatCurrency(Number(item.yLabel)) : "Unknown"

export const chartJsTooltipTitle = (
  item: Chart.ChartTooltipItem[],
  data: Chart.ChartData
) => {
  if (
    data.datasets &&
    data.labels &&
    item[0] &&
    item[0].datasetIndex !== undefined &&
    item[0].index !== undefined
  ) {
    const label = data.datasets[item[0].datasetIndex].label
    const title = data.labels[item[0].index]
    return `${title} - ${label}`
  } else {
    return "Unknown"
  }
}

// ============================================================================
// Helpers
// ============================================================================

const propertyExpensesCategories: {
  key: keyof PropertySchema
  label: string
}[] = [
  { key: "bodyCorpCosts", label: "Body Corp Costs" },
  { key: "councilRates", label: "Council Rates" },
  { key: "insuranceCosts", label: "Insurance Costs" },
  { key: "landTaxCosts", label: "Land Tax Costs" },
  { key: "maintenanceCost", label: "Maintenance Cost" },
  { key: "managementFees", label: "Management Fees" },
  { key: "waterRates", label: "Water Rates" },
]

export const getAnnualAmount = (expense: object) => {
  let amount = _.get(expense, "amount", 0)
  let annual = 0
  let frequency = _.get(expense, "frequency", "Yearly")
  if (amount > 0) {
    switch (frequency) {
      case "Weekly":
        annual = amount * 52
        break
      case "Fortnightly":
        annual = amount * 26
        break
      case "Monthly":
        annual = amount * 12
        break
      case "Every 2 months":
        annual = amount * 6
        break
      case "Every 3 months":
        annual = amount * 4
        break
      case "Every 6 months":
        annual = amount * 2
        break
      case "Yearly":
        annual = amount
        break
    }
  }
  return annual
}

export const toCurrencyTitle = (number: number) => {
  let val = Number(number)
  if (typeof val === "string" && val === "NaN") {
    return ""
  }
  return (
    `$\xa0` +
    Number(number)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  )
}

let monthsWord = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

let monthsNumber = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
]

export const converStartData = (month: string) => {
  const monthIndexNumber = monthsNumber.indexOf(month)
  let valueMonth = monthsWord[monthIndexNumber]
  return valueMonth
}

export const twoDecimalPlacing = (val: number) => {
  return Math.round(val * 100) / 100
}

export const getAllBills = val => {
  let shouldBeTrue
  switch (val.desc) {
    case "Rent":
      shouldBeTrue = true
      break
    case "Local Government Rates":
      shouldBeTrue = true
      break
    case "Water and Sewerage Rates and Charges":
      shouldBeTrue = true
      break
    case "House and Contents Insurance":
      shouldBeTrue = true
      break
    case "Electricity":
      shouldBeTrue = true
      break
    case "Gas":
      shouldBeTrue = true
      break
    case "Telephone - Fixed and Mobile":
      shouldBeTrue = true
      break
    case "Internet":
      shouldBeTrue = true
      break
    case "Pay Television":
      shouldBeTrue = true
      break
    case "Health Insurance":
      shouldBeTrue = true
      break
    case "Life Insurance":
      shouldBeTrue = true
      break
    case "Car Registration":
      shouldBeTrue = true
      break
    case "Car Insurance":
      shouldBeTrue = true
      break
    case "Motoring Organisations":
      shouldBeTrue = true
      break
    case "Education - School Fees":
      shouldBeTrue = true
      break
    case "Child Care Fees":
      shouldBeTrue = true
      break
    case "Bank Fees":
      shouldBeTrue = true
      break
    default:
      return
  }
  return shouldBeTrue
}

export const getAllSpendings = val => {
  let shouldBeTrue
  switch (val.desc) {
    case "Groceries and Non-alcoholic Beverages":
      shouldBeTrue = true
      break
    case "Clothing, Footwear, Dry cleaning, Repairs":
      shouldBeTrue = true
      break
    case "Education - Out-of-pocket expenses":
      shouldBeTrue = true
      break
    case "Personal Care":
      shouldBeTrue = true
      break
    case "Medical and Health Care expenses":
      shouldBeTrue = true
      break
    case "Pets and Animals":
      shouldBeTrue = true
      break
    case "Household Appliances and Tools":
      shouldBeTrue = true
      break
    case "Furniture":
      shouldBeTrue = true
      break
    case "Household Furnishings":
      shouldBeTrue = true
      break
    case "House and Garden maintenance":
      shouldBeTrue = true
      break
    case "Fuel":
      shouldBeTrue = true
      break
    case "Car maintenance":
      shouldBeTrue = true
      break
    case "Parking and Tolls":
      shouldBeTrue = true
      break
    case "Fares":
      shouldBeTrue = true
      break
    case "Presents and Gifts":
      shouldBeTrue = true
      break
    case "Donations":
      shouldBeTrue = true
      break
    case "Holidays":
      shouldBeTrue = true
      break
    case "Dining out / Takeaway food":
      shouldBeTrue = true
      break
    case "Entertainment":
      shouldBeTrue = true
      break
    case "Sport, recreation and hobbies":
      shouldBeTrue = true
      break
    case "Books, Magazines and Newspapers":
      shouldBeTrue = true
      break
    case "Video / DVD purchase and hire":
      shouldBeTrue = true
      break
    case "Alcohol and Tobacco":
      shouldBeTrue = true
      break
    case "Gambling and Lotto":
      shouldBeTrue = true
      break
    default:
      return
  }
  return shouldBeTrue
}
