import React from "react";
import * as d3 from "d3";
import _ from "lodash";

export default class BarsViewModel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      data: props.dataset,
      width: 600,
      height: 400,
      userRank: props.userRank,
      text: props.text,
      xText: props.xText,
      yText: props.yText,
    };
  }

  node = {};
  dispatcher = null;
  prevData = {};

  componentDidMount() {
    let el = this.node;
    this.createChart(
      el,
      { width: this.state.width + 50, height: this.state.height + 50 },
      this.state
    );
  }

  async componentWillReceiveProps(nextProps) {
    let el = this.node;
    if (nextProps.width) {
      // sets width of svg
      let svg = d3
        .select(String(el))
        .select("#barsd3")
        .attr("width", "100%")
        .attr("height", "100%");

      this.updateChart(el, this.state);
    }
    this.setState({ data: nextProps.dataset, userRank: nextProps.userRank });
  }

  componentDidUpdate() {
    let el = this.node;
    this.updateChart(el, this.state);
  }

  getData = (x) => {};

  getChartState = () => {};

  clearChart = () => {
    const svg = d3;
    svg.selectAll("rect").exit().remove();
    svg.selectAll("text").exit().remove();
    svg.selectAll("g").exit().remove();
    this.setState({ data: [] });
  };

  createChart = (el, props, state) => {
    let svg = d3
      .select(el)
      .append("svg")
      .attr("class", "d3")
      .attr("id", "barsd3")
      .attr("width", props.width)
      .attr("height", props.height)
      .attr("viewBox", "0 0 " + (props.width - 20) + " " + props.height);

    svg.append("g").attr("class", "bars");

    svg.append("g").attr("class", "yaxis");

    svg.append("g").attr("class", "xaxis");
    if (state.xText) {
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .style("font", "14px Poppins")
        .attr("dx", "-50%")
        .attr("x", props.width)
        .attr("y", props.height - 6)
        .text(state.xText);
    }
    if (state.yText) {
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("y", 6)
        .attr("dx", "-25%")
        .style("font", "14px Poppins")
        .attr("transform", "rotate(-90)")
        .text(state.yText);
    }
    this.updateChart(el, state);
  };

  updateChart = (el, state) => {
    let updatedData: any[] = [];
    let accumCount: number = 0;
    const data = state.data;

    for (let i = 0; i < data.length; i++) {
      if (i == 0) {
        updatedData.push({
          id: data[i].id,
          bins: data[i].bins,
          count: data[i].count,
          prevbins: null,
          countBelow: accumCount,
        });
      } else {
        accumCount = accumCount + data[i - 1].count;
        updatedData.push({
          id: data[i].id,
          bins: data[i].bins,
          count: data[i].count,
          prevbins: data[i - 1].bins,
          countBelow: accumCount,
        });
      }
    }

    updatedData = updatedData.sort(function (a, b) {
      return d3.descending(a.bins, b.bins);
    });

    this.drawChart(el, this.calculateScales(updatedData), updatedData);
  };

  destroyChart = () => {};

  calculateScales = (data) => {
    //define y scale
    let xMin = d3.min(data, (d: { count: number }, i) => {
      const { count } = d;
      return Number(count);
    });
    let xMax = d3.max(data, (d: { count: number }, i) => {
      const { count } = d;
      return Number(count);
    });

    let xScale = d3
      .scaleLinear()
      .range([0, this.state.width])
      .domain([0, xMax + 1]);

    //define x scale
    let yScale = d3
      .scaleLinear()
      .range([0, this.state.height])
      .domain([0, this.state.data.length]);

    //define color scale
    let colorDomain = d3.extent(data, (d: { count: number }, i) => {
      const { count } = d;
      return Number(count);
    });
    let colorScale = d3
      .scaleLinear()
      .domain(colorDomain)
      // @ts-ignore: Unreachable code error
      .range(["#709090", "#DCDCDC"]);

    //define bin scale (horizontal)
    let binDomain = data.map((d, i) => d.bins);
    let binScale = d3
      .scaleBand()
      .range([0, this.state.height])
      .domain(binDomain);

    return {
      y: yScale,
      x: xScale,
      color: colorScale,
      bin: binScale,
    };
  };

  drawChart = (el, scales, data) => {
    let init = (selection) => {
      const self = this;
      selection.each(function (this: any, data) {
        const f = d3.format("$.3s");

        // Create and configure the tooltip container
        d3.select(this).attr("class", "tooltip-container");
        //.style('width', 600);

        // Tooltip Title
        d3.select(this)
          .append("p")
          .attr("class", "tooltip-title")
          .style("font-family", "Poppins")
          .text(self.state.text + " Band");

        // Tooltip Content

        if (
          self.state.userRank < data.bins &&
          self.state.userRank > data.prevbins
        ) {
          let houseAbove = self.props.total - data.count - data.countBelow;
          let houseBelow = data.countBelow;
          let percent = houseBelow / self.props.total;
          let percent_str = (percent * 100).toFixed(1) + "%";

          d3.select(this)
            .append("p")
            .attr("class", "tooltip-content")
            .style("font-family", "Poppins")
            .text(
              "You are in the " +
                f(data.prevbins) +
                " to " +
                f(data.bins) +
                " band."
            );

          d3.select(this)
            .append("p")
            .attr("class", "tooltip-content")
            .style("font-family", "Poppins")
            .text(
              "There are " +
                houseAbove +
                " households above you and " +
                houseBelow +
                " households below you - that puts you at " +
                percent_str +
                " of all households."
            );
        } else {
          if (data.prevbins === null) {
            d3.select(this)
              .append("p")
              .attr("class", "tooltip-content")
              .text("Below " + f(data.bins));
          } else {
            d3.select(this)
              .append("p")
              .attr("class", "tooltip-content")
              .text(f(data.prevbins) + " to " + f(data.bins));
          }
        }
      });
    };

    let marked = false;
    let colorBar = (d, data, i, scales, mouseover = false) => {
      if (
        (i + 1 === data.length &&
          mouseover === true &&
          this.state.userRank < data[i].bins) ||
        (i + 1 === data.length &&
          marked === false &&
          this.state.userRank < data[i].bins) ||
        (this.state.userRank < data[i].bins &&
          this.state.userRank > data[i + 1].bins)
      ) {
        marked = true;
        return "var(--portal-page-primary-background)";
      } else {
        return scales.color(d.count);
      }
    };

    let createTip = (d) => {
      //  console.log("createTip");
      let tooltipContainer = d3
        .select("body")
        .append("div")
        .datum(d)
        .attr("class", "tooltip-container")
        .call(init);

      tooltipContainer
        .style("left", d3.event.pageX + "px")
        .style("top", d3.event.pageY + "px");
    };

    let removeTip = () => {
      d3.select("div.tooltip-container").remove();
    };

    let moveTip = () => {
      d3.select("body")
        .select("div.tooltip-container")
        .style("left", d3.event.pageX + "px")
        .style("top", d3.event.pageY + "px");
    };

    const g = d3.select(el).selectAll(".bars");
    let colorDomain = d3.extent(data, (d: { count: number }, i) => {
      const { count } = d;
      return Number(count);
    });
    const rect = g.selectAll("rect").data(data, (d: any) => {
      const { id } = d;
      return String(id);
    });

    const yaxis = d3.select(el).selectAll(".yaxis");
    const xaxis = d3.select(el).selectAll(".xaxis");

    rect
      .enter()
      .append("rect")
      .attr("transform", "translate(50,0)")
      .attr("width", 0)
      .attr("y", (d, i) => scales.y(i))
      .on("mouseenter", function (this: any, d, i) {
        d3.select(this).attr("fill", "#A3D137");
        return createTip(d);
      })
      .on("mouseout", function (this: any, d, i) {
        d3.select(this).attr("fill", function () {
          return "" + colorBar(d, data, i, scales, true) + "";
        });
        return removeTip();
      })
      .on("mousemove", function () {
        return moveTip();
      })
      .transition()
      // @ts-ignore: Unreachable code error
      .attr("width", (d, i) => scales.x(d.count))
      .attr("y", (d, i) => scales.y(i))
      .attr("height", scales.bin.bandwidth())
      .duration(2000)
      .attr("fill", (d, i) => {
        return "" + colorBar(d, data, i, scales) + "";
      });

    rect.exit().remove();

    yaxis.attr("transform", "translate(50,0)").call(
      // @ts-ignore: Unreachable code error
      d3
        .axisLeft(scales.bin)
        .ticks(20)
        // @ts-ignore: Unreachable code error
        .tickFormat(d3.formatPrefix(",.0", 1e5))
    );

    if (this.props.text == "Expense Items") {
      // @ts-ignore: Unreachable code error
      yaxis.call(
        // @ts-ignore: Unreachable code error
        d3
          .axisLeft(scales.bin)
          .ticks(20)
          // @ts-ignore: Unreachable code error
          .tickFormat(d3.format(".2s"))
      );
    }
    // @ts-ignore: Unreachable code error
    xaxis.attr("transform", "translate(50,400)").call(d3.axisBottom(scales.x));
  };
}
