import {
  calculateMonthlyPaygIncome,
  caclulateMonthlyBusinessIncome,
  caclulateMonthlySelfEmployedIncome,
  calculateMonthlyOtherIncome,
  calculateMonthlyInvestmentIncome,
  calculateMonthlyInvestmentPropertyDeductions,
  calculateMonthlyOtherTaxDeductions,
  calculateMonthlyNonTaxableIncome,
  calculateMonthlyLivingLifestyle,
  calculateMonthlyCreditCard,
  calculateMonthlyDirectPayments,
  calculateMonthlyLoans,
  calculateMonthlyProvisions,
  calculateMonthlyMoneyOut,
} from "../../lib/calculator"

import _ from "lodash"

import { formatCurrency } from "../../lib/utils"

export const incomeArray = (summary, newDashboardValues) => {
  const hasBorrowing = _.has(summary, "borrowings")
  const borrowingValue = hasBorrowing ? summary.borrowings.borrowing : []
  const hasSecondaryUser = _.has(summary, "personalInfo.client2")
  const hasIncome = _.has(summary, "income")
  const hasAssets = _.has(summary, "assets") ? summary.assets.superFunds : []
  const combinedIncome = [
    {
      incomeType: "PAYG Income",
      primary: hasIncome
        ? formatCurrency(
            calculateMonthlyPaygIncome(
              summary.income.client1PersonalIncome.currentEmployment
            )
          )
        : formatCurrency(0),
      secondary:
        hasIncome && hasSecondaryUser
          ? formatCurrency(
              calculateMonthlyPaygIncome(
                summary.income.client2PersonalIncome.currentEmployment
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Superannuation Salary Sacrifice",
      primary: formatCurrency(
        newDashboardValues.superSalarySacrificeClient1 / 12
      ),
      secondary: hasSecondaryUser
        ? formatCurrency(newDashboardValues.superSalarySacrificeClient2 / 12)
        : formatCurrency(0),
    },
    {
      incomeType: "Business Income",
      primary: hasIncome
        ? formatCurrency(
            caclulateMonthlyBusinessIncome(
              summary.income.client1PersonalIncome.currentEmployment
            )
          )
        : formatCurrency(0),
      secondary:
        hasIncome && hasSecondaryUser
          ? formatCurrency(
              caclulateMonthlyBusinessIncome(
                summary.income.client2PersonalIncome.currentEmployment
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Self Employed Income",
      primary: hasIncome
        ? formatCurrency(
            caclulateMonthlySelfEmployedIncome(
              summary.income.client1PersonalIncome.currentEmployment
            )
          )
        : formatCurrency(0),
      secondary:
        hasIncome && hasSecondaryUser
          ? formatCurrency(
              caclulateMonthlySelfEmployedIncome(
                summary.income.client2PersonalIncome.currentEmployment
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Other Income",
      primary: hasIncome
        ? formatCurrency(
            calculateMonthlyOtherIncome(
              summary.income.client1PersonalIncome.currentEmployment,
              summary.income.client1PersonalIncome.other
            )
          )
        : formatCurrency(0),
      secondary:
        hasIncome && hasSecondaryUser
          ? formatCurrency(
              calculateMonthlyOtherIncome(
                summary.income.client2PersonalIncome.currentEmployment,
                summary.income.client2PersonalIncome.other
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Rental Income",
      primary: formatCurrency(newDashboardValues.grossRentalIncomeClient1 / 12),
      secondary: hasSecondaryUser
        ? formatCurrency(newDashboardValues.grossRentalIncomeClient2 / 12)
        : formatCurrency(0),
    },
    {
      incomeType: "Investment Income",
      primary: hasAssets
        ? formatCurrency(
            calculateMonthlyInvestmentIncome(
              summary.personalInfo.client1._id,
              summary.assets.investments
            )
          )
        : formatCurrency(0),
      secondary:
        hasSecondaryUser && hasAssets && summary.personalInfo.client2 !== null
          ? formatCurrency(
              calculateMonthlyInvestmentIncome(
                summary.personalInfo.client2._id,
                summary.assets.investments
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Total Gross Income",
      primary: formatCurrency(newDashboardValues.totalGrossIncomeClient1 / 12),
      secondary: hasSecondaryUser
        ? formatCurrency(newDashboardValues.totalGrossIncomeClient2 / 12)
        : formatCurrency(0),
    },
    {
      incomeType: "Pre-Tax Deductions",
      primary: formatCurrency(newDashboardValues.preTaxDeductionsClient1 / 12),
      secondary: hasSecondaryUser
        ? formatCurrency(newDashboardValues.preTaxDeductionsClient2 / 12)
        : formatCurrency(0),
    },
    {
      incomeType: "Investment Property Deductions",
      primary: hasAssets
        ? formatCurrency(
            calculateMonthlyInvestmentPropertyDeductions(
              summary.personalInfo.client1._id,
              summary.assets.properties,
              borrowingValue
            )
          )
        : formatCurrency(0),
      secondary:
        hasSecondaryUser && hasAssets && summary.personalInfo.client2 !== null
          ? formatCurrency(
              calculateMonthlyInvestmentPropertyDeductions(
                summary.personalInfo.client2._id,
                summary.assets.properties,
                borrowingValue
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Other Tax Deductions",
      primary: formatCurrency(
        calculateMonthlyOtherTaxDeductions(
          summary.personalInfo.client1._id,
          summary.expenses.expenses
        )
      ),
      secondary:
        hasSecondaryUser && hasIncome && summary.personalInfo.client2 !== null
          ? formatCurrency(
              calculateMonthlyOtherTaxDeductions(
                summary.personalInfo.client2._id,
                summary.expenses.expenses
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Taxable Income",
      primary: formatCurrency(newDashboardValues.taxableIncomeClient1 / 12),
      secondary:
        hasSecondaryUser && hasIncome
          ? formatCurrency(newDashboardValues.taxableIncomeClient2 / 12)
          : formatCurrency(0),
    },
    {
      incomeType: "Estimated Tax Paid",
      primary: formatCurrency(newDashboardValues.estimatedTaxPaidClient1 / 12),
      secondary: hasSecondaryUser
        ? formatCurrency(newDashboardValues.estimatedTaxPaidClient2 / 12)
        : formatCurrency(0),
    },
    {
      incomeType: "Non-Taxable Income",
      primary: hasIncome
        ? formatCurrency(
            calculateMonthlyNonTaxableIncome(
              summary.income.client1PersonalIncome.other
            )
          )
        : formatCurrency(0),
      secondary:
        hasSecondaryUser && hasIncome
          ? formatCurrency(
              calculateMonthlyNonTaxableIncome(
                summary.income.client2PersonalIncome.other
              )
            )
          : formatCurrency(0),
    },
    {
      incomeType: "Total Net Income",
      primary: formatCurrency(newDashboardValues.totalNetIncomeClient1),
      secondary: hasSecondaryUser
        ? formatCurrency(newDashboardValues.totalNetIncomeClient2)
        : formatCurrency(0),
    },
  ]

  return combinedIncome
}

export const monthlyOutArray = summary => {
  const hasBorrowing = _.has(summary, "borrowings")
  const borrowingValue = hasBorrowing ? summary.borrowings.borrowing : []
  const hasAssets = _.has(summary, "assets")
  const propertyValue = hasAssets ? summary.assets.properties : []
  const investments = hasAssets ? summary.assets.investments : []
  const superFunds = hasAssets
    ? summary.assets.superFunds
    : [
        {
          value: 0,
          isSmsf: false,
          provider: "",
          salarySacrificeBool: false,
          salarySacrificeClient1: 0,
          salarySacrificeClient2: 0,
          personalContributionsBool: false,
          personalContributionsClient1: 0,
          personalContributionsClient2: 0,
        },
      ]

  const totalExpenses = [
    {
      expenseType: "L & L Jar",
      amount: formatCurrency(
        calculateMonthlyLivingLifestyle(
          summary.expenses.expenses,
          propertyValue
        )
      ),
    },
    {
      expenseType: "Credit Card Jar",
      amount: formatCurrency(
        calculateMonthlyCreditCard(summary.expenses.expenses, propertyValue)
      ),
    },
    {
      expenseType: "Direct Payments Jar",
      amount: formatCurrency(
        calculateMonthlyDirectPayments(
          summary.expenses.expenses,
          propertyValue,
          superFunds,
          investments
        )
      ),
    },
    {
      expenseType: "Loans Jar",
      amount: formatCurrency(calculateMonthlyLoans(borrowingValue)),
    },
    {
      expenseType: "Provisions Jar",
      amount: formatCurrency(
        calculateMonthlyProvisions(
          summary.expenses.expenses,
          propertyValue,
          0,
          false
        )
      ),
    },
    {
      expenseType: "Total",
      amount: formatCurrency(calculateMonthlyMoneyOut(summary, 0, false)),
    },
  ]
  return totalExpenses
}
