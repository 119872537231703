import React from "react"
import TotalNetIncomeGauge from "../total-net-income-gauge/TotalNetIncomeGauge"
import ExpensesGauge from "../Expenses-Gauge/ExpensesGauge"
import SurplusTargetGauge from "../Surplus-Gauge/SurplusTargetGauge"
import styles from "./GaugesSection.module.scss"

const GaugesSection = () => {
  return (
    <div className={styles.gaugesWrapper}>
      <div>
        <TotalNetIncomeGauge />
      </div>
      <div>
        <ExpensesGauge />
      </div>
      <div>
        <SurplusTargetGauge />
      </div>
    </div>
  )
}

export default GaugesSection
