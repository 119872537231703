import React, { useContext } from "react"
import { Doughnut } from "react-chartjs-2"
import {
  options,
  backgroundColorPrimary,
  backgroundColorSecondary,
} from "../../lib/gaugehelpers"

import { formatCurrency } from "../../lib/utils"
import AppDataContext from "../../context/AppDataContext"
import styles from "./TotalNetIncome.module.scss"
import clsx from "clsx"
import _ from "lodash"

const TotalNetIncomeGauge = () => {
  const {
    summary,
    isDesktop,
    userHasSecondary,
    isTabletSmall,
    newDashboardValues,
  } = useContext(AppDataContext)

  const hasIncome = _.has(summary, "income")
  const hasAssets = _.has(summary, "assets")
  const totalNetIncome = hasIncome
    ? newDashboardValues.totalNetIncomeClient1
    : 0
  const totalNetIncomeSecondary =
    userHasSecondary && hasIncome ? newDashboardValues.totalNetIncomeClient2 : 0

  const primaryTaxPaid = hasIncome
    ? newDashboardValues.estimatedTaxPaidClient1
    : 0

  const secondaryTaxPaid =
    userHasSecondary && hasIncome
      ? newDashboardValues.estimatedTaxPaidClient2
      : 0

  const primarySacrifice = hasAssets
    ? newDashboardValues.superSalarySacrificeClient1
    : 0

  const secondarySacrifice =
    hasAssets && userHasSecondary
      ? newDashboardValues.superSalarySacrificeClient2
      : 0

  const primaryName = summary.personalInfo.client1.fName
  const secondaryName =
    userHasSecondary && summary.personalInfo.client2 !== null
      ? summary.personalInfo.client2.fName
      : ""

  const personalInfoPrimary = [
    `${primaryName}'s Net Income`,
    `${primaryName}'s Tax`,
    `${primaryName}'s Sacrifice`,
  ]
  const personalInforSecondary = [
    `${secondaryName}'s Net Income`,
    `${secondaryName}'s Tax`,
    `${secondaryName}'s Sacrifice`,
  ]

  const doughnutData = {
    datasets: [
      {
        data: [
          Number(totalNetIncome.toFixed(2)),
          Number(primaryTaxPaid.toFixed(2)),
          Number(primarySacrifice.toFixed(2)),
        ],
        labels: personalInfoPrimary,
        backgroundColor: backgroundColorPrimary,
        fill: true,
        label: "two",
      },
      {
        data: [
          Number(totalNetIncomeSecondary.toFixed(2)),
          Number(secondaryTaxPaid.toFixed(2)),
          Number(secondarySacrifice.toFixed(2)),
        ],
        labels: personalInforSecondary,
        backgroundColor: backgroundColorSecondary,
        fill: true,
        label: "one",
      },
    ],
  }

  let doughtnutSize

  if (isDesktop && !isTabletSmall) {
    doughtnutSize = 300
  } else if (isTabletSmall && !isDesktop) {
    doughtnutSize = 200
  } else {
    doughtnutSize = 250
  }

  return (
    <div className={styles.doughtnutChartWrapper}>
      <div>
        <Doughnut
          data={doughnutData}
          options={options}
          height={doughtnutSize}
          width={doughtnutSize}
        />
      </div>
      <div className={styles.insideCircleText}>
        <p className={styles.totalIncomeText}>Total Net Income</p>
        <p className={clsx(styles.totalIncomeText, styles.amountText)}>
          {formatCurrency(newDashboardValues.totalNetIncome)}
        </p>
      </div>
    </div>
  )
}

export default TotalNetIncomeGauge
