import React from "react"
import BarsViewModel from "./ViewModels/BarsViewModel"
import Grid from "@material-ui/core/Grid"

export class Bars extends BarsViewModel {
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center">
            <div ref={node => (this.node = node)} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
