import React, { useContext } from "react"
import { Bar } from "react-chartjs-2"

import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema"
import { MonthlyBalanceItemSchema } from "../schemas/monthlyBalanceResponseSchema"
import {
  chartJsTooltipTitle,
  chartJsTooltipLabel,
  twelveMonthsLabel,
} from "../lib/utils"
import { calculateMonthlyMoneyOut } from "../lib/calculator"
import AppDataContext from "../context/AppDataContext"

import styles from "./TrackRegularSpending.module.scss"

const TrackRegularSpendingSection: React.FC<Props> = ({
  summary,
  monthlyBalanceItems,
  targetedMonthlySurplus,
  primaryAccountAmount,
  accumulatedExcessSurplus,
  monthlyActualSurplus,
  monthlyExcessSurplus,
  accumulatedSurplus,
  rollingTargetedSurplus,
}) => {
  const { newDashboardValues } = useContext(AppDataContext)
  const targetSurplus =
    newDashboardValues.totalNetIncome / 12 -
    newDashboardValues.totalExpenditure / 12

  const dateString =
    monthlyBalanceItems[0].month +
    monthlyBalanceItems[0].day +
    ", " +
    monthlyBalanceItems[0].year
  const startDate = new Date(dateString)
  const labels = twelveMonthsLabel(startDate)
  const shiftedLabels = [...labels]
  const shiftedMonthlyBalance = [...monthlyBalanceItems]
  const shiftedMonthlyTarget = shiftedMonthlyBalance.map(
    (_, i) => targetSurplus
  )
  const shiftTargetedMonthlySurplus = [...targetedMonthlySurplus]

  const shiftedActualMonthly = [...monthlyActualSurplus]
  const shiftedMonthlyOut = shiftedMonthlyBalance.map((_, i) =>
    calculateMonthlyMoneyOut(summary, i, true)
  )
  const shiftedMoneyOut = shiftedMonthlyBalance.map(
    () => calculateMonthlyMoneyOut(summary, 0, false) * -1
  )
  const shiftedAccumulatedSurplus = [...accumulatedSurplus]
  const shiftedRollingTarget = [...rollingTargetedSurplus]
  const shiftedAccumulatedExcess = [...accumulatedExcessSurplus]
  const shiftedCalculatedExcess = [...monthlyExcessSurplus]
  shiftedCalculatedExcess.shift()
  shiftedAccumulatedExcess.shift()
  shiftedMoneyOut.shift()
  shiftedMonthlyOut.shift()
  shiftedActualMonthly.shift()
  shiftedLabels.shift()
  shiftedMonthlyBalance.shift()
  shiftedMonthlyTarget.shift()
  shiftTargetedMonthlySurplus.shift()
  shiftedRollingTarget.shift()
  shiftedAccumulatedSurplus.shift()

  return (
    <section>
      <h2 className={styles.graphHeader}>Track Regular Spending</h2>
      <div className={styles.barChartWrapper}>
        <div className={styles.barChartContain}>
          <h3 className={styles.graphHeader}>Accumulated Actual Surplus</h3>
          <Bar
            height={250}
            data={{
              labels: shiftedLabels,
              datasets: [
                {
                  label: "Rolling targeted surplus",
                  type: "line",
                  data: shiftedRollingTarget,
                  borderColor: "rgba(68, 114, 196, 1)",
                  fill: false,
                  lineTension: 0,
                  pointBackgroundColor: "rgba(68, 114, 196, 1)",
                  pointHitRadius: 10,
                  pointHoverRadius: 7,
                  pointRadius: 5,
                },
                {
                  label: "Accumulated actual surplus",
                  type: "bar",
                  data: shiftedAccumulatedSurplus,
                  backgroundColor: "green",
                },
              ],
            }}
            options={{
              maintainAspectRatio: true,
              legend: { display: false },
              tooltips: {
                bodyFontSize: 16,
                callbacks: {
                  label: chartJsTooltipLabel,
                  title: chartJsTooltipTitle,
                },
              },
            }}
          />
        </div>

        <div className={styles.barChartContain}>
          <h3 className={styles.graphHeader}>Monthly Actual Surplus</h3>
          <Bar
            height={250}
            data={{
              labels: shiftedLabels,
              datasets: [
                {
                  label: "Targeted monthly surplus",
                  type: "line",
                  data: shiftTargetedMonthlySurplus,
                  borderColor: "rgba(0, 109, 14,1)",
                  fill: false,
                  lineTension: 0,
                  pointBackgroundColor: "rgba(0, 109, 14 ,1)",
                  pointHitRadius: 10,
                  pointHoverRadius: 7,
                  pointRadius: 5,
                  pointStyle: "line",
                },
                {
                  label: "Actual monthly surplus",
                  type: "bar",
                  data: shiftedActualMonthly,
                  backgroundColor: "green",
                },
              ],
            }}
            options={{
              maintainAspectRatio: true,
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
              legend: { display: false },
              tooltips: {
                bodyFontSize: 16,
                callbacks: {
                  // TODO: include the difference to target
                  label: chartJsTooltipLabel,
                  title: chartJsTooltipTitle,
                },
              },
            }}
          />
        </div>

        <div className={styles.barChartContain}>
          <h3 className={styles.graphHeader}>Monthly Excess Surplus/Deficit</h3>
          <Bar
            height={250}
            data={{
              labels: shiftedLabels,
              datasets: [
                {
                  label: "Savings flag",
                  type: "line",
                  data: shiftedMonthlyOut,
                  borderColor: "blue",
                  borderDash: [10, 5],
                  fill: false,
                  lineTension: 0,
                  pointHitRadius: 0,
                  pointHoverRadius: 1,
                  pointRadius: 0,
                },
                {
                  label: "Spending flag",
                  type: "line",
                  data: shiftedMoneyOut,
                  borderColor: "coral",
                  borderDash: [10, 5],
                  fill: false,
                  lineTension: 0,
                  pointHitRadius: 0,
                  pointHoverRadius: 1,
                  pointRadius: 0,
                },
                {
                  label: "Accumulated Excess Savings",
                  type: "line",
                  data: shiftedAccumulatedExcess,
                  borderColor: "green",
                  fill: false,
                  lineTension: 0,
                  pointBackgroundColor: "green",
                  pointHitRadius: 10,
                  pointHoverRadius: 7,
                  pointRadius: 5,
                },
                {
                  label: "Monthly Excess Surplus",
                  type: "bar",
                  data: shiftedCalculatedExcess,
                  backgroundColor: "red",
                },
              ],
            }}
            options={{
              maintainAspectRatio: true,
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
              legend: { display: false },
              tooltips: {
                bodyFontSize: 16,
                callbacks: {
                  label: chartJsTooltipLabel,
                  title: chartJsTooltipTitle,
                },
              },
            }}
          />
        </div>

        <div className={styles.barChartContain}>
          <h3 className={styles.graphHeader}>
            Primary Account Rolling Balance
          </h3>
          <Bar
            height={250}
            data={{
              labels,
              datasets: [
                {
                  label: "Monthly Balance",
                  data: primaryAccountAmount,
                  backgroundColor: "rgba(68, 114, 196, 1)",
                },
              ],
            }}
            options={{
              maintainAspectRatio: true,
              legend: { display: false },
              tooltips: {
                bodyFontSize: 16,
                callbacks: {
                  label: chartJsTooltipLabel,
                  title: chartJsTooltipTitle,
                },
              },
            }}
          />
        </div>
      </div>
    </section>
  )
}

interface Props {
  summary: ClientSummarySchema
  monthlyBalanceItems: MonthlyBalanceItemSchema[]
  targetedMonthlySurplus: Array<number>
  primaryAccountAmount: Array<number>
  accumulatedExcessSurplus: Array<number>
  monthlyActualSurplus: Array<number>
  monthlyExcessSurplus: Array<number>
  accumulatedSurplus: Array<number>
  rollingTargetedSurplus: Array<number>
}

export default TrackRegularSpendingSection
