import React, { useState, useContext } from "react"

import {
  formatCurrency,
  getLoansBorrowingItems,
  getMonthlyFrequencyMultiplier,
} from "../lib/utils"
import { calculateMonthlyLoans } from "../lib/calculator"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import SectionHeader from "./Section-Header/SectionHeader"
import styles from "../lib/jartables.module.scss"
import clsx from "clsx"
import AppDataContext from "../context/AppDataContext"
import _ from "lodash"

const LoansSection: React.FC = () => {
  const { summary, isEditable, userHasBorrowings } = useContext(AppDataContext)

  const monthlyLoans = userHasBorrowings
    ? calculateMonthlyLoans(summary.borrowings.borrowing)
    : 0

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const [addTableHeight, setAddTableHeight] = useState(false)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const prevHeightSpring = useSpring({
    height: addTableHeight ? prevHeight : 0,
    overflow: "hidden",
    config: {
      duration: 300,
    },
  })

  const totalLoansJar = formatCurrency(monthlyLoans)

  return (
    <section>
      <SectionHeader
        title="Loans Jar"
        rotate={addTableHeight}
        onClickHandler={setTableHeightHanlder}
        showSubheader={true}
        centerSubHeaderTitle={null}
        centerSubHeaderAmount={null}
        monthlyOutAmount={totalLoansJar}
        monthlyInAmount={totalLoansJar}
        textTitle={null}
        greyedOut={!isEditable}
      />
      <a.div style={prevHeightSpring}>
        <div className={clsx(styles.tableLayOut)} ref={mainRef}>
          <div>
            <table className={clsx(styles.incomeTable, styles.wideTable)}>
              <tbody>
                <tr className={styles.tableRow}>
                  <td className={clsx(styles.tableHeader, styles.textleft)}>
                    Transfer from Primary Account
                  </td>
                  <td>{totalLoansJar}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className={clsx(styles.incomeTable)}>
              <tbody>
                {userHasBorrowings
                  ? getLoansBorrowingItems(summary.borrowings.borrowing)
                      .sort((a, b) => (b.repayment || 0) - (a.repayment || 0))
                      .filter(borrowing => borrowing.isClosed !== true)
                      .map((borrowingItem, i) => {
                        let borrowingRepayment = _.get(
                          borrowingItem,
                          "repayment",
                          0
                        )
                        let borrowingFreq = _.get(
                          borrowingItem,
                          "repaymentFreq",
                          "Monthly"
                        )

                        if (!borrowingFreq) {
                          borrowingFreq = "Monthly"
                        }

                        if (!borrowingRepayment) {
                          borrowingRepayment = 0
                        }
                        let monthlyTotal =
                          borrowingRepayment *
                          getMonthlyFrequencyMultiplier(borrowingFreq)
                        return (
                          <tr
                            className={styles.tableRow}
                            key={`borrowing-expense-${i}`}
                          >
                            <td>{borrowingItem.type}</td>
                            <td>{formatCurrency(monthlyTotal)}</td>
                          </tr>
                        )
                      })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </a.div>
    </section>
  )
}

export default LoansSection
