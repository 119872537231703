import React, { useState, useContext } from "react"

import {
  formatCurrency,
  getCreditCardExpenses,
  getMonthlyFrequencyMultiplier,
} from "../lib/utils"
import { calculateMonthlyCreditCard } from "../lib/calculator"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import SectionHeader from "./Section-Header/SectionHeader"
import styles from "../lib/jartables.module.scss"
import clsx from "clsx"
import AppDataContext from "../context/AppDataContext"
import _ from "lodash"

const CreditCardSection: React.FC = () => {
  const { summary, isEditable } = useContext(AppDataContext)
  const hasAssets = _.has(summary, "assets")
  const propertyValue = hasAssets ? summary.assets.properties : []
  const monthlyCreditCard = calculateMonthlyCreditCard(
    summary.expenses.expenses,
    propertyValue
  )

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const [addTableHeight, setAddTableHeight] = useState(false)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const prevHeightSpring = useSpring({
    height: addTableHeight ? prevHeight : 0,
    padding: addTableHeight ? `0 0.5em` : `0 0`,
    overflow: "hidden",
    config: {
      duration: 300,
    },
  })

  const totalCreditCardExpense = formatCurrency(monthlyCreditCard)

  return (
    <section className={styles.sectionContainer}>
      <SectionHeader
        title="Credit Card Jar"
        rotate={addTableHeight}
        onClickHandler={setTableHeightHanlder}
        showSubheader={true}
        monthlyInAmount={totalCreditCardExpense}
        monthlyOutAmount={totalCreditCardExpense}
        textTitle={`(Paid Monthly)`}
        centerSubHeaderAmount={null}
        centerSubHeaderTitle={null}
        greyedOut={!isEditable}
      />
      <a.div style={prevHeightSpring}>
        <div className={clsx(styles.tableLayOut)} ref={mainRef}>
          <div>
            <table className={clsx(styles.incomeTable, styles.wideTable)}>
              <tbody>
                <tr className={styles.tableRow}>
                  <td
                    className={clsx(styles.tableHeader, styles.textleft)}
                    colSpan={2}
                  >
                    Estimated transfer from Primary Account
                  </td>
                  <td>{formatCurrency(monthlyCreditCard)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className={clsx(styles.incomeTable)}>
              <tbody>
                {getCreditCardExpenses(summary.expenses.expenses, propertyValue)
                  .map(expense => ({
                    ...expense,
                    amount: expense.frequency
                      ? expense.amount *
                        getMonthlyFrequencyMultiplier(expense.frequency)
                      : 0,
                  }))
                  .filter(expense => {
                    return expense.amount !== 0 && expense.frequency !== null
                  })
                  .sort((a, b) => b.amount - a.amount)
                  .map((expense, i) => (
                    <tr
                      className={styles.tableRow}
                      key={`credit-card-expense-${i}`}
                    >
                      <td>{expense.desc}</td>
                      <td>{formatCurrency(expense.amount)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </a.div>
    </section>
  )
}

export default CreditCardSection
