import Cookies from "js-cookie"
import Ajv from "ajv"

import clientSummaryResponseSchemaJson from "../schemas/clientSummaryResponseSchema.json"
import monthlyBalanceResponseSchemaJson from "../schemas/monthlyBalanceResponseSchema.json"
import provisioningResponseSchemaJson from "../schemas/provisioningResponseSchema.json"

import {
  ClientSummaryResponseSchema,
  ClientSummarySchema,
} from "../schemas/clientSummaryResponseSchema"
import { MonthlyBalanceResponseSchema } from "../schemas/monthlyBalanceResponseSchema"
import { ProvisioningResponseSchema } from "../schemas/provisioningResponseSchema"
import _ from "lodash"
let clientID // eslint-disable-line

export const setClientId = (id: string | null) => {
  if (!id) {
    clientID = process.env.REACT_APP_CLIENT_USER_ID
  } else {
    clientID = id
  }
}

const ajv = new Ajv({
  allErrors: true,
  removeAdditional: "all",
  schemas: [
    clientSummaryResponseSchemaJson,
    monthlyBalanceResponseSchemaJson,
    provisioningResponseSchemaJson,
  ],
})

const validateMonthlyBalanceResponse = ajv.getSchema(
  "monthlyBalanceResponseSchema.json"
)
const validateProvisioningResponse = ajv.getSchema(
  "provisioningResponseSchema.json"
)

export type ServiceResponse<T> =
  | { kind: "LOADING"; value: undefined | null }
  | { kind: "SUCCESS"; value: any }
  | { kind: "ERROR"; errors: Ajv.ErrorObject[] | string; value: null }

export type ServiceResponseSummary<T> =
  | { kind: "LOADING"; value: undefined | null; isTPCPlatform?: boolean }
  | { kind: "SUCCESS"; value: any; isTPCPlatform?: boolean }
  | {
      kind: "ERROR"
      errors: Ajv.ErrorObject[] | string
      value: null
      isTPCPlatform?: boolean
    }

export const fetchSummary = (): Promise<ServiceResponseSummary<
  ClientSummarySchema
>> =>
  _fetch(false, `client/getMSSumarry`)
    .then(res => res.json())
    .then(summaryResponse => {
      const isTPCPlatform = summaryResponse.isTPCPlatform

      const summary = {
        ...summaryResponse.clientSummary,
        expenses: {
          ...summaryResponse.clientSummary.expenses,
          expenses: summaryResponse.clientSummary.expenses.expenses.filter(
            // This frequency is not used anymore, remove entries with it
            expenseItem => expenseItem.frequency !== "Every 2 years"
          ),
        },
      }

      summary.expenses.expenses
        .filter(expense => expense.taxDeductible)
        .map(expense => {
          if (!_.has(expense, "category")) {
            expense.category = "Paid by Credit"
          }
          return expense
        })

      return { kind: "SUCCESS" as const, value: summary, isTPCPlatform }
    })
    .catch(error => ({ kind: "ERROR" as const, errors: error, value: null }))

export const fetchMonthlyBalance = isUserTPC =>
  _fetch(isUserTPC, "client/monthlyBalance")
    .then(res => res.json())
    .then(monthlyBalance => {
      const initialMonthlyBalance = {
        startData: {
          day: "1",
          month: "0",
          year: new Date().getFullYear().toString(),
        },
        monthlyBalance: [
          {
            items: [
              {
                day: "1",
                year: new Date().getFullYear().toString(),
                month: "January",
                creditCardBalance: 0,
                amount: 0,
              },
            ],
          },
        ],
      }

      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]

      let monthsNumber = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
      ]

      if (!_.has(monthlyBalance, "startData") && !_.isEmpty(monthlyBalance)) {
        monthlyBalance["monthlyBalance"] =
          typeof monthlyBalance["monthlyBalance"] === "undefined"
            ? []
            : monthlyBalance["monthlyBalance"]

        monthlyBalance.monthlyBalance[0].items.map(item => {
          item.day = "1"
          return item
        })

        let monthsInIndex = months.indexOf(
          monthlyBalance.monthlyBalance[0].items[0].month
        )
        let monthsInNumberIndex = monthsNumber.indexOf(
          monthlyBalance.monthlyBalance[0].items[0].month
        )
        let monthsInNumbers
        if (monthsInIndex === -1 && monthsInNumberIndex !== -1) {
          monthsInNumbers = monthsInNumberIndex
        } else {
          monthsInNumbers = monthsInIndex
        }

        let dayStartData = _.get(
          monthlyBalance.monthlyBalance[0].items[0],
          "day",
          "1"
        )

        let newstartData = {
          day: dayStartData,
          month: monthsInNumbers,
          year: monthlyBalance.monthlyBalance[0].items[0].year,
          creditCardBalance: 0,
          amount: 0,
        }

        monthlyBalance.monthlyBalance[0].items.map(item => {
          const findErrData = monthsNumber.find(num => num === item.month)
          if (findErrData !== undefined) {
            item.month = months[Number(item.month)]
          }

          if (!_.has(item, "creditCardBalance")) {
            item.creditCardBalance = 0
          }

          return item
        })

        return {
          kind: "SUCCESS" as const,
          value: { ...monthlyBalance, startData: newstartData },
        }
      } else if (
        !_.has(monthlyBalance, "startData") &&
        _.isEmpty(monthlyBalance)
      ) {
        return { kind: "SUCCESS" as const, value: initialMonthlyBalance }
      } else {
        monthlyBalance.monthlyBalance[0].items.map(item => {
          item.day = "1"
          return item
        })
        return { kind: "SUCCESS" as const, value: monthlyBalance }
      }
    })
    .catch(() => ({
      kind: "ERROR" as const,
      errors: "Server error",
      value: null,
    }))

export const fetchProvisioning = (
  isTPCUser
): Promise<ServiceResponse<ProvisioningResponseSchema>> => {
  return _fetch(isTPCUser, "client/provisioning")
    .then(res => res.json())
    .then((provisioning: ProvisioningResponseSchema) => {
      const isValid = validateProvisioningResponse(provisioning)

      if (isValid) {
        return { kind: "SUCCESS" as const, value: provisioning }
      } else {
        const errors = validateProvisioningResponse.errors as Ajv.ErrorObject[]
        return { kind: "ERROR" as const, errors, value: null }
      }
    })
    .catch(() => ({
      kind: "ERROR" as const,
      errors: "Server error",
      value: null,
    }))
}

export const fetchMonthlyBalanceUpdate = (
  isUserTPC,
  monthlyBalance: MonthlyBalanceResponseSchema
) =>
  _fetch(isUserTPC, "client/monthlyBalance", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(monthlyBalance),
  })
    .then(res => ({ error: res.ok ? null : { message: "Server error" } }))
    .catch(() => ({ error: { message: "Server error" } }))

export const fetchProvisioningUpdate = (
  provisioning: ProvisioningResponseSchema,
  isTPCUser
) =>
  _fetch(isTPCUser, "client/provisioning", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(provisioning),
  })
    .then(res => ({ error: res.ok ? null : { message: "Server error" } }))
    .catch(() => ({ error: { message: "Server error" } }))

export const rollOver = async (
  isUserTPC: boolean,
  id: string,
  provisioning: ProvisioningResponseSchema,
  monthlyBalance: MonthlyBalanceResponseSchema,
  summary: ClientSummaryResponseSchema
) =>
  _fetch(isUserTPC, "client/rollover", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id, monthlyBalance, provisioning, summary }),
  }).then(res => res.json())

export const getRollOver = async (isUserTPC, id: string) =>
  _fetch(isUserTPC, "client/getRollOver", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id }),
  }).then(res => res.json())

export const clientExpenses = (isUserTPC, expenses) => {
  _fetch(isUserTPC, "client/billPayments", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ expenses }),
  })
}

export const fetchMoneyStretch = () =>
  _fetch(false, `client/getMoneyStretch`)
    .then(res => res.json())
    .then(moneystretchResponse => {
      return { kind: "SUCCESS" as const, value: moneystretchResponse }
    })
    .catch(error => ({ kind: "ERROR" as const, errors: error, value: null }))

export const addMoneyStretch = form =>
  _fetch(false, "client/addMoneyStretch", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(form),
  }).then(res => res.json())

export const publishStretchChanges = form =>
  _fetch(false, "client/publishStretchChanges", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(form),
  }).then(res => res.json())

// ============================================================================
// Helpers
// ============================================================================

// Types copied from the fetch() type annotation

const _fetch = (isTPCUser: boolean, url: string, init: RequestInit = {}) => {
  const apiEndPoint = `https://${window.location.hostname}/api/`

  const token = Cookies.get("token")
  return fetch(apiEndPoint + url, {
    ...init,
    referrer: window.location.href, // test server
    headers: {
      ...init.headers,
      originreferer: window.location.href, // temp dev
      Authorization: `Bearer ${token}`,
    },
  })
}

//standalone add clientID, leave as it is in integration
