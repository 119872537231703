import React from "react"

import {
  ExpenseItemSchema,
  FrequencySchema,
} from "./../../schemas/clientSummaryResponseSchema"
import ReactDOM from "react-dom"

import { rolloverInput, rolloverSelect } from "./Rollover.interface"
const isNotEmpty = item => item !== null

export const getLivingLifestyleExpenses = (expenses: ExpenseItemSchema[]) =>
  expenses
    .filter(expense => expense.category === "7 Days Float")
    .filter(expenseItem => {
      const isNotEmptyAmount = isNotEmpty(expenseItem.amount)
      const isNotEmptyFrequency = isNotEmpty(expenseItem.frequency)

      return isNotEmptyFrequency && isNotEmptyAmount
    })
export const getCreditCardExpenses = (expenses: ExpenseItemSchema[]) =>
  expenses
    .filter(expense => expense.category === "Paid by Credit")
    .filter(expenseItem => {
      const isNotEmptyAmount = isNotEmpty(expenseItem.amount)
      const isNotEmptyFrequency = isNotEmpty(expenseItem.frequency)

      return isNotEmptyFrequency && isNotEmptyAmount
    })
export const getDirectPaymentsExpenses = (expenses: ExpenseItemSchema[]) =>
  expenses
    .filter(expense => expense.category === "Direct from Primary")
    .filter(expenseItem => {
      const isNotEmptyAmount = isNotEmpty(expenseItem.amount)
      const isNotEmptyFrequency = isNotEmpty(expenseItem.frequency)

      return isNotEmptyFrequency && isNotEmptyAmount
    })
export const getProvisionsExpenses = (expenses: ExpenseItemSchema[]) =>
  expenses
    .filter(expense => expense.category === "Provisioning")
    .filter(expenseItem => {
      const isNotEmptyAmount = isNotEmpty(expenseItem.amount)
      const isNotEmptyFrequency = isNotEmpty(expenseItem.frequency)

      return isNotEmptyFrequency && isNotEmptyAmount
    })
export const getUnallocatedExpense = (expenses: ExpenseItemSchema[]) =>
  expenses.filter(
    expense =>
      expense.category &&
      [
        "",
        "Provisioning",
        "7 Days Float",
        "Paid by Credit",
        "Direct from Primary",
      ].includes(expense.category)
  )

export const getMonthlyFrequencyMultiplier = (
  frequency: FrequencySchema
): number => {
  switch (frequency) {
    case "Weekly":
      return 52 / 12
    case "Fortnightly":
      return 26 / 12
    case "Monthly":
      return 1
    case "Every 2 months":
      return 1 / 2
    case "Every 3 months":
      return 1 / 3
    case "Every 6 months":
      return 1 / 6
    case "Yearly":
      return 1 / 12
    case "Every 2 years":
      return 1 / 24
  }
}

export const RenderInputCurrency: React.FC<rolloverInput> = ({
  expenseItem,
  id,
  disabled,
  val,
  onChange,
  custom,
}) => {
  const expenseId = custom ? expenseItem.id : expenseItem._id
  return (
    <input
      onChange={event => onChange(event)}
      type={custom ? "text" : "number"}
      id={expenseId}
      name={id}
      placeholder={custom ? null : "$"}
      value={val}
      disabled={disabled}
    />
  )
}

export const RenderInputSelect: React.FC<rolloverSelect> = ({
  id,
  options,
  onChange,
}) => (
  <select id={id} name={id} onChange={event => onChange(event)}>
    (
    {options.map((item, index) => {
      return (
        <option
          key={`select-conditions-${index}`}
          selected={item.selectedCondition}
          value={item.value}
        >
          {item.label}
        </option>
      )
    })}
  </select>
)

export class Portal extends React.Component {
  constructor(props) {
    super(props)
    const myNode = document.getElementById("privacy")
    while (myNode.firstChild) {
      myNode.removeChild(myNode.firstChild)
    }
  }
  render() {
    return ReactDOM.createPortal(
      <div>
        <button onClick={this.handleClick}>Click me</button>
      </div>,
      document.getElementById("privacy")
    )
  }

  handleClick() {
    alert("clicked")
  }
}
