exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes _35uc7LRbCLnErSAz4kfBAC {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes _35uc7LRbCLnErSAz4kfBAC {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n._3o_QaOa4OuLl_EB0dio_ad {\n  display: grid;\n  grid-gap: 1em; }\n\n@media (min-width: 1280px) {\n  ._3o_QaOa4OuLl_EB0dio_ad {\n    grid-template-columns: 1fr 1fr;\n    padding: 0 4em; }\n  ._3Ys-w3Z2no0joTvVheSuvS {\n    min-height: 10em; }\n  ._1A08pI745ul4aVkvTB0uCJ {\n    font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n    text-align: center; } }\n", ""]);
// Exports
exports.locals = {
	"barChartWrapper": "_3o_QaOa4OuLl_EB0dio_ad",
	"barChartContain": "_3Ys-w3Z2no0joTvVheSuvS",
	"graphHeader": "_1A08pI745ul4aVkvTB0uCJ",
	"fadeInDown": "_35uc7LRbCLnErSAz4kfBAC"
};