import React, { useContext } from "react"
import {
  formatCurrency,
  getCreditCardExpenses,
  getMonthlyFrequencyMultiplier,
  getLivingLifestyleExpenses,
  getDirectPaymentsExpenses,
  getLoansBorrowingItems,
  getProvisionsExpenses,
} from "../../lib/utils"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"
import AppDataContext from "../../context/AppDataContext"
import styles from "./MoneyOutExpenses.module.scss"
import useMeasure from "react-use-measure"
import clsx from "clsx"
import _ from "lodash"

const MoneyOutExpenses = ({ moneyOutOnly }) => {
  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const prevHeightSpring = useSpring({
    height: moneyOutOnly ? prevHeight : 0,
    config: {
      duration: 400,
    },
  })
  const { summary, userHasBorrowings } = useContext(AppDataContext)
  const hasAssets = _.has(summary, "assets")
  const superFunds = hasAssets
    ? summary.assets.superFunds
    : [
        {
          value: 0,
          isSmsf: false,
          provider: "",
          salarySacrificeBool: false,
          salarySacrificeClient1: 0,
          salarySacrificeClient2: 0,
          personalContributionsBool: false,
          personalContributionsClient1: 0,
          personalContributionsClient2: 0,
        },
      ]

  const propertyAssets = hasAssets ? summary.assets.properties : []
  const investments = hasAssets ? summary.assets.investments : []

  return (
    <a.div
      style={prevHeightSpring}
      className={clsx(!moneyOutOnly && styles.tableWrapperDiv)}
    >
      <table className={styles.incomeTable} ref={mainRef}>
        <tbody>
          {getCreditCardExpenses(summary.expenses.expenses, propertyAssets)
            .map(expense => ({
              ...expense,
              amount: expense.frequency
                ? expense.amount *
                  getMonthlyFrequencyMultiplier(expense.frequency)
                : 0,
            }))
            .sort((a, b) => b.amount - a.amount)
            .filter(expense => {
              return expense.amount !== 0 && expense.frequency !== null
            })
            .map((expense, i) => (
              <tr className={styles.tableRow} key={`credit-card-expenses-${i}`}>
                <td className={styles.incomeRow}>{expense.desc}</td>
                <td>{formatCurrency(expense.amount)}</td>
              </tr>
            ))}
          {getLivingLifestyleExpenses(summary.expenses.expenses, propertyAssets)
            .map(expense => ({
              ...expense,
              amount: expense.frequency
                ? expense.amount *
                  getMonthlyFrequencyMultiplier(expense.frequency)
                : 0,
            }))
            .sort((a, b) => b.amount - a.amount)
            .filter(expense => {
              return expense.amount !== 0 && expense.frequency !== null
            })
            .map((expense, i) => (
              <tr className={styles.tableRow} key={`living-expense-${i}`}>
                <td className={styles.incomeRow}>{expense.desc}</td>
                <td>{formatCurrency(expense.amount)}</td>
              </tr>
            ))}
          {getDirectPaymentsExpenses(
            summary.expenses.expenses,
            propertyAssets,
            superFunds,
            investments
          )
            .map(expense => ({
              ...expense,
              amount: expense.frequency
                ? expense.amount *
                  getMonthlyFrequencyMultiplier(expense.frequency)
                : 0,
            }))
            .filter(expense => {
              return expense.amount !== 0 && expense.frequency !== null
            })
            .sort((a, b) => b.amount - a.amount)
            .map((expense, i) => (
              <tr className={styles.tableRow} key={`direct-payment-${i}`}>
                <td className={styles.incomeRow}>{expense.desc}</td>
                <td>{formatCurrency(expense.amount)}</td>
              </tr>
            ))}
          {getProvisionsExpenses(summary.expenses.expenses, propertyAssets)
            .map(expense => ({
              ...expense,
              amount: expense.frequency
                ? expense.amount *
                  getMonthlyFrequencyMultiplier(expense.frequency)
                : 0,
            }))
            .sort((a, b) => b.amount - a.amount)
            .filter(expense => {
              return expense.amount !== 0 && expense.frequency !== null
            })
            .map((provisionExpense, i) => {
              return (
                <tr className={styles.tableRow} key={`provision-expense-${i}`}>
                  <td className={styles.incomeRow}>{provisionExpense.desc}</td>
                  <td>{formatCurrency(provisionExpense.amount)}</td>
                </tr>
              )
            })}
          {userHasBorrowings
            ? getLoansBorrowingItems(summary.borrowings.borrowing)
                .sort((a, b) => (b.repayment || 0) - (a.repayment || 0))
                .filter(borrowing => !borrowing?.isClosed)
                .map((borrowingItem, i) => {
                  const monthlyTotal =
                    borrowingItem.repayment *
                    getMonthlyFrequencyMultiplier(
                      borrowingItem.repaymentFreq || "Monthly"
                    )
                  return (
                    <tr
                      className={styles.tableRow}
                      key={`borrowing-expense-${i}`}
                    >
                      <td className={styles.incomeRow}>{borrowingItem.type}</td>
                      <td>{formatCurrency(monthlyTotal || 0)}</td>
                    </tr>
                  )
                })
            : null}
        </tbody>
      </table>
    </a.div>
  )
}

export default MoneyOutExpenses
