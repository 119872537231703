import React from "react"
import CircleButton from "../Circle-Button/CircleButton"
import styles from "./SectionHeader.module.scss"
import clsx from "clsx"

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  rotate,
  onClickHandler,
  showSubheader,
  centerSubHeaderTitle,
  centerSubHeaderAmount,
  monthlyInAmount,
  monthlyOutAmount,
  textTitle,
  greyedOut,
}) => {
  return (
    <>
      <div className={clsx(styles.headerDiv, greyedOut && styles.greyedOut)}>
        <div className={styles.headerLayout}>
          <h1 className={styles.primaryAccountHeader}>{title}</h1>
          <div
            onClick={() => onClickHandler()}
            className={styles.circleBtnWrapper}
          >
            <CircleButton
              type="upwards"
              rotate={rotate}
              greyedOut={greyedOut}
            />
          </div>
        </div>
        {showSubheader ? (
          <div className={styles.subHeaderDiv}>
            <div className={styles.extraDetailsSubHeader}>
              {monthlyInAmount ? (
                <>
                  <p>Monthly IN</p>
                  <p>{monthlyInAmount}</p>
                </>
              ) : null}
            </div>
            {centerSubHeaderAmount && centerSubHeaderTitle && !textTitle ? (
              <div
                className={clsx(styles.extraDetailsSubHeader, styles.center)}
              >
                <p>{centerSubHeaderTitle}</p>
                <p>{centerSubHeaderAmount}</p>
              </div>
            ) : (
              <div className={styles.extraDetailsSubHeader}>
                <p>{textTitle}</p>
              </div>
            )}

            <div className={styles.extraDetailsSubHeader}>
              {monthlyOutAmount ? (
                <>
                  <p>Monthly Out</p>
                  <p>{monthlyOutAmount}</p>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

interface SectionHeaderProps {
  title: String
  rotate: boolean
  onClickHandler: Function
  showSubheader: boolean
  centerSubHeaderTitle: String | null
  monthlyInAmount: String | null
  monthlyOutAmount: String | null
  centerSubHeaderAmount: String | null
  textTitle: String | null
  greyedOut?: boolean
}

export default SectionHeader
