import React, { useState, useContext } from "react"
import { ClientSummarySchema } from "../../schemas/clientSummaryResponseSchema"
import { formatCurrency } from "../../lib/utils"
import { calculateMonthlyMoneyOut } from "../../lib/calculator"
import { monthlyOutArray } from "./PrimaryAccountHelpers"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import styles from "./PrimaryAccount.module.scss"
import clsx from "clsx"
import SectionHeader from "../Section-Header/SectionHeader"
import AppDataContext from "../../context/AppDataContext"
import MonthlyInTable from "./MonthlyInTable"

const PrimaryAccountSection: React.FC = () => {
  const { summary, isEditable, isDesktop, newDashboardValues } = useContext(
    AppDataContext
  )

  const [addTableHeight, setAddTableHeight] = useState(false)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const height = addTableHeight ? prevHeight : 0
  let prevHeightSpring = useSpring({
    height: height,
    config: {
      duration: 400,
    },
  })

  const totalMonthlySurplus = formatCurrency(
    newDashboardValues.totalNetIncome / 12 -
      calculateMonthlyMoneyOut(summary, 0, false)
  )

  return (
    <section className={styles.primaryAccountSection}>
      <SectionHeader
        onClickHandler={setTableHeightHanlder}
        rotate={addTableHeight}
        title="PRIMARY ACCOUNT - SAVINGS"
        showSubheader={true}
        centerSubHeaderTitle="Total Monthly Surplus"
        centerSubHeaderAmount={totalMonthlySurplus}
        monthlyInAmount={null}
        monthlyOutAmount={null}
        textTitle={null}
        greyedOut={!isEditable}
      />
      <a.div style={prevHeightSpring} className={styles.tableWrapperDiv}>
        <div ref={mainRef}>
          <div className={styles.tablesWrapper}>
            <div>
              <MonthlyInTable
                summary={summary}
                moneyInOnly={false}
                wide={true}
                isDesktop={isDesktop}
                newDashboardValues={newDashboardValues}
              />
            </div>

            <div>
              <MonthlyOutTable summary={summary} />
            </div>
          </div>

          <div>
            <table className={styles.surplusTable}>
              <tbody>
                <tr className={styles.tableRow}>
                  <th>Monthly Surplus</th>
                </tr>
                <tr className={styles.tableRow}>
                  <th>{totalMonthlySurplus}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a.div>
    </section>
  )
}

// ============================================================================
// MonthlyOutTable
// ============================================================================

const MonthlyOutTable: React.FC<MonthlyOutTableProps> = ({ summary }) => {
  const monthlyOutTableData = monthlyOutArray(summary)
  return (
    <table className={styles.incomeTable}>
      <tbody>
        <tr className={styles.tableRow}>
          <th colSpan={2} className={styles.tableLabel}>
            Monthly Out
          </th>
        </tr>
        {monthlyOutTableData.map((moneyOut, index) => {
          return (
            <tr key={`monthly-table-data-${index}`} className={styles.tableRow}>
              <td className={styles.incomeRow}>{moneyOut.expenseType}</td>
              <td>{moneyOut.amount}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

interface MonthlyOutTableProps {
  summary: ClientSummarySchema
}

export default PrimaryAccountSection
