import React from "react"
import {
  withStyles,
  StyleRules,
  WithStyles,
  Theme,
} from "@material-ui/core/styles"
import {
  Grid,
  Paper,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  CircularProgress,
} from "@material-ui/core"
import _ from "lodash"
import { Bars } from "./Bars"
import { Helpers } from "./Helpers"
import FamilyCard from "./FamilyCard"
import moment from "moment"
import RSelect from "react-virtualized-select"
import "react-select/dist/react-select.css"
import "react-virtualized/styles.css"
import "react-virtualized-select/styles.css"
const TotalExpenseItemGraph: React.FC<Props> = ({
  classes,
  moneyFitPromise,
  clientSummary,
  countTotal,
  totalExpense,
}) => {
  const [data, setData] = React.useState([])
  const [age, setAge] = React.useState("")
  const [ageBounds, setAgeBounds] = React.useState("All")
  const [location, setLocation] = React.useState()
  const [stateSelect, setStateSelect] = React.useState("All")
  const [total, setTotal] = React.useState(0)
  const [ageFilter, setAgeFilter] = React.useState([])
  const [stateFilter, setStateFilter] = React.useState([])

  const [expItemSelect, setExpItemSelect] = React.useState("All")
  const [expItemFilter, setExpItemFilter] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(true)

  const [expenseItemAmount, setExpenseItemAmount] = React.useState(0)
  let personalInfo = _.get(clientSummary, "personalInfo", {})

  React.useEffect(() => {
    let filter = {
      filters: {} as any,
      measure: "expenseAmt",
    }
    if (ageFilter.length > 0) {
      filter.filters.age1 = [ageFilter[0]]
      if (ageFilter.length > 1) {
        filter.filters.age1$ = [ageFilter[1]]
      }
    }
    if (stateFilter.length > 0) {
      filter.filters.state1 = stateFilter
    }

    if (expItemFilter.length > 0) {
      filter.filters.expenseDesc = expItemFilter
    } else {
      setIsLoading(false)
      return
    }
    filter.filters.expenseAmt = [
      { operator: "<", isNumber: "1", value: "300000" },
    ]
    filter.filters.expenseAmt$ = [{ operator: ">", isNumber: "1", value: "0" }]
    filter.filters.household = getHouseholdCompFilter(clientSummary)
    filter.filters.dependants = getDependantsFilter(clientSummary)

    let expenses = _.get(clientSummary, "expenses.expenses", [])
    let expenseIndex = _.findIndex(expenses, [
      "desc",
      Helpers.getDisplayString(expItemSelect),
    ])
    let expensesItemAmt = getAnnualAmount(expenses[expenseIndex])
    setIsLoading(true)
    moneyFitPromise(JSON.stringify(filter)).then(res => {
      setTotal(countTotal(res))
      setData(res)
      setIsLoading(false)
      setExpenseItemAmount(expensesItemAmt)
    })
  }, [ageFilter, stateFilter, expItemFilter, expenseItemAmount])

  if (!age) {
    let clientDob = _.get(clientSummary, "personalInfo.client1.dob", "NA")
    let clientAge = "NA"
    if (clientDob === "NA" || _.isNil(clientDob)) {
      setAge("NA")
      setAgeBounds("All")
    } else {
      clientAge = moment()
        .diff(moment(clientDob), "years")
        .toString()
      setAge(clientAge)
      if (parseInt(clientAge) > 20) {
        setAgeBounds(Helpers.getAgeRange(clientAge))
      } else {
        setAgeBounds("All")
      }
    }
    let filter = getAgeFilter(Helpers.getAgeRange(clientAge))
    setAgeFilter(filter)
  }

  if (!location) {
    let clientState = _.get(
      clientSummary,
      "personalInfo.client1.address.state",
      "NA"
    )
    let ifNoClientState = clientState === null ? "NA" : clientState
    setLocation(ifNoClientState)
  }

  const newDefaultExpenses = defaultExpenses.sort((a, b) =>
    a.label > b.label ? 1 : -1
  )
  const searchSelectorStyle = {
    control: base => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
    }),
    width: "300",
  }

  return (
    <Paper className={classes.paperContainer}>
      {isLoading && (
        <div className={classes.mainLoaderContainer}>
          <CircularProgress color="primary" size={50} />
        </div>
      )}

      <Grid container justify="center" alignItems="flex-start">
        <Grid item alignContent="flex-end">
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            className={classes.subHeader}
          >
            Your Household Expense Item vs
          </Typography>
          <Grid container justify="center">
            <Grid item className={classes.spacing} alignContent="center">
              <Card className={classes.paper}>
                <FamilyCard personalInfo={personalInfo}></FamilyCard>
                <Typography variant="h5" align="center">
                  {Helpers.getAgeRange(age) + " (" + location + ")"}
                </Typography>
              </Card>
            </Grid>
            <Grid item alignItems="center" className={classes.spacing}>
              <Paper className={classes.paper} elevation={3}>
                <div>
                  <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    className={classes.heading}
                  >
                    How do you compare?
                  </Typography>
                  <Grid item md={12}>
                    <Grid>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          className={classes.select}
                          value={ageBounds}
                          inputProps={{
                            name: "status",
                            id: "select-status",
                          }}
                          onChange={e => {
                            setAgeBounds(String(e.target.value))
                            let filter = getAgeFilter(String(e.target.value))
                            setAgeFilter(filter)
                          }}
                        >
                          <MenuItem value={"All"}>All Ages</MenuItem>
                          <MenuItem value={"21-25"}>21-25</MenuItem>
                          <MenuItem value={"26-30"}>26-30</MenuItem>
                          <MenuItem value={"31-35"}>31-35</MenuItem>
                          <MenuItem value={"36-40"}>36-40</MenuItem>
                          <MenuItem value={"41-45"}>41-45</MenuItem>
                          <MenuItem value={"46-50"}>46-50</MenuItem>
                          <MenuItem value={"51-55"}>51-55</MenuItem>
                          <MenuItem value={"56-60"}>56-60</MenuItem>
                          <MenuItem value={"61-65"}>61-65</MenuItem>
                          <MenuItem value={"66-70"}>66-70</MenuItem>
                          <MenuItem value={"71-75"}>71-75</MenuItem>
                          <MenuItem value={"76-80"}>76-80</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          value={stateSelect}
                          className={classes.select}
                          inputProps={{
                            name: "state",
                            id: "select-state",
                          }}
                          onChange={e => {
                            setStateSelect(String(e.target.value))
                            let filter = getStringFilter(String(e.target.value))
                            setStateFilter(filter)
                          }}
                        >
                          <MenuItem value={"All"}>Australia</MenuItem>
                          {defaultLocations.map(row => {
                            return (
                              <MenuItem value={row}>
                                {Helpers.getDisplayString(row)}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <RSelect
                          className={classes.searchSelect}
                          style={searchSelectorStyle}
                          value={expItemSelect}
                          placeholder={"Choose an Expense Item"}
                          options={newDefaultExpenses}
                          onChange={e => {
                            //@ts-ignore
                            setExpItemSelect(String(e.value))
                            //@ts-ignore
                            let filter = getStringFilter(String(e.value))
                            setExpItemFilter(filter)
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignContent="flex-start">
          <div style={{ marginTop: 10 }}>
            <div className="smallScreen">
              <Bars
                dataset={data}
                text="Expense Items"
                total={total}
                xText="Households"
                yText="Yearly Spend"
                userRank={expenseItemAmount}
              />
            </div>
          </div>
          <Typography
            paragraph={true}
            align="center"
            variant="subtitle1"
            gutterBottom
          >
            * Based on a survey of {total} households.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
const styles = (theme: Theme) => {
  const style: StyleRules = {
    root: {
      display: "flex",
      flexWrap: "wrap",
      fontFamily: "Poppins",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(1) * 2,
    },
    select: {
      textAlign: "left",
      borderColor: "#ccc",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 4,
      padding: "5px 10px",
    },
    inputLabel: {
      fontSize: 18,
      textAlign: "left",
      left: -14,
      fontWeight: 200,
      color: "black",
    },
    paper: {
      padding: 5,
      minWidth: 180,
      borderColor: "red",
      borderWidth: 4,
      borderStyle: "solid",
    },

    subHeader: {
      padding: 3,
      borderBottom: "1px black solid",
      fontWeight: 500,
      minWidth: 400,
    },
    paperContainer: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
      position: "relative",
    },
    heading: {
      marginBottom: 10,
      fontWeight: 400,
    },
    labelRoot: {
      fontSize: 12,
      color: "black",
    },
    spacing: {
      margin: theme.spacing(1),
    },
    imageHolder: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "3em",
    },
    mainLoaderContainer: {
      position: "absolute",
      paddingTop: "15%",
      width: "99%",
      height: "98%",
      zIndex: 1000,
      backgroundColor: "rgba(0,0,0,0.1)",

      textAlign: "center",
    },
    clientInfoContainer: {
      width: 170,
      margin: theme.spacing(1),
    },
    searchSelect: {
      width: 250,
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.87)",
      borderBottom: "2px solid #ccc",
      borderTop: "1px solid #ccc",
      borderRight: "1px solid #ccc",
      borderLeft: "1px solid #ccc",
      borderRadius: 0,
      backkground: "#fff",
    },
  }
  return style
}

function getAgeFilter(filterBounds: string) {
  if (filterBounds !== "All") {
    // (isNumber) = 0 if value is string 1 if number
    let filter = [
      { operator: ">=", isNumber: "1", value: "" },
      { operator: "<=", isNumber: "1", value: "" },
    ]
    let bounds = filterBounds.split("-")
    filter[0].value = bounds[0]
    filter[1].value = bounds[1]
    if (parseInt(bounds[0]) > 20) {
      return filter
    } else {
      return []
    }
  }

  return []
}

function getStringFilter(stringValue: string) {
  if (stringValue !== "All") {
    let filter = [{ operator: "=", isNumber: "0", value: stringValue }]
    return filter
  }
  return []
}

function getHouseholdCompFilter(clientSummary: object) {
  return [
    {
      operator: "=",
      value: "'" + Helpers.getHouseholdComp(clientSummary) + "'",
      isNumber: "0",
    },
  ]
}

function getDependantsFilter(clientSummary: object) {
  let dependantsCount = Helpers.getDependantsTotal(clientSummary)
  return [{ operator: "=", isNumber: "1", value: dependantsCount }]
}

function getAnnualAmount(expense: object) {
  let amount = _.get(expense, "amount", 0)
  let annual = 0
  let frequency = _.get(expense, "frequency", "Yearly")
  if (amount > 0) {
    switch (frequency) {
      case "Weekly":
        annual = amount * 52
        break
      case "Fortnightly":
        annual = amount * 26
        break
      case "Monthly":
        annual = amount * 12
        break
      case "Every 2 months":
        annual = amount * 6
        break
      case "Every 3 months":
        annual = amount * 4
        break
      case "Every 6 months":
        annual = amount * 2
        break
      case "Yearly":
        annual = amount
        break
    }
  }
  return annual
}

const defaultExpenses = [
  { label: "Rent", value: "'Rent'" },
  { label: "Local Government Rates", value: "'Local Government Rates'" },
  {
    label: "Water and Sewerage Rates and Charges",
    value: "'House and Contents Insurance'",
  },
  { label: "Electricity", value: "'Electricity'" },
  { label: "Gas", value: "Gas" },
  {
    label: "Telephone - Fixed and Mobile",
    value: "'Telephone - Fixed and Mobile'",
  },
  { label: "Internet", value: "'Internet'" },
  { label: "Pay Television", value: "'Pay Television'" },
  { label: "Health Insurance", value: "'Health Insurance'" },
  { label: "Life Insurance", value: "'Life Insurance'" },
  { label: "Car Registration", value: "'Car Registration'" },
  { label: "Car Insurance", value: "'Car Insurance'" },
  { label: "Motoring Organisations", value: "'Motoring Organisations'" },
  { label: "Education - School Fees", value: "'Education - School Fees'" },
  { label: "Child Care Fees", value: "'Child Care Fees'" },
  { label: "Bank Fees", value: "'Bank Fees'" },
  {
    label: "Groceries and Non-alcoholic Beverages",
    value: "'Groceries and Non-alcoholic Beverages'",
  },
  {
    label: "Clothing, Footwear, Dry cleaning, Repairs",
    value: "'Clothing, Footwear, Dry cleaning, Repairs'",
  },
  {
    label: "Education - Out-of-pocket expenses",
    value: "'Education - Out-of-pocket expenses'",
  },
  {
    label: "Medical and Health Care expenses",
    value: "'Medical and Health Care expenses'",
  },
  { label: "Personal Care", value: "'Personal Care'" },
  { label: "Pets and Animals", value: "'Pets and Animals'" },
  {
    label: "Household Appliances and Tools",
    value: "'Household Appliances and Tools'",
  },
  { label: "Furniture", value: "'Furniture'" },
  { label: "Household Furnishings", value: "'Household Furnishings'" },
  {
    label: "House and Garden maintenance",
    value: "'House and Garden maintenance'",
  },
  { label: "Fuel", value: "'Fuel'" },
  { label: "Car maintenance", value: "'Car maintenance'" },
  { label: "Parking and Tolls", value: "'Parking and Tolls'" },
  { label: "Fares", value: "'Fares'" },
  { label: "Presents and Gifts", value: "'Presents and Gifts'" },
  { label: "Donations", value: "'Donations'" },
  { label: "Holidays", value: "'Holidays'" },
  {
    label: "Dining out / Takeaway food",
    value: "'Dining out / Takeaway food'",
  },
  { label: "Entertainment", value: "'Entertainment'" },
  {
    label: "Sport, recreation and hobbies",
    value: "'Sport, recreation and hobbies'",
  },
  {
    label: "Books, Magazines and Newspapers",
    value: "'Books, Magazines and Newspapers'",
  },
  {
    label: "Video / DVD purchase and hire",
    value: "'Video / DVD purchase and hire'",
  },
  { label: "Alcohol and Tobacco", value: "'Alcohol and Tobacco'" },
  { label: "Gambling and Lotto", value: "'Gambling and Lotto'" },
]

const defaultLocations = [
  "'ACT'",
  "'NSW'",
  "'NT'",
  "'QLD'",
  "'SA'",
  "'TAS'",
  "'VIC'",
  "'WA'",
]

interface Props extends WithStyles<typeof styles, false> {
  moneyFitPromise: Function
  clientSummary: object
  countTotal: Function
  totalExpense: number | null | undefined
}

export default withStyles(styles)(TotalExpenseItemGraph)
