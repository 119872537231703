import { createContext } from "react"
import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema"
import { MonthlyBalanceResponseSchema } from "../schemas/monthlyBalanceResponseSchema"
import { ProvisioningResponseSchema } from "../schemas/provisioningResponseSchema"
import { MoneyStretchSchema } from "../schemas/moneyStretch"

type ContextProps = {
  initDashboard: () => void
  summary: ClientSummarySchema
  monthlyBalance: MonthlyBalanceResponseSchema
  provisioning: ProvisioningResponseSchema
  isEditable: boolean
  isDesktop: boolean
  userId: string
  newDashboardValues: any
  rolloverDispatch: React.Dispatch<any>
  showRolloverState: { showSplash: boolean; showRolloverModal: boolean }
  userHasBorrowings: boolean
  userHasSecondary: boolean
  portalDiv: HTMLElement
  isTabletSmall: boolean
  isRollOver: boolean
  isUserTPC: boolean
  isUserAdmin: boolean
  setAccumulatedSurplus: any
  accumulatedSurplus: any
  setMonthlyBalanceRes: Function
  periodCounter: number
  setProvisioningRes: Function
  getRollOverData: () => void
  counterEnd: number
  setPeriodCounter: Function
  moneyStretch: MoneyStretchSchema
}

const AppDataContext = createContext<Partial<ContextProps>>({})

export default AppDataContext
