import React, { useState, useContext, useReducer, useEffect } from "react"
import _ from "lodash"
import ExpensesModal from "./ExpensesModal"
import { config } from "./Rollover.config"
import ReactDOM from "react-dom"
import { summaryExpenseReducer } from "../../lib/reducers"
import AppDataContext from "../../context/AppDataContext"
import SummaryExpenseContext from "../../context/SummaryExpenseContext"
import style from "./Rollover.module.scss"

const Rollover: React.FC<Props> = ({ getRollOverData }) => {
  const modalRoot = document.getElementById("modals")
  const { summary } = useContext(AppDataContext)
  const clonedSummary = _.cloneDeep(summary)
  const [configIndex, setConfigIndex] = useState(0)

  const [newSummary, dispatch] = useReducer(
    summaryExpenseReducer,
    clonedSummary
  )

  useEffect(() => {
    dispatch({
      type: "INITIALIZE_EXPENSES",
      payload: clonedSummary,
    })
  }, [summary])

  const setIndexHandler = type => {
    if (type === "back" && configIndex > 0) {
      setConfigIndex(configIndex - 1)
    } else if (type === "next" && configIndex < config.length - 1) {
      setConfigIndex(configIndex + 1)
    }
  }
  return ReactDOM.createPortal(
    <SummaryExpenseContext.Provider
      value={{ summaryDispatch: dispatch, reduceExpenses: newSummary }}
    >
      <div className={style.wholeModalWrapper}>
        <ExpensesModal
          category={config[configIndex].title}
          description={config[configIndex].desc}
          infoControl={config[configIndex].infoControl}
          hidePrimaryModal={config[configIndex].hidePrimaryModal || null}
          firstStepModal={config[configIndex].firstStepModal || null}
          setIndexHandler={setIndexHandler}
          beforeClosing={config[configIndex].beforeClosing || null}
          getRollOverData={getRollOverData}
        />
      </div>
    </SummaryExpenseContext.Provider>,
    modalRoot
  )
}

export default Rollover

interface Props {
  getRollOverData: () => void
}
