import { createContext } from "react"

interface ContextProps {
  summaryDispatch: Function
  reduceExpenses: any
}

const SummaryExpenseContext = createContext<Partial<ContextProps>>({})

export default SummaryExpenseContext
