import React, { useContext } from "react"
import AppDataContext from "../../context/AppDataContext"
import TotalIncomeGraph from "./TotalIncomeGraph"
import TotalExpenseGraph from "./TotalExpenseGraph"
import TotalExpenseItemGraph from "./TotalExpenseItemGraph"
import TotalAnnualSurplusGraph from "./TotalAnnualSurplusGraph"
import TotalNetWorthGraph from "./TotalNetWorthGraph"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import _ from "lodash"
interface Props {
  moneyFitPromise: Function
  houseHoldNetWorth: number
}

const MoneyFit: React.FC<Props> = ({ moneyFitPromise, houseHoldNetWorth }) => {
  const { summary, newDashboardValues } = useContext(AppDataContext)
  const totalAnnualIncome = newDashboardValues.totalNetIncome
  const totalAnnualExpense = newDashboardValues.totalExpenditure
  const totalHouseHoldExpenseAnnual = newDashboardValues.totalHouseHoldExpenseAnnual
  const totalAnnualSurplus = totalAnnualIncome - totalAnnualExpense

  const theme = createMuiTheme({
    typography: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  })

  const countTotal: any = (d: any) => {
    return _.sumBy(d, function(d: any) {
      return d.count
    })
  }
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <TotalIncomeGraph
          moneyFitPromise={moneyFitPromise}
          clientSummary={summary}
          totalIncome={totalAnnualIncome}
          countTotal={countTotal}
        />
        <TotalExpenseGraph
          countTotal={countTotal}
          clientSummary={summary}
          moneyFitPromise={moneyFitPromise}
          totalExpense={totalHouseHoldExpenseAnnual}
        />
        <TotalExpenseItemGraph
          moneyFitPromise={moneyFitPromise}
          clientSummary={summary}
          countTotal={countTotal}
          totalExpense={totalHouseHoldExpenseAnnual}
        />
        <TotalAnnualSurplusGraph
          moneyFitPromise={moneyFitPromise}
          clientSummary={summary}
          countTotal={countTotal}
          totalSurplus={totalAnnualSurplus}
        />
        <TotalNetWorthGraph
          moneyFitPromise={moneyFitPromise}
          clientSummary={summary}
          countTotal={countTotal}
          totalNetWorth={houseHoldNetWorth}
        />
      </MuiThemeProvider>
    </div>
  )
}

export default MoneyFit
