import React from "react"
import {
  withStyles,
  StyleRules,
  WithStyles,
  Theme,
} from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import _ from "lodash"
import InputAdornment from "@material-ui/core/InputAdornment"
import MoneyIn from "./MoneyInForm"
import MoneyAvailable from "./MoneyAvailableForm"
import MoneyOut from "./MoneyOutForm"
import styles from "./FormStretch.module.scss"
export interface FormProps {
  form: any
  handleFormSlider2Change?: any
  handleFormSlider1Change?: any
  handleFormChange?: any
  getTotalMoneyIn?: () => number
  getTotalPercentageVal1?: () => number
  getTotalPercentageVal2?: () => number
  getTotalMoneyAvailable?: () => number
  handlePublishChanges?: () => void
  handleSaveChanges?: () => void
  handleFormChangeBillsBasic?: any
  handleFormChangeBillsDiscretionary?: any
  handleFormChangeSpendingBasic?: any
  handleFormChangeSpendingDiscretionary?: any
  sumDiscretionaryBills?: () => number
  sumDiscretionarySpending?: () => number
  sumEssentailSpending?: () => number
  sumEssentailBills?: () => number
  sumEssential?: () => number
  sumDiscretionary?: () => number
  handleFormChangeFrequencyBills?: any
  handleFormChangeFrequencySpending?: any
  getTotalSurplusDeficit?: () => number
  getTotalMoneyOutEssential?: () => number
  getTotalMoneyOutDiscretionary?: () => number
  currentSpendingCount?: number
  baselineSpendingCount?: number
  essentialSpendingCount?: number
  isPublishDisable?: boolean
}

const FormStretch: React.FC<FormProps> = ({
  form,
  handleFormChange,
  handleFormSlider1Change,
  handleFormSlider2Change,
  getTotalMoneyIn,
  getTotalPercentageVal1,
  getTotalPercentageVal2,
  getTotalMoneyAvailable,
  handlePublishChanges,
  handleSaveChanges,
  handleFormChangeBillsBasic,
  handleFormChangeBillsDiscretionary,
  handleFormChangeSpendingBasic,
  handleFormChangeSpendingDiscretionary,
  sumDiscretionaryBills,
  sumDiscretionarySpending,
  sumEssentailSpending,
  sumEssentailBills,
  sumEssential,
  sumDiscretionary,
  handleFormChangeFrequencyBills,
  handleFormChangeFrequencySpending,
  getTotalMoneyOutEssential,
  getTotalMoneyOutDiscretionary,
  getTotalSurplusDeficit,
  currentSpendingCount,
  baselineSpendingCount,
  essentialSpendingCount,
  isPublishDisable,
  ...props
}) => {
  const currencyProps = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
    classes: {
      input: styles.nativeInput,
    },
  }

  const normalInput = {
    classes: {
      input: styles.nativeInput,
    },
  }

  return (
    <div className={styles.formStretchRoot}>
      <MoneyIn
        {...{
          form,
          handleFormChange,
          handleFormSlider1Change,
          handleFormSlider2Change,
          getTotalMoneyIn,
          getTotalPercentageVal1,
          getTotalPercentageVal2,
          getTotalMoneyAvailable,
          currencyProps,
          currentSpendingCount,
          baselineSpendingCount,
          essentialSpendingCount,
          normalInput,
        }}
      />

      <MoneyAvailable
        {...{
          form,
          handleFormChange,
          getTotalMoneyAvailable,
          currencyProps,
        }}
      />

      <MoneyOut
        {...{
          form,
          handleFormChange,
          handleFormChangeBillsBasic,
          handleFormChangeBillsDiscretionary,
          handleFormChangeSpendingBasic,
          handleFormChangeSpendingDiscretionary,
          currencyProps,
          sumDiscretionaryBills,
          sumDiscretionarySpending,
          sumEssentailSpending,
          sumEssentailBills,
          sumEssential,
          sumDiscretionary,
          handlePublishChanges,
          handleFormChangeFrequencyBills,
          handleFormChangeFrequencySpending,
          getTotalSurplusDeficit,
          getTotalMoneyOutEssential,
          getTotalMoneyOutDiscretionary,
          normalInput,
          isPublishDisable,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveChanges}
        className={styles.bottomSave}
      >
        Save
      </Button>
    </div>
  )
}

export default React.memo(FormStretch)
