import React, { useState, useEffect, useReducer } from "react";
import styles from "./App.module.scss";
import theme from "../lib/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  fetchSummary,
  fetchMonthlyBalance,
  fetchProvisioning,
  fetchProvisioningUpdate,
  ServiceResponse,
  ServiceResponseSummary,
  setClientId,
  getRollOver,
  fetchMoneyStretch,
} from "../lib/services";
import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema";
import { ProvisioningResponseSchema } from "../schemas/provisioningResponseSchema";
import { useMedia } from "../hooks/useMedia";
import { showRolloverReducer } from "../lib/reducers";
import { getProvisionsExpenses } from "../lib/utils";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";

// Components
import AppDataContext from "../context/AppDataContext";
import CalculateDataContext from "../context/CalculateDataContext";
import { ToastContainer } from "react-toastify";
import MoneyFit from "./MoneyFit/MoneyFit";

//Calculator
import {
  calculateMonthlySurplus,
  calculateMonthlyLivingLifestyle,
  calculateMonthlyCreditCard,
  calculateMonthlyLoans,
  calculateMonthlyProvisions,
} from "../lib/calculator";
//svg
import _ from "lodash";
import MoneySmarts from "./MoneySmarts";
import MoneyStretch from "./MoneyStretch/MoneyStretch";
import TabPanel from "./TabPanel";

const App: React.FC<AppProps> = ({
  id,
  dashboardValues,
  moneyFitPromise,
  tabValue,
}) => {
  const [showRollOverSplash] = useState({
    // eslint-disable-line
    showSplash: false,
    showRolloverModal: false,
    completeRollOver: false,
  });
  const [initDashValue, setInitDashValue] = useState(null);
  const [userId, setUserId] = useState(id);
  const [counterEnd, setCounterEnd] = useState(0);
  const [periodCounter, setPeriodCounter] = useState(0);
  const [isRollOver, setIsRollOver] = useState();
  const [rollOverMonthlyBalance, setRollOverMonthlyBalance] = useState([]);
  const [rollOverProvision, setRollOverProvision] = useState([]);
  const [rollOverSummary, setRollOverSummary] = useState([]);
  const [newDashboardValues, setNewDashboardValues] = useState(null);
  const [isUserTPC, setIsUserTPC] = useState(false);
  const [summaryRes, setSummaryRes] = useState<
    ServiceResponseSummary<ClientSummarySchema>
  >({ kind: "LOADING", value: null, isTPCPlatform: false });
  const [monthlyBalanceRes, setMonthlyBalanceRes] = useState({
    kind: "LOADING",
    value: null,
  });
  const [provisioningRes, setProvisioningRes] = useState<
    ServiceResponse<ProvisioningResponseSchema>
  >({ kind: "LOADING", value: null });
  const [accumulatedSurplus, setAccumulatedSurplus] = useState(0);
  const [moneyStretch, setMoneyStretch] = useState<any>({
    kind: "LOADING",
    value: null,
  });

  const isDesktop = useMedia(["(min-width: 1367px)"], [true], false);
  const isTabletSmall = useMedia(["(max-width: 768px)"], [true], false);
  const [showRolloverState, rolloverDispatch] = useReducer(
    showRolloverReducer,
    showRollOverSplash
  );
  const [tabIndex, setTabIndex] = useState<number>(tabValue);

  const getRollOverData = () => {
    getRollOver(isUserTPC, userId).then((res) => {
      setIsRollOver(res.rollover);
      if (res.rollover) {
        const counterEnd = res.data.rollOverMonthlyBalance.length;
        setRollOverMonthlyBalance(res.data.rollOverMonthlyBalance);
        setRollOverProvision(res.data.rollOverProvision);
        setRollOverSummary(res.data.rollOverSummary);
        setCounterEnd(counterEnd);
        setPeriodCounter(counterEnd);
      }
      return res;
    });
  };

  const portalDiv = document.getElementById("modals");

  const fetchMoneyStretchApi = async () => {
    const res = await fetchMoneyStretch();
    setMoneyStretch(res.value.data);
  };

  useEffect(() => {
    if (!initDashValue && dashboardValues) {
      setInitDashValue(dashboardValues);
    }

    // only used on pagination change
    if (initDashValue !== dashboardValues) {
      setNewDashboardValues(dashboardValues);
    }
  }, [dashboardValues]);

  useEffect(() => {
    if (id) {
      setClientId(id);
    } else {
      setClientId(null);
    }

    if (userId === null) {
      setUserId(process.env.REACT_APP_CLIENT_USER_ID);
    } else {
      setUserId(userId);
    }

    getRollOverData();
    fetchMoneyStretchApi();
  }, [userId, showRollOverSplash, id]);

  useEffect(() => {
    // initializing newdashboardValues
    if (initDashValue !== dashboardValues) {
      setNewDashboardValues(dashboardValues);
    } else {
      setNewDashboardValues(initDashValue);
    }

    // initializer data on first load
    if (isRollOver && periodCounter !== counterEnd) {
      setMonthlyBalanceRes({
        kind: "SUCCESS",
        value: rollOverMonthlyBalance[periodCounter],
      });
      setProvisioningRes({
        kind: "SUCCESS",
        value: {
          provisionItems: rollOverProvision[periodCounter].provisionItems,
        },
      });

      dashboardValues.initDashboard(rollOverSummary[periodCounter]);
      setSummaryRes({ kind: "SUCCESS", value: rollOverSummary[periodCounter] });
    } else {
      if (initDashValue) {
        setNewDashboardValues(initDashValue);
      }

      fetchSummary()
        .then((res) => {
          setIsUserTPC(res.isTPCPlatform);
          setSummaryRes(res);
          return res;
        })
        .then((summary) => {
          fetchProvisioning(summary.isTPCPlatform)
            .then((res) => {
              if (res.value.provisionItems.length <= 0) {
                const allProvisioningExpenses = getProvisionsExpenses(
                  summary.value.expenses.expenses,
                  summary.value.assets.properties
                );
                const recreateProvisionItems = allProvisioningExpenses.map(
                  (expense) => {
                    const newProvisionItems = {
                      category: expense.desc,
                      categoryId: expense._id,
                      items: [],
                    };
                    return newProvisionItems;
                  }
                );
                const provision = {
                  provisionItems: recreateProvisionItems,
                };

                fetchProvisioningUpdate(provision, summary.isTPCPlatform)
                  .then((res) => res)
                  .catch((err) => console.log("error", err));

                setProvisioningRes({ kind: "SUCCESS", value: provision });
              } else {
                setProvisioningRes(res);
              }
            })
            .catch((error) => console.log("provisionFetch", error));
          fetchMonthlyBalance(summary.isTPCPlatform).then((res) => {
            setMonthlyBalanceRes(res);
          });
        });
    }
  }, [
    periodCounter,
    counterEnd,
    isRollOver,
    rollOverMonthlyBalance,
    rollOverProvision,
    rollOverSummary,
    dashboardValues,
  ]);

  if (
    summaryRes.kind === "LOADING" ||
    monthlyBalanceRes.kind === "LOADING" ||
    provisioningRes.kind === "LOADING" ||
    monthlyBalanceRes.value.monthlyBalance.length <= 0
  ) {
    return <span>Loading...</span>;
  }

  if (
    summaryRes.kind === "ERROR" ||
    monthlyBalanceRes.kind === "ERROR" ||
    provisioningRes.kind === "ERROR"
  ) {
    return <span>Error</span>;
  }

  const summaryValue = summaryRes.value;
  const monthlyBalanceValue = monthlyBalanceRes.value;
  let provisioningValue = provisioningRes.value;
  let currentSurplus = 0;
  let lifestyleJar = 0;
  let creditCardJar = 0;
  let loansJar = 0;
  let provisionJar = 0;

  const userHasBorrowings = _.has(summaryValue, "borrowings");
  const userHasSecondary = _.has(summaryValue, "personalInfo.client2");

  const provisioningExpenses = getProvisionsExpenses(
    summaryValue.expenses.expenses,
    summaryValue.assets.properties
  );

  let missingProvisioning = [];

  for (let i = 0; i < provisioningExpenses.length; i++) {
    let findProvisionById = provisioningValue.provisionItems.find(
      (provision) => {
        return provision.categoryId === provisioningExpenses[i]._id;
      }
    );

    if (findProvisionById === undefined) {
      missingProvisioning.push(provisioningExpenses[i]);
    }
  }

  const reCreateProvisions = missingProvisioning.map((addProvision) => {
    const addMissing = {
      category: addProvision.desc,
      categoryId: addProvision._id,
      items: [],
    };

    return addMissing;
  });

  const stateProvision =
    provisioningValue.provisionItems.concat(reCreateProvisions);

  provisioningValue.provisionItems = stateProvision;

  if (
    summaryValue &&
    monthlyBalanceValue &&
    monthlyBalanceValue.monthlyBalance.length > 0 &&
    provisioningValue
  ) {
    currentSurplus =
      calculateMonthlySurplus(
        monthlyBalanceValue.monthlyBalance[0].items,
        summaryValue.assets.properties,
        monthlyBalanceValue.monthlyBalance[0].items.length - 1,
        summaryValue.expenses.expenses
      ) * monthlyBalanceValue.monthlyBalance[0].items.length;

    lifestyleJar = calculateMonthlyLivingLifestyle(
      summaryValue.expenses.expenses,
      summaryValue.assets.properties
    );
    creditCardJar = calculateMonthlyCreditCard(
      summaryValue.expenses.expenses,
      summaryValue.assets.properties
    );
    loansJar = userHasBorrowings
      ? calculateMonthlyLoans(summaryValue.borrowings.borrowing)
      : 0;
    provisionJar = calculateMonthlyProvisions(
      summaryValue.expenses.expenses,
      summaryValue.assets.properties,
      0,
      false
    );
  }
  const allProvision = getProvisionsExpenses(
    summaryValue.expenses.expenses,
    summaryValue.assets.properties
  ).map((expense) => expense._id);
  provisioningValue.provisionItems = provisioningValue.provisionItems.filter(
    (item) => {
      return allProvision.includes(item.categoryId);
    }
  );
  const isUserAdmin = true;

  function a11yProps(index) {
    return {
      id: `moneysmarts-tab-${index}`,
      "aria-controls": `moneysmarts-tabpanel-${index}`,
    };
  }

  const appBarHandler = (event, newValue) => {
    setTabIndex(newValue);
  };

  interface tabProps {
    label?: string;
    classes: any;
  }

  const StyledTab = withStyles((theme) => ({
    textBtn: {
      textTransform: "none",
      color: "var(--portal-page-primary-background)",
      fontWeight: 500,
      fontSize: "18px",
      fontFamily: "Poppins",
      marginRight: theme.spacing(1),
      "&:focus": {
        opacity: 1,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px !important",
      },
    },
  }))((props: tabProps) => {
    const { classes, ...other } = props;
    return <Tab className={classes.textBtn} {...other} disableRipple />;
  });

  interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    centered: any;
  }

  const StyledTabs = withStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "none", // EM-19566 moorr CSS update (was #f5f5f5)
      "& > span": {
        width: "100%",
        height: "20px",
        backgroundColor: "#fdc106",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px !important",
      },
    },
  }))((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
  ));

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <AppDataContext.Provider
          value={{
            initDashboard: dashboardValues.initDashboard,
            userId,
            newDashboardValues,
            summary: summaryValue,
            monthlyBalance: monthlyBalanceValue,
            provisioning: provisioningValue,
            isEditable: periodCounter === counterEnd,
            isDesktop,
            rolloverDispatch,
            showRolloverState,
            userHasBorrowings,
            userHasSecondary,
            portalDiv,
            isTabletSmall,
            isRollOver,
            isUserTPC,
            isUserAdmin,
            setAccumulatedSurplus,
            accumulatedSurplus,
            setMonthlyBalanceRes,
            periodCounter,
            setProvisioningRes,
            getRollOverData,
            counterEnd,
            setPeriodCounter,
            moneyStretch,
          }}
        >
          <CalculateDataContext.Provider
            value={{
              currentSurplus,
              lifestyleJar,
              creditCardJar,
              loansJar,
              provisionJar,
            }}
          >
            <div className={styles.headerTabsWrapper}>
              <Paper className={styles.linksWrapper}>
                <StyledTabs
                  value={tabIndex}
                  onChange={appBarHandler}
                  aria-label="moneysmarts-header"
                  centered
                >
                  <StyledTab label="Money Smarts" {...a11yProps(0)} />
                  <StyledTab label="Money Fit" {...a11yProps(1)} />
                  <StyledTab label="Money Stretch" {...a11yProps(2)} />
                </StyledTabs>
              </Paper>
            </div>
            <TabPanel value={tabIndex} index={0}>
              <MoneySmarts />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <MoneyFit
                moneyFitPromise={moneyFitPromise}
                houseHoldNetWorth={dashboardValues.houseHoldNetWorth}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <MuiThemeProvider theme={theme}>
                <MoneyStretch />
              </MuiThemeProvider>
            </TabPanel>
            <ToastContainer
              autoClose={3000}
              position="top-right"
              hideProgressBar={true}
              closeOnClick={true}
              pauseOnHover={false}
              draggable={false}
            />
          </CalculateDataContext.Provider>
        </AppDataContext.Provider>
      </MuiThemeProvider>
    </>
  );
};

interface AppProps {
  id?: string;
  dashboardValues?: any;
  isAdmin?: boolean;
  moneyFitPromise?: Function;
  tabValue?: number;
}

export default App;
