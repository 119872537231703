import React, { useContext } from "react"
import styles from "./App.module.scss"
import {
  fetchMonthlyBalanceUpdate,
  fetchProvisioningUpdate,
} from "../lib/services"

// Components
import SnapshotSection from "./Snapshot-section/SnapshotSection"
import PrimaryAccountSection from "./Primary-Account-Section/PrimayAccountSection"
import LivingLifestyleSection from "./LivingLifeStyle-Section/LivingLifestyleSection"
import CreditCardSection from "./CreditCardSection"
import DirectPaymentsSection from "./DirectPaymentsSection"
import LoansSection from "./LoansSection"
import BudgetIntervalContainer from "./BudgetIntervalContainer"
import AppDataContext from "../context/AppDataContext"
import GaugesSection from "../components/Gauges-Section/GaugesSection"
import * as dateFns from "date-fns"

//svg
import sideArrow from "../images/icons/chevron-left-solid.svg"
import _ from "lodash"

const MoneySmarts: React.FC = () => {
  const {
    summary,
    isRollOver,
    monthlyBalance,
    setMonthlyBalanceRes,
    isUserTPC,
    periodCounter,
    provisioning,
    setProvisioningRes,
    getRollOverData,
    counterEnd,
    setPeriodCounter,
  } = useContext(AppDataContext)

  const onDraftMonthlyBalanceSubmit = async newMonthlyBalance => {
    await fetchMonthlyBalanceUpdate(isUserTPC, newMonthlyBalance)
    setMonthlyBalanceRes({ kind: "SUCCESS", value: newMonthlyBalance })
  }

  const onDraftProvisioningSubmit = async newProvisioning => {
    await fetchProvisioningUpdate(newProvisioning, isUserTPC)
    setProvisioningRes({ kind: "SUCCESS", value: newProvisioning })
  }

  const counterHandler = (direction: string) => {
    if (direction === "left") {
      if (periodCounter === 0) {
        setPeriodCounter(0)
      } else {
        setPeriodCounter(periodCounter - 1)
      }
    } else {
      if (periodCounter === counterEnd) {
        setPeriodCounter(counterEnd)
      } else {
        setPeriodCounter(periodCounter + 1)
      }
    }
  }

  const renderDisplayYear = () => {
    const { startData } = monthlyBalance
    if (startData === undefined) return null
    const { year, month, day } = startData
    const startDate = new Date(Number(year), Number(month), Number(day))
    return (
      <div className={styles.displayYear}>
        Display Year:&nbsp;&nbsp;
        <>
          <span>
            {dateFns.format(startDate, "MMMM yyyy")} to{" "}
            {dateFns.format(dateFns.addYears(startDate, 1), "MMMM yyyy")}{" "}
          </span>
        </>
      </div>
    )
  }

  const renderRollOverSwitch = (displayYearOnTop = false) => {
    const period = counterEnd - periodCounter + 1
    return (
      <div className={styles.switcherButton}>
        {displayYearOnTop && renderDisplayYear()}
        <div className={styles.arrowContainer}>
          <img
            alt="arrow-left"
            src={sideArrow}
            className={styles.arrowLeft}
            onClick={() => counterHandler("left")}
          />{" "}
          <div className={styles.period}>
            {period < counterEnd + 1 && (
              <React.Fragment>
                <span>Previous Period</span>
                <span> {period + 1}</span>
              </React.Fragment>
            )}
            <span className={styles.active}>{period}</span>
            {period - 1 !== 0 && (
              <React.Fragment>
                <span>{period - 1}</span>
                <span>Next Period</span>
              </React.Fragment>
            )}
          </div>
          <img
            alt="arrow-right"
            src={sideArrow}
            className={styles.arrowRight}
            onClick={() => counterHandler("right")}
          />
        </div>
        {!displayYearOnTop && renderDisplayYear()}
      </div>
    )
  }

  const secondaryName =
    _.has(summary, "personalInfo.client2") &&
    summary.personalInfo.client2 !== null
      ? `and ${summary.personalInfo.client2.fName}`
      : ""

  return (
    <>
      <div className={styles.wholeAppWrapper}>
        <div className={styles.nameCounterWrapepr}>
          <p className={styles.clientNames}>
            {summary.personalInfo.client1.fName} {secondaryName}
          </p>
          {isRollOver && renderRollOverSwitch(true)}
          <span></span>
        </div>

        <SnapshotSection />
        <GaugesSection />
        <PrimaryAccountSection />
        <LivingLifestyleSection />
        <CreditCardSection />
        <DirectPaymentsSection />
        <LoansSection />
        <BudgetIntervalContainer
          getRollOverData={getRollOverData}
          summary={summary}
          monthlyBalance={monthlyBalance}
          provisioning={provisioning}
          onDraftMonthlyBalanceSubmit={onDraftMonthlyBalanceSubmit}
          onDraftProvisioningSubmit={onDraftProvisioningSubmit}
          isEditable={periodCounter === counterEnd}
          rolloverSwitch={renderRollOverSwitch}
        />
      </div>
    </>
  )
}

export default MoneySmarts
