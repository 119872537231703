import _ from "lodash"
export class Helpers {
  // static toCurrency(number: number){
  //          return `$\xa0\xa0\xa0` +Number(number).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  // }
  static toCurrency(number: number) {
    let val = Number(number)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    if (typeof val == "string" && val == "NaN") {
      return ""
    }
    return (
      `$\xa0` +
      Number(number)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    )
  }

  static toCurrencyTitle(number: number) {
    let val = Number(number)
    if (typeof val == "string" && val == "NaN") {
      return ""
    }
    return (
      `$\xa0` +
      Number(number)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    )
  }

  static cutStrings(str: string) {
    return str.replace(/(^.{26})(.*)/g, "$1...")
  }

  static calculateDistance(x1, y1, x2, y2) {
    return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
  }

  static getMonthsYears = async (startingMonth, startingYear) => {
    let monthList = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    let monthYear = []
    for (let i = 1; i <= 12; i++) {
      let index = (startingMonth + i) % 12
      let plusOne = parseInt(((startingMonth + i) / 12).toString())
      monthYear.push({
        month: monthList[index],
        year: (parseInt(startingYear) + plusOne).toString(),
      })
    }
    return monthYear
  }
  static getMonthIndex = async monthName => {
    let monthList = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    return monthList.indexOf(monthName)
  }
  static getLabelsByPage = async (startingMonth, startingYear) => {
    let monthList = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    let labels = []
    for (let i = 0; i < 12; i++) {
      let index = (startingMonth + i) % 12
      let plusOne = parseInt(((startingMonth + i) / 12).toString())
      labels.push(
        monthList[index].substring(0, 3) +
          " '" +
          (parseInt(startingYear) + plusOne).toString().substring(2, 4)
      )
    }
    return labels
  }
  static getLabels = async (startingMonth, startingYear) => {
    let monthList = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    let labels = []
    let startMonthIndex = 0

    let startingYearVal = new Date().getFullYear().toString()
    if (
      startingMonth &&
      typeof startingMonth != "undefined" &&
      startingMonth > -1
    ) {
      startMonthIndex = startingMonth
    }
    if (startingYear && typeof startingYear != "undefined") {
      startingYearVal = startingYear
    }
    for (let i = 0; i < 12; i++) {
      let index = (startMonthIndex + i) % 12
      let plusOne = parseInt(((startMonthIndex + i) / 12).toString())

      labels.push(
        monthList[index].substring(0, 3) +
          " '" +
          (parseInt(startingYearVal) + plusOne).toString().substring(2, 4)
      )
    }
    return labels
  }

  static getMonthlyExpenditure = async (
    expenditures,
    startingMonth,
    startingYear
  ) => {
    let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < expenditures.length; i++) {
      let exp = expenditures[i]
      for (let j = 0; j < exp.length; j++) {
        let date = new Date(
          exp[j].Date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        )
        let index = (12 + date.getMonth() - startingMonth) % 12

        if (
          (date.getFullYear().toString() == startingYear &&
            date.getMonth() >= startingMonth) ||
          (date.getFullYear() == parseInt(startingYear) + 1 &&
            date.getMonth() < startingMonth)
        )
          data[index] += exp[j].Amount
      }
    }
    return data
  }

  static addZeroNumString = num => {
    if (num < 10) {
      return "0" + num.toString()
    }
    return num.toString()
  }
  static countTotal: any = (d: any) => {
    return _.sumBy(d, function(d: any) {
      return d.count
    })
  }

  static getDisplayString(expense: string) {
    var find = "'"
    var re = new RegExp(find, "g")
    var formattedExpense = expense.replace(re, "")
    return formattedExpense
  }

  static getAgeRange(age: string | number) {
    if (age === "NA") {
      return age
    }

    let ageNum = age

    if (typeof ageNum === "string") {
      ageNum = Number(age)
    }
    if (ageNum % 5 === 0) {
      let minRange = ageNum - 4
      let maxRange = ageNum
      return `${minRange}-${maxRange}`
    } else {
      let minRange = ageNum - (ageNum % 5) + 1
      let maxRange = minRange + 4
      return `${minRange}-${maxRange}`
    }
  }
  static ageGroups = []
  static getHouseholdComp(clientSummary: object) {
    let client1 = _.get(clientSummary, "personalInfo.client1", null)
    let client2 = _.get(clientSummary, "personalInfo.client2", null)

    if (client1 && client2) {
      return "Couple"
    }
    return "Single"
  }

  static getDependantsTotal(clientSummary: object) {
    let dependantsCount = _.get(
      clientSummary,
      "personalInfo.dependants",
      []
    ).length.toString()
    return dependantsCount
  }
}
