import React, { useState, useContext } from "react"
import {
  formatCurrency,
  getDirectPaymentsExpenses,
  getMonthlyFrequencyMultiplier,
} from "../lib/utils"
import {
  calculateMonthlyDirectPayments,
  calculateMonthlyAfterTaxContribution,
} from "../lib/calculator"
import { animated as a, useSpring } from "react-spring"
import { ResizeObserver } from "@juggle/resize-observer"
import useMeasure from "react-use-measure"
import SectionHeader from "./Section-Header/SectionHeader"
import clsx from "clsx"
import styles from "../lib/jartables.module.scss"
import AppDataContext from "../context/AppDataContext"
import _ from "lodash"

const DirectPaymentsSection: React.FC = () => {
  const { summary, isEditable } = useContext(AppDataContext)
  const hasAssets = _.has(summary, "assets")
  const propertyValue = hasAssets ? summary.assets.properties : []
  const investments = hasAssets ? summary.assets.investments : []
  const superFunds = hasAssets
    ? summary.assets.superFunds
    : [
        {
          value: 0,
          isSmsf: false,
          provider: "",
          salarySacrificeBool: false,
          salarySacrificeClient1: 0,
          salarySacrificeClient2: 0,
          personalContributionsBool: false,
          personalContributionsClient1: 0,
          personalContributionsClient2: 0,
        },
      ]

  let directPayments = calculateMonthlyDirectPayments(
    summary.expenses.expenses,
    propertyValue,
    superFunds,
    investments
  )

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const [addTableHeight, setAddTableHeight] = useState(false)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const prevHeightSpring = useSpring({
    height: addTableHeight ? prevHeight : 0,
    padding: addTableHeight ? `0 0.5em` : `0 0`,
    overflow: "hidden",
    config: {
      duration: 300,
    },
  })

  const totalDirectPaymentJar = formatCurrency(directPayments)

  return (
    <section className={styles.sectionContainer}>
      <SectionHeader
        title="Direct Payments Jar"
        rotate={addTableHeight}
        onClickHandler={setTableHeightHanlder}
        showSubheader={true}
        centerSubHeaderTitle={null}
        centerSubHeaderAmount={null}
        textTitle={null}
        monthlyOutAmount={totalDirectPaymentJar}
        monthlyInAmount={totalDirectPaymentJar}
        greyedOut={!isEditable}
      />
      <a.div style={prevHeightSpring}>
        <div className={clsx(styles.tableLayOut)} ref={mainRef}>
          <div>
            <table className={clsx(styles.incomeTable, styles.wideTable)}>
              <tbody>
                <tr className={styles.tableRow}>
                  <td className={clsx(styles.tableHeader, styles.textleft)}>
                    Transfer from Primary Account
                  </td>
                  <td>{totalDirectPaymentJar}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className={clsx(styles.incomeTable)}>
              <tbody>
                {getDirectPaymentsExpenses(
                  summary.expenses.expenses,
                  propertyValue,
                  superFunds,
                  investments
                )
                  .map(expense => ({
                    ...expense,
                    amount: expense.frequency
                      ? expense.amount *
                        getMonthlyFrequencyMultiplier(expense.frequency)
                      : 0,
                  }))
                  .sort((a, b) => b.amount - a.amount)
                  .filter(expense => {
                    return expense.amount !== 0 && expense.frequency !== null
                  })
                  .map((expense, i) => (
                    <tr className={styles.tableRow} key={`direct-payment-${i}`}>
                      <td>{expense.desc}</td>
                      <td>{formatCurrency(expense.amount)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </a.div>
    </section>
  )
}

export default DirectPaymentsSection
