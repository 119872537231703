import React, { useContext } from "react"
import {
  getLivingLifestyleExpenses,
  getCreditCardExpenses,
  getProvisionsExpenses,
  getLoansBorrowingItems,
} from "../../lib/utils"
import { Doughnut } from "react-chartjs-2"
import {
  options,
  expenseMapper,
  expenseDoughnutColors,
} from "../../lib/gaugehelpers"
import AppDataContext from "../../context/AppDataContext"
import CalculateDataContext from "../../context/CalculateDataContext"
import styles from "./ExpenseGauge.module.scss"
import { formatCurrency } from "../../lib/utils"
import { calculateMonthlyMoneyOut } from "../../lib/calculator"
import { gradientGenerator } from "../../lib/gradient"
import clsx from "clsx"
import _ from "lodash"

const ExpensesGauge = () => {
  const {
    summary,
    userHasBorrowings,
    isDesktop,
    isTabletSmall,
    newDashboardValues,
  } = useContext(AppDataContext)
  const {
    provisionJar = 0,
    lifestyleJar = 0,
    loansJar = 0,
    creditCardJar = 0,
  } = useContext(CalculateDataContext)

  const hasAssets = _.has(summary, "assets")
  const propertyValue = hasAssets ? summary.assets.properties : []

  const lifestyleExpense = expenseMapper(
    "lifestyle",
    getLivingLifestyleExpenses(summary.expenses.expenses, propertyValue)
  )
  const creditCardExpenses = expenseMapper(
    "creditCard",
    getCreditCardExpenses(summary.expenses.expenses, propertyValue)
  )
  const provisionExpenses = expenseMapper(
    "provision",
    getProvisionsExpenses(summary.expenses.expenses, propertyValue)
  )
  const loansExpenses = userHasBorrowings
    ? expenseMapper("", getLoansBorrowingItems(summary.borrowings.borrowing))
    : []

  const allExpenses = lifestyleExpense.concat(
    creditCardExpenses,
    loansExpenses,
    provisionExpenses
  )

  const lifestyleColors = gradientGenerator(
    "#FC2B40",
    "#ea1528",
    lifestyleExpense.length,
    "f"
  )
  const creditCardExpensesColors = gradientGenerator(
    "#FC4E31",
    "#FC4A2C",
    creditCardExpenses.length,
    "0"
  )
  const loansExpensesColors = gradientGenerator(
    "#FF5780",
    "#f7084e",
    loansExpenses.length,
    "f"
  )
  const provisionExpensesColors = gradientGenerator(
    "#FC2B80",
    "#fe287e",
    provisionExpenses.length,
    "f"
  )
  const allSubJarColors = lifestyleColors.concat(
    creditCardExpensesColors,
    loansExpensesColors,
    provisionExpensesColors
  )

  const allExpensesCategory = allExpenses.map(
    (expense: { category: string }) => expense.category
  )
  const doughnutData = {
    datasets: [
      {
        data: allExpenses.map((expense: { amount: number }) => expense.amount),
        labels: allExpensesCategory,
        backgroundColor: allSubJarColors,
        fill: true,
        label: "one",
      },
      {
        data: [
          lifestyleJar.toFixed(2),
          creditCardJar.toFixed(2),
          loansJar.toFixed(2),
          provisionJar.toFixed(2),
        ],
        labels: ["L & L Jar", "Credit Card Jar", "Loans Jar", "Provision Jar"],
        backgroundColor: expenseDoughnutColors,
        fill: true,
        label: "two",
      },
    ],
  }

  let doughtnutSize

  if (isDesktop && !isTabletSmall) {
    doughtnutSize = 300
  } else if (isTabletSmall && !isDesktop) {
    doughtnutSize = 200
  } else {
    doughtnutSize = 250
  }

  return (
    <div className={styles.doughtnutChartWrapper}>
      <div>
        <Doughnut
          data={doughnutData}
          options={options}
          height={doughtnutSize}
          width={doughtnutSize}
        />
      </div>
      <div className={clsx(styles.insideCircleText)}>
        <p className={styles.totalText}>Total Expense</p>
        <p className={clsx(styles.totalText, styles.amountText)}>
          {formatCurrency(calculateMonthlyMoneyOut(summary, 0, false))}
        </p>
      </div>
    </div>
  )
}

export default ExpensesGauge
