exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "@-webkit-keyframes H2MFRXttSZe36SiAdM5nj {\n  0% {\n    opacity: 0;\n    -webkit-transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    -webkit-transform: translateY(0); } }\n\n@keyframes H2MFRXttSZe36SiAdM5nj {\n  0% {\n    opacity: 0;\n    transform: translateY(-20px); }\n  100% {\n    opacity: 1;\n    transform: translateY(0); } }\n\n._1bQQC0O4o1uSlzSR8mrxTC {\n  text-align: center;\n  position: absolute;\n  z-index: -1; }\n  ._1bQQC0O4o1uSlzSR8mrxTC._2PrtSwD6ymfEPAelGOmQUY {\n    position: static; }\n\n.XYlKz_2_hrf2t5_EjtlzI {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n.lmBJcXiv_TVaAQEJGcwNf {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  margin-bottom: 0;\n  margin-top: 0.3em;\n  font-weight: 500;\n  font-size: 12px; }\n  .lmBJcXiv_TVaAQEJGcwNf.tMDmN60TmtdyAj5nGpXVh {\n    font-size: 12px; }\n\n@media (min-width: 1024px) {\n  .lmBJcXiv_TVaAQEJGcwNf {\n    font-size: 16px; }\n    .lmBJcXiv_TVaAQEJGcwNf.tMDmN60TmtdyAj5nGpXVh {\n      font-size: 16px; } }\n", ""]);
// Exports
exports.locals = {
	"insideCircleText": "_1bQQC0O4o1uSlzSR8mrxTC",
	"outsideCircleText": "_2PrtSwD6ymfEPAelGOmQUY",
	"doughtnutChartWrapper": "XYlKz_2_hrf2t5_EjtlzI",
	"totalText": "lmBJcXiv_TVaAQEJGcwNf",
	"amountText": "tMDmN60TmtdyAj5nGpXVh",
	"fadeInDown": "H2MFRXttSZe36SiAdM5nj"
};