import React from "react"
import IconButton from "@material-ui/core/IconButton"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"

interface Props {
  onClick?: () => any
  cssClass: any
}
const QuestionMarkButton: React.FC<Props> = ({ onClick, cssClass }) => {
  return (
    <IconButton
      color="primary"
      size="medium"
      aria-label="upload picture"
      component="span"
      onClick={onClick}
      className={cssClass}
    >
      <HelpOutlineIcon className={cssClass} color="primary" />
    </IconButton>
  )
}

export default QuestionMarkButton
