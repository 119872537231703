import React, { useState, useContext } from "react"

import {
  ExpenseItemSchema,
  PropertySchema,
} from "../../schemas/clientSummaryResponseSchema"
import {
  formatCurrency,
  getLivingLifestyleExpenses,
  getMonthlyFrequencyMultiplier,
} from "../../lib/utils"
import { calculateMonthlyLivingLifestyle } from "../../lib/calculator"
import { ResizeObserver } from "@juggle/resize-observer"
import { animated as a, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import SectionHeader from "../Section-Header/SectionHeader"
import styles from "../../lib/jartables.module.scss"
import clsx from "clsx"
import AppDataContext from "../../context/AppDataContext"
import _ from "lodash"

const LivingLifestyleSection: React.FC = () => {
  const { summary, isEditable, isDesktop } = useContext(AppDataContext)
  const hasAssets = _.has(summary, "assets")
  const propertyValue = hasAssets ? summary.assets.properties : []

  const monthlyLivingLifestyle = calculateMonthlyLivingLifestyle(
    summary.expenses.expenses,
    propertyValue
  )

  const [mainRef, { height: prevHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const [addTableHeight, setAddTableHeight] = useState(false)

  const setTableHeightHanlder = () => {
    setAddTableHeight(!addTableHeight)
  }

  const prevHeightSpring = useSpring({
    height: addTableHeight ? prevHeight : 0,
    padding: addTableHeight ? `0 0.5em` : `0 0`,
    overflow: "hidden",
    config: {
      duration: 300,
    },
  })

  const weeklyAllocation = formatCurrency(
    calculateWeeklyLivingLifestyle(summary.expenses.expenses, propertyValue)
  )

  const monthlyCost = formatCurrency(monthlyLivingLifestyle)

  return (
    <section className={styles.sectionContainer}>
      <SectionHeader
        title="Living and Lifestyle Jar"
        rotate={addTableHeight}
        onClickHandler={setTableHeightHanlder}
        showSubheader={true}
        centerSubHeaderTitle="Weekly 7 Day Float Allocation"
        centerSubHeaderAmount={weeklyAllocation}
        monthlyInAmount={monthlyCost}
        monthlyOutAmount={monthlyCost}
        textTitle={null}
        greyedOut={!isEditable}
      />

      <a.div style={prevHeightSpring}>
        <div className={clsx(styles.tableLayOut)} ref={mainRef}>
          <div>
            <table className={clsx(styles.incomeTable, styles.wideTable)}>
              <tbody>
                {!isDesktop ? (
                  <tr className={styles.tableRow}>
                    <td className={styles.tableHeader} colSpan={2}>
                      Monthly IN
                    </td>
                  </tr>
                ) : null}
                <tr className={styles.tableRow}>
                  <td
                    className={clsx(
                      isDesktop && styles.tableHeader,
                      styles.textleft
                    )}
                  >
                    Paid from Primary Account
                  </td>
                  <td>{formatCurrency(monthlyLivingLifestyle)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table className={clsx(styles.incomeTable)}>
              <tbody>
                {!isDesktop ? (
                  <tr className={styles.tableRow}>
                    <td className={styles.tableHeader} colSpan={2}>
                      Monthly OUT
                    </td>
                  </tr>
                ) : null}
                {getLivingLifestyleExpenses(
                  summary.expenses.expenses,
                  propertyValue
                )
                  .map(expense => ({
                    ...expense,
                    amount: expense.frequency
                      ? expense.amount *
                        getMonthlyFrequencyMultiplier(expense.frequency)
                      : 0,
                  }))
                  .sort((a, b) => b.amount - a.amount)
                  .filter(expense => {
                    return expense.amount !== 0 && expense.frequency !== null
                  })
                  .map((expense, i) => (
                    <tr className={styles.tableRow} key={`expenses-index-${i}`}>
                      <td>{expense.desc}</td>
                      <td>{formatCurrency(expense.amount)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </a.div>
    </section>
  )
}

// ============================================================================
// Helpers
// ============================================================================

const calculateWeeklyLivingLifestyle = (
  expenses: ExpenseItemSchema[],
  property: PropertySchema[]
) => (calculateMonthlyLivingLifestyle(expenses, property) * 12) / 52

export default LivingLifestyleSection
