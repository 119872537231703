import * as dateFns from "date-fns";

import _ from "lodash";
import {
  ClientSummarySchema,
  ExpenseItemSchema,
  BorrowingItemSchema,
  CurrentEmploymentSchema,
  PropertySchema,
  OtherIncomeSchema,
  SuperFundSchema,
  InvestmentsSchema,
} from "../schemas/clientSummaryResponseSchema";
import { MonthlyBalanceItemSchema } from "../schemas/monthlyBalanceResponseSchema";
import { ProvisionItemSchema } from "../schemas/provisioningResponseSchema";
import {
  getLivingLifestyleExpenses,
  getCreditCardExpenses,
  getDirectPaymentsExpenses,
  getLoansBorrowingItems,
  getProvisionsExpenses,
  getMonthlyBalanceItemDate,
  getMonthlyFrequencyMultiplier,
  getYearlyFrequencyMultiplier,
} from "./utils";

// ============================================================================
// Monthly In
// ============================================================================

export const calculateMonthlyPaygIncome = (
  currentEmployment: CurrentEmploymentSchema[]
) => {
  let monthly = 0;

  currentEmployment.forEach((employment) => {
    const hasJob = _.has(employment, "job");
    const hasJobAnnualSalary = _.has(employment, "job.annualSalary");

    if (hasJob && hasJobAnnualSalary) {
      monthly += employment.job.annualSalary / 12;
    }
  });

  return monthly;
};

export const caclulateMonthlySuperSalarySacrifice = (
  clientType: ClientType,
  superFunds: SuperFundSchema[]
) => {
  const sacrifices = superFunds.reduce(
    (previous, current) =>
      previous + clientType === "client1"
        ? current.salarySacrificeClient1 || 0
        : current.salarySacrificeClient2 || 0,
    0
  );

  return sacrifices / 12;
};

export const calculateMonthlyAfterTaxContribution = (
  superFunds: SuperFundSchema[]
) => {
  const sacrifices = superFunds.reduce(
    (previous, current) =>
      previous +
      current.personalContributionsClient1 +
      current.personalContributionsClient2,
    0
  );

  return sacrifices / 12;
};

export const caclulateMonthlyBusinessIncome = (
  currentEmployment: CurrentEmploymentSchema[]
) => {
  const businessIncome = currentEmployment
    .filter((employment) => employment.employmentType === "Business")
    .reduce(
      (previous, current) => calculateBusinessMonthly(previous, current),
      0
    );

  return businessIncome / 12;
};

export const caclulateMonthlySelfEmployedIncome = (
  currentEmployment: CurrentEmploymentSchema[]
) => {
  const businessIncome = currentEmployment
    .filter((employment) => employment.employmentType === "Self employed")
    .reduce(
      (previous, current) => calculateBusinessMonthly(previous, current),
      0
    );

  return businessIncome / 12;
};

export const calculateMonthlyOtherIncome = (
  currentEmployment: CurrentEmploymentSchema[],
  otherIncome: OtherIncomeSchema[]
) => {
  const employment = currentEmployment
    .filter((employ) => employ.employmentType === "PAYG")
    .reduce(
      (previous, current) =>
        previous +
        (current.job.overtime || 0) +
        (current.job.commission || 0) +
        (current.job.bonus || 0),
      0
    );

  const other = otherIncome.reduce(
    (previous, current) => previous + current.amount,
    0
  );

  return (employment + other) / 12;
};

export const calculateMonthlyInvestmentIncome = (
  clientId: string,
  investments: InvestmentsSchema[]
) => {
  const investmentIncome = investments.reduce((previous, current) => {
    const ownership =
      typeof current.ownership !== "undefined"
        ? current.ownership !== null
          ? current.ownership.owners.find((x) => x.owner === clientId)
          : undefined
        : undefined;

    return (
      previous +
      calculateOwnershipReducedValue(current.yearlyIncome || 0, ownership)
    );
  }, 0);

  return investmentIncome / 12;
};

export const calculateMonthlyInvestmentPropertyDeductions = (
  clientId: string,
  properties: PropertySchema[],
  borrowings: BorrowingItemSchema[]
) => {
  const propertyDeductions = properties
    .filter((property) => _.has(property.ownership, "owners"))
    .reduce((previous, current) => {
      let deduction = 0;

      if (current.purpose === "Investment") {
        deduction =
          (current.bodyCorpCosts || 0) +
          (current.insuranceCosts || 0) +
          (current.landTaxCosts || 0) +
          (current.maintenanceCost || 0) +
          (current.managementFees || 0) +
          (current.waterRates || 0) +
          (current.councilRates || 0);
      }

      const ownership = current.ownership.owners.find(
        (x) => x.owner === clientId
      );

      return previous + calculateOwnershipReducedValue(deduction, ownership);
    }, 0);

  const loanDeductions = borrowings
    .filter((borrowingItem) => {
      const borrowingItemHasType = _.has(borrowingItem, "type");

      const isInvestmentLoan =
        typeof borrowingItem.type !== "undefined"
          ? borrowingItem.type !== null
            ? borrowingItem.type.toLowerCase().includes("investment loan")
            : false
          : false;

      const isRealEstate =
        typeof borrowingItem.primaryPurpose !== "undefined"
          ? borrowingItem.primaryPurpose !== null
            ? borrowingItem.primaryPurpose.toLowerCase().includes("real estate")
            : false
          : false;

      return borrowingItemHasType
        ? isInvestmentLoan
        : "" && borrowingItem.primaryPurpose && isRealEstate;
    })
    .reduce((previous, current) => {
      if (!current.interestRate || !current.outstanding) return previous;

      const deduction = (current.interestRate / 100) * current.outstanding;

      const ownership =
        typeof current.borrower !== "undefined"
          ? current.borrower !== null
            ? current.borrower.owners.find((x) => x.owner === clientId)
            : undefined
          : undefined;

      return previous + calculateOwnershipReducedValue(deduction, ownership);
    }, 0);

  return (propertyDeductions + loanDeductions) / 12;
};

export const calculateMonthlyOtherTaxDeductions = (
  clientId: string,
  expenses: ExpenseItemSchema[]
) => {
  return expenses
    .filter((expense) => expense.taxDeductible && expense.frequency !== null)
    .reduce((previous, current) => {
      if (current.ownership === undefined) return previous;

      const isOwnedByClient = current.ownership.owners.some(
        (x) => x.owner === clientId
      );

      if (!isOwnedByClient) return previous;

      const frequencyAdjustedValue = current.frequency
        ? current.amount * getMonthlyFrequencyMultiplier(current.frequency)
        : 0;

      return previous + current.ownership.ownershipType === "Joint"
        ? frequencyAdjustedValue / 2
        : frequencyAdjustedValue;
    }, 0);
};

export const calculateMonthlyNonTaxableIncome = (
  otherIncome: OtherIncomeSchema[]
) =>
  otherIncome
    .filter((other) => other.isTaxFree)
    .reduce((previous, current) => previous + current.amount, 0);

// ============================================================================
// Monthly Out
// ============================================================================

export const calculateMonthlyLivingLifestyle = (
  expenses: ExpenseItemSchema[],
  property: PropertySchema[]
) =>
  getLivingLifestyleExpenses(expenses, property).reduce(
    sumByMonthlyFreqReducer("amount", "frequency"),
    0
  );

export const calculateMonthlyCreditCard = (
  expenses: ExpenseItemSchema[],
  property: PropertySchema[]
) =>
  getCreditCardExpenses(expenses, property).reduce(
    sumByMonthlyFreqReducer("amount", "frequency"),
    0
  );

export const calculateMonthlyDirectPayments = (
  expenses: ExpenseItemSchema[],
  properties: PropertySchema[],
  superFunds: SuperFundSchema[] | any,
  investments: InvestmentsSchema[]
) =>
  getDirectPaymentsExpenses(
    expenses,
    properties,
    superFunds,
    investments
  ).reduce(sumByMonthlyFreqReducer("amount", "frequency"), 0);

export const calculateMonthlyLoans = (borrowing: BorrowingItemSchema[]) => {
  return getLoansBorrowingItems(borrowing)
    .filter((b) => b.isClosed !== true)
    .reduce(sumByMonthlyFreqReducer("repayment", "repaymentFreq"), 0);
};

export const calculateMonthlyProvisions = (
  expenses: ExpenseItemSchema[],
  properties: PropertySchema[],
  index: number,
  hasIndex: boolean
) => {
  return hasIndex
    ? index === 0
      ? 0
      : getProvisionsExpenses(expenses, properties).reduce(
          sumByMonthlyFreqReducer("amount", "frequency"),
          0
        )
    : getProvisionsExpenses(expenses, properties).reduce(
        sumByMonthlyFreqReducer("amount", "frequency"),
        0
      );
};

export const calculateMonthlyMoneyOut = (summary, index, hasIndex) => {
  const hasAssets = _.has(summary, "assets") ? summary.assets.superFunds : [];
  const propertyValue = hasAssets ? summary.assets.properties : [];
  const investments = hasAssets ? summary.assets.investments : [];

  const superFunds = hasAssets
    ? summary.assets.superFunds
    : [
        {
          value: 0,
          isSmsf: false,
          provider: "",
          salarySacrificeBool: false,
          salarySacrificeClient1: 0,
          salarySacrificeClient2: 0,
          personalContributionsBool: false,
          personalContributionsClient1: 0,
          personalContributionsClient2: 0,
        },
      ];

  const properties = hasAssets
    ? summary.assets.properties
    : [
        {
          bodyCorpCosts: null,
          councilRates: null,
          grossRentalIncome: 0,
          insuranceCosts: null,
          landTaxCosts: null,
          maintenanceCost: 0,
          managementFees: 0,
          ownership: [],
          purpose: "",
          waterRates: null,
        },
      ];

  const expenses =
    typeof summary.expenses !== "undefined"
      ? summary.expenses !== null
        ? summary.expenses.expenses
        : []
      : [];

  const livingLifeStyleMonthly = calculateMonthlyLivingLifestyle(
    expenses,
    properties
  );

  const creditCardMonthy = calculateMonthlyCreditCard(expenses, properties);

  const directPaymentsMonthly = calculateMonthlyDirectPayments(
    expenses,
    properties,
    superFunds,
    investments
  );

  const hasBorrowing = _.has(summary, "borrowings");

  const loansMonthly = hasBorrowing
    ? calculateMonthlyLoans(summary.borrowings.borrowing)
    : 0;

  const provisionsMonthly = calculateMonthlyProvisions(
    summary.expenses.expenses,
    propertyValue,
    index,
    hasIndex
  );

  // const afterTaxContribution = calculateMonthlyAfterTaxContribution(superFunds)

  return hasIndex
    ? index === 0
      ? 0
      : livingLifeStyleMonthly +
        creditCardMonthy +
        directPaymentsMonthly +
        loansMonthly +
        provisionsMonthly
    : livingLifeStyleMonthly +
        creditCardMonthy +
        directPaymentsMonthly +
        loansMonthly +
        provisionsMonthly;
};

// ============================================================================
// Other
// ============================================================================

export const calculateMonthlyCashPosition = (
  monthlyBalanceItem: MonthlyBalanceItemSchema
) =>
  monthlyBalanceItem &&
  monthlyBalanceItem.amount - monthlyBalanceItem.creditCardBalance;

export const calculateMonthToMonthChangeInCashPosition = (
  monthlyBalanceItems: MonthlyBalanceItemSchema[],
  index: number
) => {
  const currentCashPosition =
    index === 0 ? 0 : calculateMonthlyCashPosition(monthlyBalanceItems[index]);

  const previousCashPosition =
    index === 0
      ? 0
      : calculateMonthlyCashPosition(monthlyBalanceItems[index - 1]);

  return currentCashPosition - previousCashPosition;
};

export const calculateMonthlySurplus = (
  monthlyBalanceItems: MonthlyBalanceItemSchema[],
  properties: PropertySchema[],
  index: number,
  expenses: ExpenseItemSchema[]
) => {
  return index === 0
    ? 0
    : calculateMonthToMonthChangeInCashPosition(monthlyBalanceItems, index) -
        calculateMonthlyProvisions(expenses, properties, index, true);
};

export const calculateAccumulatedSurplus = (
  monthlyBalanceItems: MonthlyBalanceItemSchema[],
  properties: PropertySchema[],
  index: number,
  expenses: ExpenseItemSchema[]
) => {
  return index === 0
    ? 0
    : monthlyBalanceItems
        .map((item, index_, self) =>
          calculateMonthlySurplus(self, properties, index_, expenses)
        )
        .slice(0, index + 1)
        .reduce((previous, current) => previous + current, 0);
};

export const calculateMonthlyExcessSurplus = (
  monthlyBalanceItems: MonthlyBalanceItemSchema[],
  index: number,
  summary: ClientSummarySchema,
  targetSurplus: Number
) => {
  const expenses =
    typeof summary.expenses !== "undefined"
      ? summary.expenses !== null
        ? summary.expenses.expenses
        : []
      : [];

  const properties =
    typeof summary.assets !== "undefined"
      ? summary.assets !== null
        ? summary.assets.properties
        : []
      : [];

  return (
    calculateMonthlySurplus(monthlyBalanceItems, properties, index, expenses) -
    Number(targetSurplus)
  );
};

export const calculateMonthlyProvisioningsSpent = (
  monthlyBalanceItem: MonthlyBalanceItemSchema,
  provisionItems: ProvisionItemSchema[]
) => {
  const monthlyBalanceItemDate = getMonthlyBalanceItemDate(monthlyBalanceItem);

  return provisionItems
    .flatMap((provisionItem) => provisionItem.items)
    .filter((x) =>
      dateFns.isSameMonth(monthlyBalanceItemDate, new Date(x.date))
    )
    .reduce(
      (previous, current) => Number(previous) + Number(current.amount),
      0
    );
};

export const calculateMonthlyProvisioningsSpentTable = (
  startDate: Date,
  monthlyBalanceItem: MonthlyBalanceItemSchema,
  provisionItems: ProvisionItemSchema[],
  position: number
) => {
  const monthlyBalanceItemDate = getMonthlyBalanceItemDate(monthlyBalanceItem);
  const day = String(`0${startDate.getDate()}`).slice(-2);
  const monthFromStart = dateFns.subDays(dateFns.addMonths(startDate, 1), 1);
  const newItemDate = dateFns.addDays(monthlyBalanceItemDate, Number(day) - 1);

  const newItemDateMonth = dateFns.subDays(
    dateFns.addMonths(newItemDate, 1),
    1
  );

  return provisionItems
    .flatMap((provisionItem) => provisionItem.items)
    .filter((item) => {
      let dateIsInRange;
      let isDateEqual;

      if (position === 0) {
        dateIsInRange = dateFns.isWithinInterval(new Date(item.date), {
          start: startDate,
          end: monthFromStart,
        });

        isDateEqual = dateFns.isEqual(startDate, new Date(item.date));
      } else {
        dateIsInRange = dateFns.isWithinInterval(new Date(item.date), {
          start: newItemDate,
          end: newItemDateMonth,
        });
      }

      return dateIsInRange || isDateEqual;
    })
    .reduce(
      (previous, current) => Number(previous) + Number(current.amount),
      0
    );
};

export const calculateYearlyProvisions = (
  expenses: ExpenseItemSchema[],
  property: PropertySchema[]
) =>
  getProvisionsExpenses(expenses, property).reduce((previous, current) => {
    const frequencyAdjustedValue = current.frequency
      ? current.amount * getYearlyFrequencyMultiplier(current.frequency)
      : 0;

    return previous + frequencyAdjustedValue;
  }, 0);

// ============================================================================
// Helpers
// ============================================================================

const calculateBusinessMonthly = (
  previous: number,
  current: CurrentEmploymentSchema
) => {
  if (current.business.earnings.length <= 0) {
    return 0;
  }

  return current.business !== null
    ? previous + current.business.earnings[0].salary
    : previous;
};

type ClientType = "client1" | "client2";

const calculateOwnershipReducedValue = (
  value: number,
  ownership: { percentage: number } | undefined
) => (ownership ? value * (ownership.percentage / 100) : 0);

const sumByMonthlyFreqReducer =
  <T>(valueField: keyof T, freqField: keyof T) =>
  (previous: number, current: T) => {
    const value = Number(current[valueField]);
    const frequency = current[freqField] as any;

    const frequencyAdjustedValue =
      !Number.isNaN(value) && frequency
        ? value * getMonthlyFrequencyMultiplier(frequency)
        : 0;

    return previous + frequencyAdjustedValue;
  };

// TypeScript has a bug that prevents this from being typed correctly
// https://github.com/microsoft/TypeScript/issues/29505
// const sumByMonthlyFreqReducer = <
//   T extends object,
//   V extends keyof T,
//   F extends keyof T
// >(
//   valueField: T[V] extends number ? V : never,
//   freqField: T[F] extends FrequencySchema ? F : never
// ) => (prev: number, curr: T) =>
//   prev + curr[valueField] * getMonthlyFrequencyMultiplier(curr[freqField])

export const calculateMonthlyPreTax = (clientType, summary) => {
  let clientPreTax;

  if (clientType === "client1") {
    clientPreTax = _.get(
      summary,
      "income.client1PersonalIncome.currentEmployment[0].job.preTaxes",
      []
    );
  } else if (clientType === "client2") {
    clientPreTax = _.get(
      summary,
      "income.client2PersonalIncome.currentEmployment[0].job.preTaxes",
      []
    );
  }

  const allPreTax = clientPreTax.map((tax) => {
    return tax.taxValue;
  });

  const addPretaxes = allPreTax.reduce((a, b) => a + b, 0);

  return addPretaxes / 12;
};
